import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { Observable } from "rxjs";
import * as models from "../data.modal";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class TransactionsService {
  basePath: any = "https://rst.intellibooks.io";
  cookieValue: any = "";
  constructor(private http: HttpClient, private cookieService: CookieService) {
    this.basePath = environment.basePath;
  }

  getHeader(): HttpHeaders {
    let headers = new HttpHeaders();
    this.cookieValue = this.cookieService.get("SESSIONID");
    headers = headers.set("Accept", "application/json");
    headers = headers.set("Content-Type", "application/json");
    // headers = headers.set("Authorization", "bearer " + this.cookieValue);
    if (this.cookieService.get("refresh_token") && this.cookieValue) {
      headers = headers.set("Authorization", "Bearer " + this.cookieValue);
    }
    return headers;
  }

  //Company Information API service
  getCompanyInfo(): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/CompanyInfo`, { headers });
  }

  //ledgers

  getLedger(id: any | "null"): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(
      `${this.basePath}/api/Ledger/${encodeURIComponent(id)}`,
      { headers }
    );
  }
  getLedgerGroupList(): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/LedgerGroup`, { headers });
  }
  getLedgerDetailsList(
    from_date: any | "null",
    to_date: any | "null",
    ledger: string | "null"
  ) {
    let headers = this.getHeader();
    from_date.setHours(0, 0, 0, 0);
    to_date.setHours(23, 59, 59, 59);
    return this.http.get<any>(
      `${this.basePath}/api/LedgerDetailReport/${encodeURIComponent(
        from_date
      )}/${encodeURIComponent(to_date)}/${encodeURIComponent(ledger)}`,
      { headers }
    );
  }

  getBankLedgerList(): Observable<any> {
    let headers = this.getHeader();
    return this.http.get<any>(`${this.basePath}/api/BankLedger`, { headers });
  }

  downloadPdf(data: models.TransactionObj | "null"): Observable<Blob> {
    let headers = new HttpHeaders();

    headers = headers.set("Accept", "application/pdf");
    headers = headers.set("Content-Type", "application/json");
    this.cookieValue = this.cookieService.get("SESSIONID");
    // headers = headers.set("Authorization", "bearer " + this.cookieValue);
    headers = headers.set("Authorization", "Bearer " + this.cookieValue);

    return this.http.post<any>(`${this.basePath}/api/PdfDownload`, data, {
      headers,
      responseType: "blob" as "json",
    });
  }

  getCashBankLedgerList(): Observable<any> {
    let headers = this.getHeader();
    return this.http.get<any>(`${this.basePath}/api/CashBankLedger`, {
      headers,
    });
  }

  addCashBank(data: models.TransactionObj | "null"): Observable<any> {
    let headers = this.getHeader();
    return this.http.post<any>(`${this.basePath}/api/CashBank`, data, {
      headers,
    });
  }

  getLedgerClosingBalance(ledger_id: any): Observable<any> {
    let headers = this.getHeader();
    return this.http.get<any>(
      `${this.basePath}/api/LedgerClosingBalance/${encodeURIComponent(
        ledger_id
      )}`,
      { headers }
    );
  }

  getCashBank(id: string | "null"): Observable<any> {
    let headers = this.getHeader();
    return this.http.get<any>(
      `${this.basePath}/api/CashBank/${encodeURIComponent(id)}`,
      { headers }
    );
  }

  editCashBank(
    id: string | "null",
    data: models.TransactionObj | "null"
  ): Observable<any> {
    let headers = this.getHeader();

    return this.http.put<any>(
      `${this.basePath}/api/CashBank/${encodeURIComponent(id)}`,
      data,
      { headers }
    );
  }

  deleteCashBank(id: string | "null"): Observable<any> {
    let headers = this.getHeader();

    return this.http.delete<any>(
      `${this.basePath}/api/CashBank/${encodeURIComponent(id)}`,
      { headers }
    );
  }

  getEnableResturantPos(): Observable<any> {
    let headers = this.getHeader();
    return this.http.get<any>(`${this.basePath}/api/ChechEnableResturantPos`, {
      headers,
    });
  }

  getVoucherTypeList(): Observable<any> {
    let headers = this.getHeader();
    return this.http.get<any>(`${this.basePath}/api/VoucherType`, { headers });
  }

  getAllVoucherListByDay(
    from_date: any | "null",
    to_date: any | "null"
  ): Observable<any> {
    let headers = this.getHeader();
    from_date.setHours(0, 0, 0, 0);
    to_date.setHours(23, 59, 59, 59);

    return this.http.get<any>(
      `${this.basePath}/api/getReportByDate/${encodeURIComponent(
        from_date
      )}/${encodeURIComponent(to_date)}`,
      { headers }
    );
  }

  getCashLedgerList(): Observable<any> {
    let headers = this.getHeader();
    return this.http.get<any>(`${this.basePath}/api/CashLedger`, { headers });
  }

  sgetGstState(
    filter: string | "null",
    inactive: string | "null"
  ): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(
      `${this.basePath}/api/sgstState/${encodeURIComponent(
        filter
      )}/${encodeURIComponent(inactive)}`,
      { headers }
    );
  }

  //get gst state code
  getGstState(): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/tntgstState`, { headers });
  }

  getSundryLedgerList(
    filter: string | "null",
    inactive: string | "null"
  ): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(
      `${this.basePath}/api/CustomerLedger/${encodeURIComponent(
        filter
      )}/${encodeURIComponent(inactive)}`,
      { headers }
    );
  }

  // Account Map API service
  getAccountMapList(): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/AccountMap`, { headers });
  }
  // Account Map API service
  getEBillAcountMapData(): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/getEBillAcountMapData`, {
      headers,
    });
  }

  //Contracts API service
  getContractList(): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/Contracts`, { headers });
  }

  getContract(id: string | "null"): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(
      `${this.basePath}/api/Contracts/${encodeURIComponent(id)}`,
      { headers }
    );
  }

  deleteContract(id: string | "null"): Observable<any> {
    let headers = this.getHeader();

    return this.http.delete<any>(
      `${this.basePath}/api/Contracts/${encodeURIComponent(id)}`,
      { headers }
    );
  }

  editContract(
    id: string | "null",
    data: models.ContractObj | "null"
  ): Observable<any> {
    let headers = this.getHeader();

    return this.http.put<any>(
      `${this.basePath}/api/Contracts/${encodeURIComponent(id)}`,
      data,
      { headers }
    );
  }

  getEnabledInvoiceField(id: any): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(
      `${this.basePath}/api/getEnabledFields/${encodeURIComponent(id)}`,
      { headers }
    );
  }

  addCreditNote(data: models.TransactionObj | "null"): Observable<any> {
    let headers = this.getHeader();

    return this.http.post<any>(`${this.basePath}/api/CreditNote`, data, {
      headers,
    });
  }

  getInvoiceByBillNo(id: any | "null"): Observable<any> {
    let headers = this.getHeader();
    return this.http.get<any>(
      `${this.basePath}/api/InvoiceByBillNo/${encodeURIComponent(id)}`,
      { headers }
    );
  }

  getTaxslabList(): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/Taxslab`, { headers });
  }

  getCustomerItemRate(ledger_id: any | "null"): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(
      `${this.basePath}/api/CustomerItemRate/${encodeURIComponent(ledger_id)}`,
      { headers }
    );
  }

  getFilterLedgerList(filter: string | "null"): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(
      `${this.basePath}/api/LedgerList/${encodeURIComponent(filter)}`,
      { headers }
    );
  }

  deleteLedger(id: string | "null"): Observable<any> {
    let headers = this.getHeader();
    return this.http.delete<any>(
      `${this.basePath}/api/Ledger/${encodeURIComponent(id)}`,
      { headers }
    );
  }

  // Dashboard
  getTransCount(
    from_date: any | "null",
    to_date: any | "null",
    type: string | "null"
  ) {
    let headers = this.getHeader();
    from_date.setHours(0, 0, 0, 0);
    to_date.setHours(23, 59, 59, 59);

    return this.http.get<any>(
      `${this.basePath}/api/vouchercount/${encodeURIComponent(
        from_date
      )}/${encodeURIComponent(to_date)}/${encodeURIComponent(type)}`,
      { headers }
    );
  }

  getSalesInv(
    from_date: string | "null",
    to_date: string | "null",
    type: string | "null"
  ) {
    let headers = this.getHeader();

    return this.http.get<any>(
      `${this.basePath}/api/salesinv/${encodeURIComponent(
        from_date
      )}/${encodeURIComponent(to_date)}/${encodeURIComponent(type)}`,
      { headers }
    );
  }

  getInvoiceCountTrans(
    from_date: any | "null",
    to_date: any | "null"
  ): Observable<any> {
    let headers = this.getHeader();
    from_date.setHours(0, 0, 0, 0);
    to_date.setHours(23, 59, 59, 59);

    return this.http.get<any>(
      `${this.basePath}/api/invoiceGraph/${encodeURIComponent(
        from_date
      )}/${encodeURIComponent(to_date)}`,
      { headers }
    );
  }

  getpendingDelivery(): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/pending_delivery`, {
      headers,
    });
  }

  getPendingOrders(): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/pending_orders`, {
      headers,
    });
  }

  addLedger(data: models.LedgerObj | "null"): Observable<any> {
    let headers = this.getHeader();

    return this.http.post<any>(`${this.basePath}/api/Ledger`, data, {
      headers,
    });
  }

  editLedger(
    id: string | "null",
    data: models.LedgerObj | "null"
  ): Observable<any> {
    let headers = this.getHeader();

    return this.http.put<any>(
      `${this.basePath}/api/Ledger/${encodeURIComponent(id)}`,
      data,
      {
        headers,
      }
    );
  }

  // //Ledger group API service

  addLedgerGroup(data: models.LedgerGroupObj | "null"): Observable<any> {
    let headers = this.getHeader();

    return this.http.post<any>(`${this.basePath}/api/LedgerGroup`, data, {
      headers,
    });
  }

  getLedgerGroup(id: string | "null"): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(
      `${this.basePath}/api/LedgerGroup/${encodeURIComponent(id)}`,
      {
        headers,
      }
    );
  }

  deleteLedgerGroup(id: string | "null"): Observable<any> {
    let headers = this.getHeader();

    return this.http.delete<any>(
      `${this.basePath}/api/LedgerGroup/${encodeURIComponent(id)}`,
      {
        headers,
      }
    );
  }

  editLedgerGroup(
    id: string | "null",
    data: models.LedgerGroupObj | "null"
  ): Observable<any> {
    let headers = this.getHeader();

    return this.http.put<any>(
      `${this.basePath}/api/LedgerGroup/${encodeURIComponent(id)}`,
      data,
      {
        headers,
      }
    );
  }
  //Ledger API service
  getLedgerList(): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/Ledger`, {
      headers,
    });
  }

  getLedgerListWithInctive(
    filter: string | "null",
    active: string | "null"
  ): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(
      `${this.basePath}/api/LedgerListWithInctive/${encodeURIComponent(
        filter
      )}/${encodeURIComponent(active)}`,
      {
        headers,
      }
    );
  }
  getSundryCrLedgerList(
    filter: string | "null",
    inactive: string | "null"
  ): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(
      `${this.basePath}/api/SundryCreditorLedger/${encodeURIComponent(
        filter
      )}/${encodeURIComponent(inactive)}`,
      {
        headers,
      }
    );
  }

  //Reorder API service
  getReorderList(): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/reorder`, { headers });
  }

  //Stock Adjustment API Service
  getStockAdjustmentList(): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/StockAdjustment`, {
      observe: "response",
      headers,
    });
  }

  addStockAdjustment(data: models.TransactionObj | "null"): Observable<any> {
    let headers = this.getHeader();

    return this.http.post<any>(`${this.basePath}/api/StockAdjustment`, data, {
      headers,
    });
  }

  getStockAdjustment(id: string | "null"): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(
      `${this.basePath}/api/StockAdjustment/${encodeURIComponent(id)}`,
      {
        headers,
      }
    );
  }

  deleteStockAdjustment(id: string | "null"): Observable<any> {
    let headers = this.getHeader();

    return this.http.delete<any>(
      `${this.basePath}/api/StockAdjustment/${encodeURIComponent(id)}`,
      {
        observe: "response",
        headers,
      }
    );
  }

  editStockAdjustment(
    id: string | null, // Use `null` directly instead of "null"
    data: models.TransactionObj | null // Use `null` directly instead of "null"
  ): Observable<any> {
    let headers = this.getHeader();
  
    // Ensure id and data are not null
    if (id === null || data === null) {
      throw new Error('Invalid id or data');
    }
  
    // Use the correct structure for the PUT request
    return this.http.put<any>(
      `${this.basePath}/api/StockAdjustment/${encodeURIComponent(id)}`,
      data, // Pass data directly without stringifying
      {
        headers,
        observe: "response", // This should be part of the options, not the body
      }
    ).pipe(
      // Handle any response or error handling here if needed
    );
  }
  
}
