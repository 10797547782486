<h2 *ngIf="!data" mat-dialog-title class="color-h3">Add New Company</h2>
<h2 *ngIf="data" mat-dialog-title class="color-h3">Edit Company</h2>
<div class="table-details">
  <mat-dialog-content>
    <form name="form" #f="ngForm">
      <div class="row">
        <div *ngIf="!data" class="col-md-4">
          <mat-form-field class="contract-full-width">
            <mat-label>Company Code:</mat-label>
            <input matInput  [(ngModel)]="model.tenent_code" name="tenent_code" required>
            <mat-error>required</mat-error>
          </mat-form-field>
        </div>

        <div *ngIf="!data" class="col-md-4">
          <mat-form-field class="contract-full-width">
            <mat-label>Database Name:</mat-label>
            <input matInput  [(ngModel)]="model.db_name" name="db_name" required>
            <mat-error>required</mat-error>
          </mat-form-field>
        </div>
        <div *ngIf="!data" class="col-md-4">
          <section class="form-section">
            <mat-checkbox class="form-margin" [(ngModel)]="model.is_composite" name="is_composite">Composite
            </mat-checkbox>
          </section>
        </div>
        <div *ngIf="!data" class="col-md-12">
          <mat-form-field class="contract-full-width">
            <mat-label>Company Name:</mat-label>
            <input matInput  [(ngModel)]="model.tenent_name" name="tenent_name" required>
            <mat-error>required</mat-error>
          </mat-form-field>
        </div>
        <div *ngIf="data" class="col-md-12">
          <mat-form-field class="contract-full-width">
            <mat-label>Company Name:</mat-label>
            <input matInput  [(ngModel)]="model.company_name" name="company_name" required>
            <mat-error>required</mat-error>
          </mat-form-field>
        </div>

        <div *ngIf="data" class="col-md-6">
          <section class="form-section">
            <mat-checkbox class="form-margin" [(ngModel)]="model.active" name="active">Active</mat-checkbox>
          </section>
        </div>
        <div *ngIf="!data" class="col-md-4">
          <mat-form-field color="accent" class="contract-full-width">
            <mat-label>Address1:</mat-label>
            <input matInput  [(ngModel)]="model.address_address1" name="address_address1"
              required>
            <mat-error>required</mat-error>
          </mat-form-field>
        </div>
        <div *ngIf="!data" class="col-md-4">
          <mat-form-field class="contract-full-width">
            <mat-label>Address2:</mat-label>
            <input matInput  [(ngModel)]="model.address_address2" name="address_address2"
              required>
            <mat-error>required</mat-error>
          </mat-form-field>
        </div>
        <div *ngIf="!data" class="col-md-4">
          <mat-form-field class="contract-full-width">
            <mat-label>City:</mat-label>
            <input matInput  [(ngModel)]="model.address_city" name="address_city" required>
            <mat-error>required</mat-error>
          </mat-form-field>
        </div>
        <div *ngIf="!data" class="col-md-4">
          <mat-form-field class="contract-full-width">
            <mat-label>State</mat-label>
            <input type="text"  aria-label="address_state" matInput [formControl]="StateControl"
              [matAutocomplete]="auto" required>
            <mat-error>required</mat-error>
            <!-- <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayState" autoActiveFirstOption -->
            <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption (optionSelected)="StateSelection($event)">
              <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                {{option.name}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div *ngIf="!data" class="col-md-4">
          <mat-form-field class="contract-full-width">
            <mat-label>Country:</mat-label>
            <input matInput  [(ngModel)]="model.address_country" name="address_country" required>
            <mat-error>required</mat-error>
          </mat-form-field>
        </div>
        <div *ngIf="!data" class="col-md-4">
          <mat-form-field class="contract-full-width">
            <mat-label>Pin:</mat-label>
            <input matInput  [(ngModel)]="model.address_pin" name="address_pin" required>
            <mat-error>required</mat-error>
          </mat-form-field>
        </div>
        <div *ngIf="!data" class="col-md-4">
          <mat-form-field class="contract-full-width">
            <mat-label>Contact No:</mat-label>

            <input matInput  [(ngModel)]="model.contact_no" name="contact_no">
          </mat-form-field>
        </div>
        <div *ngIf="!data" class="col-md-4">
          <mat-form-field class="contract-full-width">
            <mat-label>Email Id:</mat-label>

            <input matInput  [(ngModel)]="model.email_id" name="email_id">
          </mat-form-field>
        </div>
        <div *ngIf="!data" class="col-md-4">
          <mat-form-field class="contract-full-width">
            <mat-label>GSTIN:</mat-label>

            <input matInput  [(ngModel)]="model.gstin" name="gstin">
          </mat-form-field>
        </div>
      </div>




    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button type="button" class="btn mat-raised-button mat-primary" (click)="close()">Close</button>
    <button type="submit" class="btn mat-raised-button mat-primary" style="margin-left: 5px;" (click)="onSubmit()"
      [disabled]="f.invalid">Save</button>
  </mat-dialog-actions>
</div>