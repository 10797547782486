import { Component, OnInit } from "@angular/core";
import { CardComponentsComponent } from "../../stories/stories-module/card-components/card-components.component";
import { IconComponent } from "../../stories/stories-module/icons/icons.component";
import { StatsCardComponent } from "../../stories/stories-module/stats-card/stats-card.component";
import { CommonModule } from "@angular/common";
import { OwnerDashboardService } from "../_services/owner-dashboard.service";
import { TotalSalesComponent } from "../../stories/stories-module/total-sales/total-sales.component";
import { environment } from "../../environments/environment";
import { AppNavService } from "../_services/app-nav.service";

@Component({
  selector: "app-owner-dashboard",
  standalone: true,
  imports: [
    CardComponentsComponent,
    IconComponent,
    StatsCardComponent,
    TotalSalesComponent,
    CommonModule,
  ],
  templateUrl: "./owner-dashboard.component.html",
  styleUrl: "./owner-dashboard.component.css",
})
export class OwnerDashboardComponent implements OnInit {
  currency: any;

  modelData = {
    success_invoice: 0,
    success_kot: 0,
    cancelled_invoice: 0,
    cancelled_kot: 0,
  };
  saleModelData = {
    card: 0,
    cash: 0,
    upi: 0,
    total_sales: 0,
    due: 0,
    net_amount: 0,
    discount_amt: 0,
    paid: 0,
  };
  cards: any = [];
  // Array of date ranges for the dropdown
  dateRanges: { label: string; value: string }[] = [
    { label: "Today", value: "Today" },
    { label: "Yesterday", value: "Yesterday" },
    { label: "Weekly", value: "Weekly" },
    { label: "Monthly", value: "Monthly" },
    // { label: 'This Month', value: 'thisMonth' },
    // { label: 'Last Month', value: 'lastMonth' },
    // { label: 'Custom Range', value: 'custom' }
  ];
  constructor(
    public ownerDashboardService: OwnerDashboardService,
    private apiService: AppNavService
  ) {
    this.currency = environment.currency;
  }

  localset() {
    // Calls a service to get initial settings from an API
    this.apiService.getInitialSetting().subscribe((result: any) => {
      // If the result contains a 'Currency' property
      if (result.Currency) {
        // Store the 'Currency' value in localStorage with the key 'currency'
        localStorage.setItem("currency", result.Currency);
      }
    });
  }

  ngOnInit(): void {
    this.getOwnerDashboard("Today");
    this.getOrderDashboard("Today");
    this.getTotalSale("Today");
  }

  getOwnerDashboard(today: any) {
    this.ownerDashboardService
      .getOwnerDashboardData(today)
      .subscribe((result) => {
        this.cards = [];
        if (result.success) {
          this.cards = result.data;
        }
      });
  }
  getOrderDashboard(today: any) {
    this.ownerDashboardService.getOrderStatistic(today).subscribe((result) => {
      if (result.success) {
        this.modelData = result.data;
      }
    });
  }
  getTotalSale(Today: any) {
    this.ownerDashboardService
      .getTotalSaleDashboard(Today)
      .subscribe((result) => {
        if (result.success) {
          this.saleModelData = result.data;
          this.saleModelData.total_sales =
            this.saleModelData.card +
            this.saleModelData.cash +
            this.saleModelData.upi;
        }
      });
  }
  handleDateRangeChangeOrderDashboard(selectedDate: string): void {
    // console.log('Received Date from CardComponent:', selectedDate);
    this.getOrderDashboard(selectedDate);
    // Handle the received date value here
  }
  handleDateRangeChangeSaleDashboard(selectedDateSale: string) {
    this.getTotalSale(selectedDateSale);
  }
  refreshData() {}
  onDateRangeChange(event: any) {
    console.log(event.target.value);
    this.getOwnerDashboard(event.target.value);
  }
}
