import { Component, OnInit, Input } from '@angular/core';
import { DataService } from '../data.service';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Notyf } from 'notyf';

const notyf = new Notyf({
  position: {
    x: 'right',
    y: 'top',
  }
});
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';

import { MatDialogModule } from "@angular/material/dialog";
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { SharedModule } from '../common.module';

@Component({
  selector: 'app-update-licence',
  templateUrl: './update-licence.component.html',
  styleUrls: ['./update-licence.component.css'],
  standalone: true,
  imports: [
    SharedModule
  ]
})
export class UpdateLicenceComponent implements OnInit {
  constructor(private apiService: DataService,
    public dialog: MatDialog,
    
    private router: Router) { }
  @Input() public isLoggedIn: boolean = false;
  licenceObj: any;
  isLicence: boolean = false;
  file: any;
  licenceUrl: any;
  client_id: any;
  status: any;
  expiry:any;
  ngOnInit() {
    this.load();
  }
  quotation_terms_conditions: any;
  load() {
    this.apiService.getLicenceDetails()
      .subscribe((result: any) => {
        if (result.success) {
          if (result.licence.SUCCESS) {
            this.isLicence = true;
            this.status = "Active";
            this.expiry = result.licence.LICENSEDATA.EXPIRYDATE?result.licence.LICENSEDATA.EXPIRYDATE:"Never"
            this.licenceObj = result.licence.LICENSEDATA;
          } else {
            this.isLicence = false;
            if (result.licence.LICENSEDATA) {
              this.status = result.licence.ERROR_MSG;
            } else {
              this.status = "Trial mode";
            }
          }
          this.client_id = result.licence.CLIENTID ? result.licence.CLIENTID : "Client Id not found";
        }
      }, (result: any) => {
      });

  }
  licenceChange(fileInputEvent: any) {
    this.file = fileInputEvent.target.files[0];
    this.readFile(this.file);
  }
  readFile(file: File) {
    var reader = new FileReader();
    reader.onload = () => {
      this.licenceUrl = reader.result;
    };
    reader.readAsDataURL(file);
  }

  licenceUpdate() {
    const fd = new FormData();
    fd.append('licenceFile', this.file, "licence.lic");

    this.apiService.uploadLicence(fd)
      .subscribe((result: any) => {
        if (result.success) {
          this.apiService.getLicenceDetails()
            .subscribe((result: any) => {
              if (result.success) {
                if (result.licence.SUCCESS) {
                  this.isLicence = true;
                  this.status = "Active";
                  this.expiry = result.licence.LICENSEDATA.EXPIRYDATE?result.licence.LICENSEDATA.EXPIRYDATE:"Never"
                  this.licenceObj = result.licence.LICENSEDATA;
                } else {
                  this.isLicence = false;
                  if (result.licence.LICENSEDATA) {
                    this.status = result.licence.ERROR_MSG;
                  } else {
                    this.status = "Trial mode";
                  }
                }
                this.client_id = result.licence.CLIENTID ? result.licence.CLIENTID : "Client Id not found";
                if(!result.licence.SUCCESS && result.licence.LICENSEDATA){
                  this.isLoggedIn = false;
                  this.router.navigate(['/licence']);
                }
                if (this.isLicence) {
                  localStorage.setItem('licence', 'true');
                } else {
                  localStorage.setItem('licence', 'false');
                }
                if (this.isLoggedIn) {
                  this.router.navigateByUrl('/redirect', { skipLocationChange: true }).then(() =>
                    this.router.navigate(['/Setting/company-info']));
                  notyf.success( 'Licence updated successfully');
                } else {
                  if (this.isLicence) {
                    this.router.navigate(['/dashboard']);
                    notyf.success( 'Licence updated successfully');
                  } else {
                    notyf.error( 'Licence not valid or expired');
                  }
                }
              }

            }, (result: any) => {
            });

        } else {
          notyf.error( result.message);
        }
      }, (result: any) => {
        notyf.error( 'unable to update licence...try after some time.');
      });
  }

  copyToClipboard() {
    // document.addEventListener('copy', (e: ClipboardEvent) => {
    //   e.clipboardData.setData('text/plain', (this.client_id));
    //   e.preventDefault();
    //   document.removeEventListener('copy', null);
    // });
    // document.execCommand('copy');
    // notyf.success( 'Client Id copied successfully');
  }

}
