import { CommonModule } from "@angular/common";
import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
interface OrderStatistics {
  successOrder: number;
  cancelledOrder: number;
  complimentaryOrder: number;
  tableTurnAroundTime: number; // in minutes
}

@Component({
  selector: "app-stats-card",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./stats-card.component.html",
  styleUrls: ["./stats-card.component.css"],
})
export class StatsCardComponent implements OnInit {
  @Output() dateRangeChange: EventEmitter<string> = new EventEmitter();
  @Input() date: string = "3rd Oct";
  @Input() stats: OrderStatistics = {
    successOrder: 0,
    cancelledOrder: 0,
    complimentaryOrder: 0,
    tableTurnAroundTime: 0,
  };

  isRotating = false; // State to manage rotation

  statsArray: { label: string; colorClass: string; value: number | string }[] =
    [];

  constructor() {}

  ngOnInit() {
    this.updateStatsArray(); // Initialize statsArray on component load
  }

  ngOnChanges() {
    this.updateStatsArray();
  }

  private updateStatsArray() {
    this.statsArray = [
      {
        label: "Success Order",
        value: this.stats.successOrder,
        colorClass: this.stats.successOrder > 0 ? "success-order" : "",
      },
      {
        label: "Cancelled Order",
        value: this.stats.cancelledOrder,
        colorClass: this.stats.cancelledOrder > 0 ? "cancelled-order" : "",
      },
      {
        label: "Success KOT",
        value: this.stats.complimentaryOrder,
        colorClass:
          this.stats.complimentaryOrder > 0 ? "complimentary-order" : "",
      },
      {
        label: "Cancelled KOT",
        value: this.stats.tableTurnAroundTime,
        colorClass:
          this.stats.tableTurnAroundTime > 0 ? "table-turnaround-time" : "",
      },
    ];
    // console.log('9875846758674',this.statsArray)
  }

  // Array of date ranges for the dropdown
  dateRanges: { label: string; value: string }[] = [
    { label: "Today", value: "Today" },
    { label: "Yesterday", value: "Yesterday" },
    { label: "Weekly", value: "Weekly" },
    { label: "Monthly", value: "Monthly" },
    // { label: 'This Month', value: 'thisMonth' },
    // { label: 'Last Month', value: 'lastMonth' },
    // { label: 'Custom Range', value: 'custom' }
  ];

  onDateRangeChange(event: any): void {
    const selectedValue = event.target.value;
    // console.log('Selected Date Range:', selectedValue); // Emit the selected value
    this.dateRangeChange.emit(selectedValue);
  }

  // refreshData() {}
}
