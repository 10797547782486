<div
  class="food-card"
  [ngStyle]="{
    borderColor: selected ? selectedBorderColor : '#ccc',
    backgroundColor: selected ? selectedBackgroundColor : '#fff',
    height: showPrice ? '160px' : '140px'
  }"
  (click)="showitems()"
  (mouseenter)="showFullName = true"
  (mouseleave)="showFullName = false"
>
  <div class="image-container">
    <img
      [src]="imgurl === nullImageURL ? '/assets/images/NoImage.png' : imgurl"
      alt="{{ categoryName }}"
    />
  </div>
  <div class="details-container">
    <div class="title" [title]="categoryName" [ngStyle]="titleStyle">
      {{ categoryName }}
      <span *ngIf="showFullName" [title]="categoryName" class="tooltip">{{
        categoryName
      }}</span>
    </div>
    <div class="price" *ngIf="showPrice" [ngStyle]="priceStyle">
      ₹{{ price }}
    </div>
  </div>
</div>
