import { CommonModule } from "@angular/common";
import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { SharedModule } from "../../../app/common.module";

@Component({
  selector: "app-left-sidebar-card",
  templateUrl: "./left-sidebar-card.component.html",
  styleUrls: ["./left-sidebar-card.component.css"],
  standalone: true,
  imports: [CommonModule, SharedModule],
})
export class LeftSidebarCardComponent implements OnInit {
  @Input() customerName!: string;
  @Input() customerContact!: string;
  @Input() customerAddress!: string;
  @Input() invoiceNumber!: string;
  @Input() kot!: string;
  @Input() date!: string;
  @Input() transAmount!: number;
  @Input() invoiceLabel!: string;
  @Input() paymentStatus!: string;
  @Input() seen!: boolean;
  @Input() width: string = "245px"; // Add width input
  @Input() height: string = "auto"; // Add height input

  @Output() siteOrder = new EventEmitter<{ event: any; data: any }>();
  @Output() siteOrderRej = new EventEmitter<{ event: any; data: any }>();
  @Output() invoice = new EventEmitter<{
    transaction_id: string;
    display_trans_no: string;
  }>();
  @Output() notification = new EventEmitter<{
    data: any;
    event: any;
  }>();

  @Input() currency: string = "usd";

  constructor() {}

  ngOnInit() {}

  @Input() websiteInvoiceData: any[] = [
    {
      kot_transction_id: 11825,
      transaction_id: 11826,
      user_id: 15,
      trans_no: 37,
      trans_date: "2024-09-12T13:19:53.323Z",
      customer_name: "arvind",
      shipping_address1: "Mesra, Jharkhand, India",
      shipping_address2: "mesra",
      total_amount: 249,
      net_amount: 249,
      active: true,
      display_trans_no: "INV24-2500009",
      contact_no: "8789280977",
      kot_rejected: false,
      kot_approved: false,
      show_details: true,
      advance: 0,
    },
    // Add more invoice objects as needed...
    {
      kot_transction_id: 17347,
      transaction_id: 17348,
      user_id: 15,
      trans_no: 2064,
      trans_date: "2024-10-18T16:19:21.713Z",
      customer_name: "Amit",
      shipping_address1: "Mumbai, Maharashtra, India",
      total_amount: 307,
      net_amount: 322,
      display_trans_no: "INV24-2501431",
      contact_no: "9004298517",
      kot_rejected: false,
      kot_approved: true,
      show_details: false,
      advance: 0,
    },
  ];

  // Method to toggle the "seen" state
  toggleSeen(): void {
    this.seen = !this.seen;
  }

  siteOrderApprove(event: any, data: any) {
    this.siteOrder.emit({ event, data });
  }

  siteOrderReject(event: any, data: any) {
    this.siteOrderRej.emit({ event, data });
  }

  riderInvoice(transaction_id: string, display_trans_no: string) {
    this.invoice.emit({ transaction_id, display_trans_no });
  }

  NotificationSeen(data: any, event: any) {
    this.notification.emit({ data, event });
  }
}
