import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { CommonModule } from "@angular/common";
interface PaymentMethod {
  label: string;
  value: number;
}

interface DateRange {
  label: string;
  value: string;
}

@Component({
  selector: "app-total-sales",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./total-sales.component.html",
  styleUrls: ["./total-sales.component.css"],
})
export class TotalSalesComponent implements OnInit {
  @Output() dateRangeChangeSale: EventEmitter<string> = new EventEmitter();
  @Input() date: string = "3rd Oct";
  @Input() totalSales: number = 0;
  @Input() paymentMethods: PaymentMethod[] = []; // Payment methods passed as input
  @Input() dateRanges: DateRange[] = []; // Date ranges passed as input
  @Input() currency: string = "usd";

  constructor() {}

  ngOnInit() {}

  // Function to handle date range change event
  onDateRangeChange(event: any): void {
    const selectedValue = event.target.value;
    this.dateRangeChangeSale.emit(selectedValue);

    console.log("Selected Date Range:", selectedValue);
  }

  // Function to refresh data
  // refreshData() {
  //   console.log("Data refreshed");
  // }
}
