import {  Component, OnInit } from "@angular/core";
import { Router, NavigationStart, NavigationEnd } from "@angular/router";
import { DataService } from "./data.service";
import { SharedModule } from "./common.module";
import { Notyf } from 'notyf';
import { v4 as uuidv4 } from 'uuid';
import { CookieService } from "ngx-cookie-service";

const notyf = new Notyf({
  position: {
    x: 'right',
    y: 'top',
  }
});

@Component({
  selector: "app-root",
  standalone: true,
  imports: [
    SharedModule
  ],
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  title = "UI";
  mapArr: any;
  loading: boolean = false;
  constructor(private router: Router, private DataService: DataService,


    private cookieService: CookieService,
  ) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.loading = true;
      } else if (event instanceof NavigationEnd) {
        this.loading = false;
      }
    });
  }
  theme: any = "light";
  ngOnInit(): void {
    let p = localStorage.getItem("theme");
    let uuid = uuidv4();
    let endpoint = localStorage.getItem('endPoint')
    if(!endpoint){

      localStorage.setItem('endPoint',uuid)
    }
    this.DataService.gettheme().subscribe((k: any) => {
      if (k == true) {
        this.theme = "dark";
      } else {
        this.theme = "light";
      }
    });
  }
}
