<h2 class="color-h3" mat-dialog-title>Import PriceList Items</h2>
<div class="table-details">
  <mat-dialog-content>
    <mat-tab-group
      [selectedIndex]="selectedTab"
      (selectedIndexChange)="onTabChange($event)"
    >
      <mat-tab label="Data">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <input
                  #csvInput
                  hidden="true"
                  type="file"
                  (change)="csvInputChange($event)"
                  accept=".csv"
                />
                <button
                  mat-flat-button
                  class="btn m-1"
                  (click)="csvInput.click()"
                >
                  Choose csv file
                </button>
                <span *ngIf="file">{{ file.name }}</span>
                <span *ngIf="!file">No File Choosen</span>
              </div>
            </div>
            <!-- <div class="col-md-6 text-right">
              <button mat-button color="primary" (click)="downloadTemplate()">
                Download import template here
              </button>
            </div> -->
          </div>
        </div>
        <table *ngIf="item_data.length" mat-table [dataSource]="itemsArr">
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox
                (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()"
                [aria-label]="checkboxLabel()"
              >
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
              <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="$event ? selection.toggle(row) : null"
                [checked]="selection.isSelected(row)"
                [aria-label]="checkboxLabel(row)"
              >
              </mat-checkbox>
            </td>
          </ng-container>
          <ng-container matColumnDef="itemid">
            <th mat-header-cell *matHeaderCellDef>Item Id</th>
            <td mat-cell *matCellDef="let element">{{ element.item_id }}</td>
          </ng-container>
          <ng-container matColumnDef="itemname">
            <th mat-header-cell *matHeaderCellDef>Item Name</th>
            <td mat-cell *matCellDef="let element">{{ element.item_name }}</td>
          </ng-container>
          <ng-container matColumnDef="category">
            <th mat-header-cell *matHeaderCellDef>Category</th>
            <td mat-cell *matCellDef="let element">{{ element.cat_name }}</td>
          </ng-container>
          <ng-container matColumnDef="taxslab">
            <th mat-header-cell *matHeaderCellDef>Taxslab</th>
            <td mat-cell *matCellDef="let element">{{ element.taxslab }}</td>
          </ng-container>
          <ng-container matColumnDef="defaultprice">
            <th mat-header-cell *matHeaderCellDef>D Price</th>
            <td mat-cell *matCellDef="let element">
              {{ element.default_price }}
            </td>
          </ng-container>
          <ng-container matColumnDef="srate">
            <th mat-header-cell *matHeaderCellDef>S Price</th>
            <td mat-cell *matCellDef="let element">{{ element.s_rate }}</td>
          </ng-container>
          <ng-container matColumnDef="msg">
            <th class="text-center" mat-header-cell *matHeaderCellDef>
              Status
            </th>
            <td mat-cell *matCellDef="let element">
              <span *ngIf="element.success" class="badge badge-success">{{
                element.msg
              }}</span>
              <span *ngIf="!element.success" class="badge badge-danger">{{
                element.msg
              }}</span>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            (click)="selection.toggle(row)"
          ></tr>
        </table>
      </mat-tab>

      <mat-tab label="Variant">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <input
                  #csvInput
                  hidden="true"
                  type="file"
                  (change)="csvInputChange($event)"
                  accept=".csv"
                />
                <button
                  mat-flat-button
                  class="btn m-1"
                  (click)="csvInput.click()"
                >
                  Choose csv file
                </button>
                <span *ngIf="file">{{ file.name }}</span>
                <span *ngIf="!file">No File Choosen</span>
              </div>
            </div>
            <div class="col-md-6 text-right">
              <button mat-button color="primary" (click)="downloadTemplate()">
                Download Variants CSV
              </button>
            </div>
          </div>
        </div>
        <table *ngIf="item_data.length" mat-table [dataSource]="itemsArr">
          <ng-container matColumnDef="selectVar">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox
                (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()"
                [aria-label]="checkboxLabel()"
              >
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
              <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="$event ? selection.toggle(row) : null"
                [checked]="selection.isSelected(row)"
                [aria-label]="checkboxLabel(row)"
              >
              </mat-checkbox>
            </td>
          </ng-container>
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>Id</th>
            <td mat-cell *matCellDef="let element">{{ element.id }}</td>
          </ng-container>
          <ng-container matColumnDef="item_id">
            <th mat-header-cell *matHeaderCellDef>Item Id</th>
            <td mat-cell *matCellDef="let element">{{ element.item_id }}</td>
          </ng-container>

          <ng-container matColumnDef="item_name">
            <th mat-header-cell *matHeaderCellDef>Item Name</th>
            <td mat-cell *matCellDef="let element">{{ element.item_name }}</td>
          </ng-container>
          <ng-container matColumnDef="parent_id">
            <th mat-header-cell *matHeaderCellDef>Parent Id</th>
            <td mat-cell *matCellDef="let element">{{ element.parent_id }}</td>
          </ng-container>
          <ng-container matColumnDef="itemname">
            <th mat-header-cell *matHeaderCellDef>Item Name</th>
            <td mat-cell *matCellDef="let element">{{ element.itemname }}</td>
          </ng-container>
          <ng-container matColumnDef="variant_name">
            <th mat-header-cell *matHeaderCellDef>Variant Name</th>
            <td mat-cell *matCellDef="let element">
              {{ element.variant_name }}
            </td>
          </ng-container>

          <!-- <ng-container matColumnDef="parentName">
            <th mat-header-cell *matHeaderCellDef>Parent Name</th>
            <td mat-cell *matCellDef="let element">
              {{ element.parentName }}
            </td>
          </ng-container> -->
          <ng-container matColumnDef="default_rate">
            <th mat-header-cell *matHeaderCellDef>Default Rate</th>
            <td mat-cell *matCellDef="let element">
              {{ element.default_rate }}
            </td>
          </ng-container>

          <ng-container matColumnDef="s_rate">
            <th mat-header-cell *matHeaderCellDef>S_Rate</th>
            <td mat-cell *matCellDef="let element">
              {{ element.s_rate }}
            </td>
          </ng-container>

          <ng-container matColumnDef="msg">
            <th class="text-center" mat-header-cell *matHeaderCellDef>
              Status
            </th>
            <td mat-cell *matCellDef="let element">
              <span *ngIf="element.success" class="badge badge-success">{{
                element.msg
              }}</span>
              <span *ngIf="!element.success" class="badge badge-danger">{{
                element.msg
              }}</span>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumnsVar"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumnsVar"
            (click)="selection.toggle(row)"
          ></tr>
        </table>
      </mat-tab>
    </mat-tab-group>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button class="btn mat-raised-button" (click)="close()">Close</button>
    <button
      *ngIf="selectedTab == 0"
      class="btn mat-raised-button"
      (click)="onImportDataClick()"
    >
      Import Data
    </button>
    <button
      *ngIf="selectedTab == 1"
      class="btn mat-raised-button"
      (click)="onImportVariantClick()"
    >
      Import Variant
    </button>
  </mat-dialog-actions>
</div>
