<div>
  <!-- Header Section -->

  <div class="main-container">
    <div class="card" [ngClass]="{ 'no-icon': !showIcon }">
      <!-- Placeholder for icon, only visible if the icon is shown -->
      <div class="icon-space">
        <app-icon
          *ngIf="showIcon"
          [iconName]="iconName"
          [iconColor]="iconColor"
          [iconSize]="iconSize"
        ></app-icon>
      </div>

      <div class="card-amount">
        <ng-container *ngIf="showCurrency">
          <i class="fa fa-{{ currency }}"></i>
        </ng-container>
        {{ amount | number : "1.2-2" }}
      </div>

      <div
        class="card-description"
        [ngStyle]="{
          'font-size.px': descriptionFontSize,
          'font-weight': descriptionFontWeight
        }"
      >
        {{ description }}
      </div>
    </div>
  </div>
</div>
