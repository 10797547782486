import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {DataService} from '../data.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-tenent-logout',
  templateUrl: './tenent-logout.component.html',
  styleUrls: ['./tenent-logout.component.css']
})
export class TenentLogoutComponent implements OnInit {

  constructor(private router: Router, 
    private apiService: DataService,
    private cookieService: CookieService
  ) { }

  ngOnInit() {
    this.load();
  }
  load() {
    this.apiService.tenentLogout()
    .subscribe((result: any) => {
      this.cookieService.delete('SESSIONID');
      localStorage.removeItem('token');
      localStorage.removeItem('userName');
      localStorage.removeItem('emailId');
            
      this.router.navigate(['tenent-login']);
    }, (result: any) => {

    });
  }
}
