<div id="bodyContainer">
  <h3 class="color-h3">Tenent Management :</h3>
  <button mat-icon-button class="mat-primary" (click)="addClick()">
    <mat-icon>add_circle</mat-icon>
  </button>
  <table mat-table [dataSource]="tenentObj" matSort>
    <ng-container matColumnDef="tenent_code">
      <th mat-header-cell *matHeaderCellDef [ngClass]="'customWidthClass'" mat-sort-header>Code</th>
      <td mat-cell *matCellDef="let row" [ngClass]="'customWidthClass'">{{row.tenent_code}}</td>
    </ng-container>

    <ng-container matColumnDef="tenent_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
      <td mat-cell *matCellDef="let row">{{row.tenent_name}}
        <span *ngIf="!row.active" class="danger">Canceled</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="db_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>DB Name</th>
      <td mat-cell *matCellDef="let row">{{row.db_name}}</td>
    </ng-container>
    <ng-container matColumnDef="active">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Active</th>
      <td mat-cell *matCellDef="let row">{{row.active}}</td>
    </ng-container>

    <ng-container matColumnDef="is_trial">
      <th mat-header-cell *matHeaderCellDef>Trial</th>
      <td mat-cell *matCellDef="let row">
        <mat-slide-toggle color="primary" [ngModel]="row.is_trial" color="primary" (ngModelChange)="updateIsTrial($event,row)">
        </mat-slide-toggle>
      </td>
    </ng-container>
    <ng-container matColumnDef="allow_multicompany">
      <th mat-header-cell *matHeaderCellDef>Multi Com</th>
      <td mat-cell *matCellDef="let row">
        <mat-slide-toggle color="primary" [ngModel]="row.allow_multicompany" color="primary"
          (ngModelChange)="updateAllowMultiCompany($event,row)">
        </mat-slide-toggle>
      </td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row">
        <button mat-icon-button (click)="editClick(row)"><mat-icon>edit</mat-icon></button>
        <button mat-icon-button color="warn" (click)="deleteClick(row)"><mat-icon>delete_outline</mat-icon></button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'table-danger': row.active == false}"></tr>
  </table>
</div>