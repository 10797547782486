<div class="panelcard">
  <h3 *ngIf="!prceListId" mat-dialog-title class="color-h3">Add Price List</h3>
  <h2 *ngIf="prceListId" mat-dialog-title class="color-h3">Edit Price List</h2>
  <div class="table-details">
    <div class="row">
      <div class="col-md-12">
        <fieldset class="border p-2 form-fieldset">
          <legend class="w-auto">Condition:</legend>
          <div class="row">
            <div class="col-md-12">
              <div class="col-md-6">
                <mat-form-field class="contract-full-width">
                  <input matInput placeholder="Name:" [(ngModel)]="model.name" name="name" required />
                </mat-form-field>
              </div>
              <fieldset class="border p-2 form-fieldset col-md-6">
                <legend class="w-auto">Applicable For:</legend>

                <div class="row">
                  <div class="col-md-12">
                    <section class="form-section col-md-4 col-12 d-inline-block">
                      <mat-checkbox class="form-margin" [(ngModel)]="model.Dine_in">
                        Dine-In
                      </mat-checkbox>
                    </section>

                    <section class="form-section col-md-4 col-12 d-inline-block">
                      <mat-checkbox class="form-margin" [(ngModel)]="model.Web_App">
                        Web App
                      </mat-checkbox>
                    </section>

                    <section class="form-section col-md-4 col-12 d-inline-block">
                      <mat-checkbox class="form-margin" [(ngModel)]="model.Web_site">
                        Web Site
                      </mat-checkbox>
                    </section>
                  </div>
                </div>
              </fieldset>

              <div class="col-md-6 mt-3">
                <form [formGroup]="tableGroupForm">
                  <mat-form-field class="contract-full-width">
                    <mat-select placeholder="Table Group:" multiple formControlName="tableGroupNames" required>
                      <mat-option *ngFor="let tablelist of tableGroupList" [value]="tablelist">
                        {{ tablelist.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <p>Note: A table group that is already assigned will not be assigned again.</p>
                </form>
              </div>

              <div class="row">
                <div class="active col-md-2 col-sm-2">
                  <section class="form-section">
                    <mat-checkbox class="form-margin" *ngIf="prceListId" [(ngModel)]="model.active">
                      Active
                    </mat-checkbox>
                  </section>
                </div>
                <div class="buttons d-flex col-md-4 col-sm-4 ms-2 justify-content-end">
                  <!-- Cancel button -->
                  <div class="text-left me-3" style="margin-right: 1rem">
                    <button mat-flat-button class="btn" (click)="cancel()">
                      Back
                    </button>
                  </div>
                  <!-- Delete button -->
                  <div class="text-left me-3" style="margin-right: 1rem">
                    <button mat-flat-button class="mfullwidth1 delete-btn" color="warn" *ngIf="prceListId"
                      (click)="DeleteNewPriceList()">
                      Delete
                    </button>
                  </div>
                  <!-- Update/Submit button -->
                  <div class="text-left">
                    <button mat-flat-button class="mfullwidth1 update-btn" color="primary" (click)="onSubmit()">
                      {{ prceListId ? "Update" : "Submit" }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
    </div>

    <fieldset class="border p-2 mt-3 form-fieldset">
      <div *ngIf="prceListId" class="mt-2">
        <mat-tab-group [selectedIndex]="selectedTab" (selectedIndexChange)="onTabChange($event)">
          <mat-tab label="Price List Item">
            <div class="table-details" style="margin-top: 10px;">
              <div class="row">
                <div class="col-md-2 col-sm-2">
                  <button mat-icon-button *ngIf="prceListId" class="mat-primary" (click)="addClick()">
                    <mat-icon>add_circle</mat-icon>
                  </button>
                </div>
                <div class="col-md-4 col-sm-4">
                  <mat-form-field *ngIf="prceListId" class="contract-full-width">
                    <input matInput placeholder="Search:" [(ngModel)]="searchText" name="searchText"
                      (keyup)="enterClick($event)" />
                  </mat-form-field>
                </div>
                <!-- <div class="col-md-2 col-6">
                  <section class="form-section">
                    <mat-checkbox
                      *ngIf="prceListId"
                      class="form-margin"
                      [(ngModel)]="activeType"
                      name="activeType"
                      >Include Inactive</mat-checkbox
                    >
                  </section>
                </div> -->
                <div class="col-md-2 col-sm-2">
                  <button mat-mini-fab *ngIf="prceListId" class="mat-primary serchBtn" (click)="search()">
                    <mat-icon class="searcIcon">search</mat-icon>
                  </button>
                </div>
                <div class="col-md-4 col-sm-4" style="display: flex; justify-content: end">
                  <button mat-flat-button *ngIf="prceListId" class="btn" (click)="importPricelistClick()"
                    style="height: 40px">
                    import
                  </button>
                </div>
              </div>
            </div>
            <div class="table-items" style="overflow-y: scroll">
              <table *ngIf="prceListId && filteredDataArr.length > 0" mat-table [dataSource]="filteredDataArr" matSort>
                <ng-container matColumnDef="id">
                  <th mat-header-cell *matHeaderCellDef [ngClass]="'customWidthClass'" mat-sort-header>
                    ID
                  </th>
                  <td mat-cell *matCellDef="let row" data-label="id" [ngClass]="'customWidthClass imageclass'">
                    {{ row.id }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="item_id">
                  <th mat-header-cell *matHeaderCellDef [ngClass]="'customWidthClass'" mat-sort-header>
                    ITEM_ID
                  </th>
                  <td mat-cell *matCellDef="let row" data-label="item_id" [ngClass]="'customWidthClass'">
                    {{ row.item_id }}
                  </td>
                </ng-container>
                <!-- <ng-container matColumnDef="sub_cat_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Sub-Category Name</th>
                <td mat-cell *matCellDef="let row" data-label="Name">{{row.sub_cat_name}}
                  <span *ngIf="!row.active" class="badge badge-danger">inactive</span>
                </td>
              </ng-container> -->
                <ng-container matColumnDef="category_name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    CATEGORY
                  </th>
                  <td mat-cell *matCellDef="let row" data-label="category_name">
                    {{ row.category_name }}
                    <!-- <span *ngIf="!row.active" class="badge badge-danger"
                    >inactive</span
                  > -->
                  </td>
                </ng-container>

                <ng-container matColumnDef="item_name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    ITEM NAME
                  </th>
                  <td mat-cell *matCellDef="let row" data-label="Name">
                    {{ row.item_name }}
                    <!-- <span *ngIf="!row.active" class="badge badge-danger"
                    >inactive</span
                  > -->
                  </td>
                </ng-container>

                <ng-container matColumnDef="default_rate">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    DEFAULT RATE
                  </th>
                  <td mat-cell *matCellDef="let row" data-label="default_rate">
                    {{ row.default_rate }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="s_rate">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    S_Rate
                  </th>
                  <td mat-cell *matCellDef="let row" data-label="Sell Rate">
                    {{ row.s_rate }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="gst">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Taxslab
                  </th>
                  <td mat-cell *matCellDef="let row" data-label="GST">
                    {{ row.taxslab }} %
                  </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    ACTION
                  </th>
                  <td mat-cell *matCellDef="let row" data-label="actions">
                    <!-- <mat-slide-toggle
                      [ngModel]="row.wera_active"
                      color="primary"
                      (ngModelChange)="updateItemWeraActive($event, row)"
                      *ngIf="checkEnableWeraFlag"
                    > -->
                    <!-- </mat-slide-toggle> -->
                    <button mat-icon-button (click)="editClick(row)">
                      <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-icon-button color="warn" (click)="DeleteItemPriceListId(row)">
                      <mat-icon>delete_outline</mat-icon>
                    </button>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"
                  [ngClass]="{ 'table-danger': row.active == false }"></tr>
              </table>
            </div>
          </mat-tab>
          <mat-tab label="Price List Item Variant">
            <div class="table-details" style="margin-top: 10px;">
              <div class="row d-flex justify-content-between">
                <div class="col-md-4 col-sm-6">
                  <mat-form-field *ngIf="prceListId" class="contract-full-width">
                    <input matInput placeholder="Search:" [(ngModel)]="searchText" name="searchText"
                      (keyup)="enterClick($event)" />
                  </mat-form-field>
                </div>
                <div class="col-md-2 col-sm-2 d-flex align-items-center">
                  <button mat-mini-fab *ngIf="prceListId" class="mat-primary serchBtn" (click)="search()">
                    <mat-icon class="searcIcon">search</mat-icon>
                  </button>
                </div>
                <div class="col-md-4 col-sm-4 d-flex justify-content-end">
                  <button mat-flat-button *ngIf="prceListId" class="btn" (click)="importPricelistClick()"
                    style="height: 40px">
                    Import
                  </button>
                </div>
              </div>
            </div>

            <div class="table-details">
              <div class="table-items" style="overflow-y: scroll">
                <table mat-table [dataSource]="filteredVarAr" matSort>
                  <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef [ngClass]="'customWidthClass'" mat-sort-header>
                      ID
                    </th>
                    <td mat-cell *matCellDef="let row" data-label="id" [ngClass]="'customWidthClass imageclass'">
                      {{ row.id }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="item_id">
                    <th mat-header-cell *matHeaderCellDef [ngClass]="'customWidthClass'" mat-sort-header>
                      ITEM_ID
                    </th>
                    <td mat-cell *matCellDef="let row" data-label="item_id" [ngClass]="'customWidthClass'">
                      {{ row.item_id }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="itemanme">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      Item Name
                    </th>
                    <td mat-cell *matCellDef="let row" data-label="itemanme">
                      {{ row.item_name }}

                    </td>
                  </ng-container>
                  <ng-container matColumnDef="variant_name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      Variant Name
                    </th>
                    <td mat-cell *matCellDef="let row" data-label="variant_name">
                      {{ row.variant_name }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="default_rate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      DEFAULT RATE
                    </th>
                    <td mat-cell *matCellDef="let row" data-label="default_rate">
                      {{ row.default_rate }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="s_rate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      S_Rate
                    </th>
                    <td mat-cell *matCellDef="let row" data-label="S_Rate">
                      {{ row.s_rate }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      ACTION
                    </th>
                    <td mat-cell *matCellDef="let row" data-label="actions">

                      <button mat-icon-button (click)="editvariant(row)">
                        <mat-icon>edit</mat-icon>
                      </button>
                      <button mat-icon-button color="warn" (click)="Deletevariant(row)">
                        <mat-icon>delete_outline</mat-icon>
                      </button>
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumnsV"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumnsV"
                    [ngClass]="{ 'table-danger': row.active == false }"></tr>
                </table>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </fieldset>
  </div>
</div>