<div class="card-container">
  <div class="header-container">
    <div class="header1">
      <p>Order Statistics</p>
    </div>
    <div class="header2">
      <p>
        <!-- Use *ngFor to dynamically generate dropdown options -->
        <select
          (change)="onDateRangeChange($event)"
          class="date-range-dropdown"
        >
          <option *ngFor="let range of dateRanges">{{ range.label }}</option>
        </select>
      </p>
      <!-- <p class="refresh">
        <button class="refresh-btn" (click)="refreshData()">
          <i class="fa fa-refresh" [ngClass]="{ rotate: isRotating }"></i>
        </button>
      </p> -->
    </div>
  </div>
  <div class="grid-container">
    <!-- Use *ngFor to dynamically generate stat items -->
    <div class="col-md" *ngFor="let stat of statsArray">
      <div class="stat-item-view"></div>
      <div class="stat-details">
        <p class="stat-value" [ngClass]="stat.colorClass">{{ stat.value }}</p>
        <p class="stat-label">{{ stat.label }}</p>
      </div>
    </div>
  </div>
</div>
