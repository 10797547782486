import { Component, OnInit, Inject } from "@angular/core";
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";

// 
import { RstKotComponent } from "../rst-kot.component";

import { MatDialogModule } from "@angular/material/dialog";
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { DataService } from "../../../data.service";
import { SharedModule } from "../../../common.module";
import { RstDashboardService } from "../../../_services/rst-dashboard.service";

@Component({
  selector: "app-kot-cancel-dialog",
  templateUrl: "./kot-cancel-dialog.component.html",
  styleUrls: ["./kot-cancel-dialog.component.css"],
  standalone: true,
  imports: [
    SharedModule
  ]
})
export class KotCancelDialogComponent implements OnInit {
  constructor(
    private apiService: RstDashboardService,
    // /
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<KotCancelDialogComponent>,
    private dialogReff: MatDialogRef<RstKotComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  myControl = new FormControl("");
  options: string[] = ["Change of Mind", "Incorrect Order", "Price Concerns", "Service Issues"];
  filteredOptions: Observable<any[]> = new Observable<any>();;

  ngOnInit() {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value || ""))
    );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  onClose() {
    this.dialogRef.close();
  }

  cancelKOT() {
    const Reason = { cancel_reason: this.myControl.value };
    if (Reason.cancel_reason) {
      this.apiService
        .deleteSalesOrder(this.data.transaction_id, Reason)
        .subscribe(
          (result: any) => {
            if (result.success) {
              // notyf.success( "KOT Canceled successfully");
              this.dialogReff.close({ success: true });
            } else {
              // notyf.error(result.message);
            }
          },
          (error: any) => {
            // notyf.error( "unable to cancel KOT");
          }
        );
    }else{
      // notyf.error( "Please Select Or Write Reason");

    }
  }
}
