import { CommonModule } from "@angular/common";
import {
  Component,
  Input,
  Output,
  EventEmitter,
  HostListener,
} from "@angular/core";
import { IconComponent } from "../icons/icons.component";

interface MenuItem {
  label: string;
  icon: string;
  actionName: string;
}

@Component({
  selector: "app-dropdown-menu-card",
  templateUrl: "./dropdown-menu-card.component.html",
  styleUrls: ["./dropdown-menu-card.component.css"],
  standalone: true,
  imports: [CommonModule, IconComponent],
})
export class DropdownMenuCardComponent {
  @Input() companyName: string = "Restaurant";
  @Input() title: string = "User";
  @Input() role: string = "Administrator";
  @Output() itemClicked = new EventEmitter<string>();

  @Input() iconColor: string = "#000"; // Default icon color
  @Input() iconSize: string = "24px"; // Default icon size
  // @Input() menuItems: { label: string; icon: string }[] = [];
  @Input() menuItems: MenuItem[] = [];
  @Input() isCircular: boolean = false; // Default to square background
  @Input() isLabel: boolean = false; // Default to square background
  @Input() isConnected: boolean = false;

  isOpen = false;

  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  onItemClick(actionName: string) {
    this.itemClicked.emit(actionName); // Emit action name
    this.isOpen = false; // Close the dropdown after selecting an item
  }

  // Close the dropdown when clicking outside
  @HostListener("document:click", ["$event.target"])
  onClickOutside(targetElement: HTMLElement) {
    const clickedInside = targetElement.closest(".dropdown-container");
    if (!clickedInside) {
      this.isOpen = false;
    }
  }
}
