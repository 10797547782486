import { CommonModule } from "@angular/common";
import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-total-reservation-request",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./total-reservation-request.component.html",
  styleUrls: ["./total-reservation-request.component.css"],
})
export class TotalReservationRequestComponent {
  @Input() requestCount: number = 0;
  @Input() unApprovedRequestCount: number = 0;

  @Output() ReservationDas = new EventEmitter<{}>();

  tableReservationDashboard() {
    this.ReservationDas.emit();
  }
}
