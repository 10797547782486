<div style="padding: 25px;">
  <h2 mat-dialog-title class="text-center">Transfer Table</h2>
  <br>
  <div *ngFor="let table of owntable"><span style="font-size: larger; font-weight:500;">From Table :</span>
    {{table.name}}</div>
  <div>
    <mat-dialog-content class="d-flex">
      <div style="font-size: larger; font-weight:500; width: 40%;padding-top: 15px;">To Table :</div>
      <mat-form-field style="width: 50%;">
        <mat-label>Select Table</mat-label>
        <mat-select [(value)]="data.selectedTable">
          <mat-option *ngFor="let table of tableDetails" [value]="table.id">
            {{ table.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-dialog-content>
    <div>
      <button type="button" #btnGrp class="btn-primary" (click)="processTransfer()"
        style="float: right;margin-bottom: 20px;margin-top: 10px;">Transfer</button>
      <button type="button" #btnGrp class="btn"
        style="float: right;margin-bottom: 20px;margin-top: 10px; margin-right: 5px;" mat-dialog-close>Cancel</button>
    </div>
  </div>
</div>