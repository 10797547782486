<div class="container">
  <div class="row">
    <div class="col-3">
      <h3 class="color-h3">Categories</h3>
    </div>
  </div>

  <div class="main-container">
    <app-item-card
      *ngFor="let category of categories; let i = index"
      [imgurl]="category.imgurl"
      [categoryData]="category"
      [categoryName]="category.category_name"
      [selected]="selectedIndex === i"
      [selectedBorderColor]="selectedBorderColor"
      [selectedBackgroundColor]="selectedBackgroundColor"
      [showPrice]="showPrice"
      (click)="showitems(i)"
    ></app-item-card>
  </div>
</div>
