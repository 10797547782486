<div style="background-color: white">
  <div style="background-color: white">
    <div *ngIf="is_trial == 'true'" class="row trial" [ngClass]="themes">
      <div class="col-md-12 text-center alert alert-danger p-0">
        <span>You are in <strong>Trial mode</strong></span>
      </div>
    </div>
    <!-- <mat-toolbar
    [ngClass]="{
      'fixed-header': is_trial == 'false',
      'trial-header': is_trial == 'true'
    }"
  >
   </mat-toolbar> -->
    <app-top-header
      [welcomeMessage]="'Welcome'"
      [profileName]="userFullName"
      [backgroundColor]="'#e9ecef'"
      (itemClickedFromDropdown)="handleDropdownAction($event)"
      (iconClicked)="onrightToolbarMenuToggle()"
      [userFullName]="userFullName"
      [userRole]="userRole"
      [company_name]="company_name"
      [isConnected]="is_connected"
      [notificationCount]="notificationCount"
      [menuGroupObj]="menuGroupObj"
      [roleId]="role_id"
      [allowMulticompany]="allow_multicompany"
    ></app-top-header>

    <!-- Sidebar -->
    <mat-sidenav-container class="sidenav-container">
      <mat-sidenav
        fixedInViewport="true"
        mode="side"
        #sidenav
        id="sidenav"
        [opened]="isMenuOpen"
      >
        <app-sidebar
          [logo]="'Intellibook'"
          [logoFontSize]="24"
          [textColor]="'#212529'"
          [iconPanelBackgroundColor]="'#e9ecef'"
          [iconPanelTextColor]="'#495057'"
          [sidebarBackgroundColor]="'#ffffff'"
          [sidebarTextColor]="'#343a40'"
          [menuFontSize]="15"
          [menuFontStyle]="'normal'"
          [submenuFontSize]="14"
          [menuItemTextColor]="'#495057'"
          [submenuTextColor]="'#6c757d'"
          [searchTextColor]="'#495057'"
          [searchBorderColor]="'#ced4da'"
          [selectedIconBackgroundColor]="'#d1e4f1'"
          [menuGroups]="this.menuGroupObj"
          (sidebarToggle)="handleSidebarToggle($event)"
          [helpDeskUrl]="helpDeskUrl"
        >
          ></app-sidebar
        >
      </mat-sidenav>
    </mat-sidenav-container>
    <div class="row-fullpage d-flex">
      <!-- Right Content -->
      <div
        class="col-md-right content-scroll"
        [ngClass]="{
          bodyContent: is_trial == 'true',
          collapsed: isSidebarCollapsed
        }"
        [ngStyle]="{
        'margin-left': isSidebarCollapsed ? '6%' : '20%',
      }"
        style="background: white; min-height: 100vh"
      >
        <router-outlet></router-outlet>
      </div>
    </div>

    <mat-sidenav-container class="sidenav-container" [hasBackdrop]="true">
      <mat-sidenav
        fixedInViewport="true"
        mode="side"
        position="end"
        #sidenav
        id="sidenav"
        [class.menu-open]="rightMenuOpen"
        [class.menu-close]="!rightMenuOpen"
        opened
        style="padding-top: 10px"
      >
        <app-total-reservation-request
          [requestCount]="requestCount"
          [unApprovedRequestCount]="unApprovedRequestCount"
          (ReservationDas)="tableReservationDashboard()"
        ></app-total-reservation-request>
        <hr />

        <app-left-sidebar-card
          [websiteInvoiceData]="websiteInvoiceData"
          [currency]="currency"
          (siteOrder)="siteOrderApprove($event.event, $event.data)"
          (siteOrderRej)="siteOrderReject($event.event, $event.data)"
          (invoice)="
            riderInvoice($event.transaction_id, $event.display_trans_no)
          "
          (notification)="NotificationSeen($event.data, $event.event)"
        ></app-left-sidebar-card>
        <hr />
        <!-- </mat-menu> -->

        <!--------------------------------web app codes------------------------------------------------------>
        <!-- <div *ngIf="kotArr && kotArr.length > 0">
        <div *ngFor="let kot of kotArr" class="p-2 not-sec">
          <mat-card *ngIf="kot.order_mode.toLowerCase() != 'website'">
            <mat-card-content>
              <div class="w-100 not-head border border-1">
                <img
                  style="max-width: 52px; border-radius: 12px"
                  *ngIf="
                    kot.order_mode.toLowerCase() === 'swiggy' ||
                    kot.order_mode.toLowerCase() === 'zomato'
                  "
                  src="assets/images/{{ kot.order_mode }}.png"
                  alt=""
                  class="pull-right"
                />
                <span>#KOT: {{ kot.trans_no }}</span>
                <span
                  class="pull-right"
                  *ngIf="
                    kot.order_mode.toLowerCase() !== 'swiggy' &&
                    kot.order_mode.toLowerCase() !== 'zomato'
                  "
                  >Table: {{ kot.name }}</span
                >
              </div>
              <mat-list role="list">
                <mat-list-item
                  *ngFor="let item of kot.items_details"
                  role="listitem"
                  class="ml-3"
                >
                  <div class="w-100">
                    <span
                      >{{ convertFloat(item.qty) }} x {{ item.item_name }}</span
                    >
                    <span class="pull-right pr-3"
                      ><i class="fa fa-{{ currency }}"></i
                      >{{ item.amount | number : "1.0-0" }}</span
                    >
                  </div>
                </mat-list-item>
              </mat-list>
            </mat-card-content>
            <mat-card-actions>
              <button
                type="button"
                class="btn btn-primary btn-sm not-btn"
                (click)="onApproveClick($event, kot)"
                *ngIf="!kot.food_cancel && !kot.auto_accept"
              >
                Approve
              </button>
              <button
                type="button"
                class="btn btn-danger btn-sm not-btn"
                (click)="onRejectClick($event, kot)"
                *ngIf="!kot.food_cancel && !kot.auto_accept"
              >
                Reject
              </button>
              <button
                type="button"
                class="btn btn-danger"
                (click)="cancelledOrder($event, kot)"
                *ngIf="kot.food_cancel && !kot.auto_accept"
              >
                Order Cancelled
              </button>
              <button
                type="button"
                class="btn btn-success"
                (click)="orderAutoAccepted($event, kot)"
                *ngIf="kot.auto_accept"
              >
                Auto Accepted
              </button>
            </mat-card-actions>
          </mat-card>
        </div>
      </div> -->
        <app-web-app-card
          [kotArr]="kotArr"
          [currency]="currency"
          (approve)="onApproveClick($event.event, $event.kot)"
          (reject)="onRejectClick($event.event, $event.kot)"
          (accept)="orderAutoAccepted($event.event, $event.kot)"
          (cancel)="cancelledOrder($event.event, $event.kot)"
        ></app-web-app-card>
        <!-- </mat-menu> -->
      </mat-sidenav>
      <mat-sidenav-content [ngStyle]="{ 'margin-left.px': contentMargin }">
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</div>
