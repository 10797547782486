import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
// 
import { Notyf } from 'notyf';

const notyf = new Notyf({
  position: {
    x: 'right',
    y: 'top',
  }
});


import { MatDialogModule } from "@angular/material/dialog";
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';

import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';

import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { SharedModule } from '../common.module';
import { DataService } from '../data.service';

@Component({
  selector: 'db-backup',
  templateUrl: './db-backup.component.html',
  styleUrls: ['db-backup.component.css'],
  standalone: true,
  imports: [
    SharedModule
  ]

})
export class DBbackupComponent implements OnInit {

  result: any;

  constructor(
    private apiService: DataService, 
    // 

    @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<DBbackupComponent>) {

  }
  ngOnInit() {

  }
  google_drive() {

    this.apiService.create_google_drive_DBBackup().subscribe(
      (result: any) => {
        if (result.success) {
          this.dialogRef.close(result.url);

        }
        else {
          // notyf.error( 'something went wrong');
        }
      },
      (result: any) => {

      });


  }

  local_storage() {
    this.apiService.createDBBackup()
      .subscribe((result: any) => {
        let blob = new Blob([result], {
          type: 'application/tar'
        });
        var dt = new Date();
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'IB' + dt.toJSON().slice(0, 23).replace(/[-T]/g, '').replace(/[:]/g, '').replace(/[.]/g, '').replace(/[-]/g, '') + '.tar';
        link.click();
        window.URL.revokeObjectURL(link.href);
        this.dialogRef.close(null);

      }, (result: any) => {
      });
  }
  closeDialog() {
    this.dialogRef.close(null);
  }

}



