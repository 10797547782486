<div class="main-container" *ngIf="kotArr && kotArr.length > 0">
  <div>
    <div *ngFor="let kot of kotArr" class="card-container">
      <div class="card-header">
        <span>#KOT: {{ kot.trans_no }}</span>
        <span
          *ngIf="
            kot.order_mode.toLowerCase() !== 'swiggy' &&
            kot.order_mode.toLowerCase() !== 'zomato'
          "
        >
          Table: {{ kot.name }}
        </span>
      </div>

      <div class="item-details">
        <div *ngFor="let item of kot.items_details" class="item-row">
          <span class="item-name"
            >{{ item.qty | number : "1.0-0" }} x {{ item.item_name }}</span
          >

          <span class="amount">
            <i class="fa fa-{{ currency }}"></i
            >{{ item.amount | number : "1.0-0" }}
          </span>
        </div>
      </div>

      <div class="card-actions">
        <button
          *ngIf="!kot.food_cancel && !kot.auto_accept"
          class="btn approve-btn"
          (click)="onApproveClick($event, kot)"
        >
          Approve
        </button>
        <button
          *ngIf="!kot.food_cancel && !kot.auto_accept"
          class="btn reject-btn"
          (click)="onRejectClick($event, kot)"
        >
          Reject
        </button>
        <button
          *ngIf="kot.auto_accept"
          class="btn auto-accept-btn"
          (click)="orderAutoAccepted($event, kot)"
        >
          Auto Accepted
        </button>
        <button
          class="btn cancel-btn"
          *ngIf="kot.food_cancel"
          (click)="cancelledOrder($event, kot)"
        >
          Order Cancelled
        </button>
      </div>
    </div>
  </div>
</div>
