<div class="dbrd panelcard">
  <h3 class="color-h3">Dashboard</h3>
  <div class="table-details">
    <div class="row">
      <br /><br />
      <div
        class="col-md-3"
        style="cursor: pointer"
        [routerLink]="['/Report/voucher-details/5']"
      >
        <!-- <app-d-invoice></app-d-invoice>  -->
      </div>
      <div
        class="col-md-3 col-sm-6"
        style="cursor: pointer"
        [routerLink]="['/Report/voucher-details/7']"
      >
        <!-- <app-d-purchase></app-d-purchase> -->
      </div>
      <div
        class="col-md-3 col-sm-6"
        style="cursor: pointer"
        [routerLink]="['/Report/voucher-details/3']"
      >
        <!-- <app-do></app-do> -->
      </div>
      <div
        class="col-md-3"
        style="cursor: pointer"
        [routerLink]="['/Report/voucher-details/2']"
      >
        <!-- <app-so></app-so> -->
      </div>

      <!-- <div class="col-md-4 crdp"><app-pending-dlvry></app-pending-dlvry></div>
      <div class="col-md-4 crdp"><app-pending-ordrs></app-pending-ordrs></div> -->
      <div class="col-md-4 crdp" [routerLink]="['/Report/reorder_list']">
        <!-- <app-reorder-stock></app-reorder-stock> -->
      </div>

      <div class="clearfix"></div>
      <br />
      <div class="col-md-12 inv">
        <!-- <app-sales-invoice></app-sales-invoice> -->
      </div>
    </div>
  </div>
</div>
