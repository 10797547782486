<!-- <div style="float: right;
margin-top: 20px;
margin-right: 20px;" class="row">
  <div class="col-12">

    <button mat-flat-button type="button" color="primary" style="margin-right: 3px;" (click)="onReset()">Reset</button>
    <button mat-flat-button type="button" color="primary" (click)="cancel()">Cancel</button>
  </div>
</div> -->
<div class="dialog-title" mat-dialog-title>
  <h3 class="color-h3">Reservation Form
    <span><mat-icon class="close-icon" style="float: right;" (click)="onCancel()">close</mat-icon></span>
  </h3>
</div>
<div class="table-details">

  <div mat-dialog-content style="margin-top: 27px;
  padding-left: 27px;">
    <div class="reservation-form">
      <form [formGroup]="reservationForm" (ngSubmit)="onSubmit()">
        <!-- First Row -->
        <div class="form-row">
          <mat-form-field class="form-group">
            <mat-label>Date</mat-label>
            <mat-icon matPrefix>date_range</mat-icon>
            <input matInput type="date" formControlName="date" required>
          </mat-form-field>

          <mat-form-field class="form-group margin">
            <mat-label>Time</mat-label>
            <mat-icon matPrefix>access_time</mat-icon>
            <input matInput type="time" formControlName="time" required>
          </mat-form-field>
        </div>

        <!-- Second Row -->
        <div class="form-row">
          <!-- Add icons and styles as needed for other fields -->
          <mat-form-field class="form-group">
            <mat-label>Name</mat-label>
            <mat-icon matPrefix>person</mat-icon>
            <input matInput type="text" formControlName="name" required>
          </mat-form-field>

          <mat-form-field class="form-group margin">
            <mat-label>Number of Persons</mat-label>
            <mat-icon matPrefix>group</mat-icon>
            <input matInput type="number" formControlName="numberOfPersons" required>
          </mat-form-field>
        </div>

        <!-- Third Row -->
        <div class="form-row">
          <mat-form-field class="form-group">
            <mat-label>Mobile Number</mat-label>
            <mat-icon matPrefix>phone</mat-icon>
            <input matInput type="tel" formControlName="mobileNumber" required>
          </mat-form-field>

          <mat-form-field class="form-group margin">
            <mat-label>Email</mat-label>
            <mat-icon matPrefix>email</mat-icon>
            <input matInput type="email" formControlName="email" required>
          </mat-form-field>
        </div>

        <!-- Fourth Row -->
        <div class="form-row">


          <mat-form-field class="form-group">
            <mat-label>Waiter Assigned</mat-label>
            <mat-icon matPrefix>assignment_ind</mat-icon>
            <input matInput type="text" formControlName="waiterAssigned">
          </mat-form-field>
          <mat-form-field style="margin-top: 5px;" class="form-group margin">
            <mat-label>Table</mat-label>
            <mat-icon matPrefix>table</mat-icon>
            <mat-select formControlName="table">
              <mat-option *ngFor="let table of tables" [value]="table.name">{{ table.name }}</mat-option>
            </mat-select>
          </mat-form-field>

        </div>
        <!-- Fourth Row 2  -->
        <div class="form-row">

          <mat-form-field class="form-group">
            <mat-label>Comments</mat-label>
            <mat-icon matPrefix>comment</mat-icon>
            <textarea matInput formControlName="comments"></textarea>
          </mat-form-field>
          <mat-form-field style="margin-top: 16px;" class="form-group margin">
            <mat-label>Status</mat-label>
            <mat-icon matPrefix>status</mat-icon>
            <mat-select formControlName="status">
              <mat-option value="Confirmed">Confirmed</mat-option>
              <mat-option value="InProgress">In Progress</mat-option>
            </mat-select>
          </mat-form-field>

        </div>

        <!-- Fifth Row -->
        <div class="form-row">
          <div class="form-group col-md-12">
            <mat-checkbox class="sendInfo" formControlName="sendEmail">Send Email</mat-checkbox>
            <mat-checkbox class="sendInfo" formControlName="sendSMS">Send SMS</mat-checkbox>
            <mat-checkbox class="sendInfo" formControlName="isAllotted">Allotted</mat-checkbox>
          </div>
        </div>
        <!-- <div class="form-group">
          <button style="margin-top: -7px;" mat-flat-button type="submit" color="primary">Submit</button>
        </div> -->
      </form>
    </div>
  </div>
  <!-- <div class="col-3" style="margin-top: 92px;">
    <mat-form-field style="margin-top: 5px;" class="form-group">
      <mat-label>All Bookings</mat-label>
      <mat-icon matPrefix>All Bookings</mat-icon>
      <mat-select formControlName="tableBookings">
        <mat-option value=""></mat-option>
      </mat-select>
    </mat-form-field>
  </div> -->
  <div mat-dialog-actions style="place-content: flex-end;">
    <button mat-raised-button class="btn mfullwidth1" (click)="onCancel()">Cancel</button>
    <button mat-raised-button class=" mfullwidth1 form-margin" color="warn" *ngIf="reservtionData"
      (click)="onRejectClick()">Reject</button>
    <button mat-raised-button class="btn mfullwidth1 btnMarginLeft" *ngIf="!reservtionData"
      (click)="onSubmit()">Submit</button>
    <button mat-raised-button class="btn mfullwidth1 btnMarginLeft" *ngIf="reservtionData"
      (click)="onSubmit()">Update</button>
  </div>
</div>