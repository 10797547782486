import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

// 
import { Notyf } from 'notyf';

const notyf = new Notyf({
  position: {
    x: 'right',
    y: 'top',
  }
});
import { BookingFormComponent } from '../booking-form/booking-form.component';
import { DatePipe } from '@angular/common';
import { ReceiptDialogComponent } from '../receipt-dialog/receipt-dialog.component';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { SocketServiceService } from '../../socket-service.service';
import { SharedModule } from '../../common.module';
import { RstDashboardComponent } from '../rst-dashboard.component';
import { RstDashboardService } from '../../_services/rst-dashboard.service';



@Component({
  selector: 'app-booktable',
  templateUrl: './booktable.component.html',
  styleUrls: ['./booktable.component.css'],
  standalone: true,
  imports: [
   SharedModule
  ]
})
export class BooktableComponent implements OnInit {


  // displayedColumns = ['10:00','10:30','11:00','11:30','12:00','12:30','13:00','13:30','14:00','14:30','15:00','15:30','16:00','16:30',];
  // @ViewChild(MatSort) sort!: MatSort;
  // @ViewChild(MatPaginator) paginator!: MatPaginator;
  // tables = [ "T1","T2","T3","T4","T5","T6","T7","T8","T9","T10","T11",]
  UnApprovedReservedTablesArr: any = []
  dataSource: any = []
  requestCount: any;
  itemObj: any = [];
  tblStatus: any = []
  displayReservationSummaryColumns: string[] = ['display', 'date', 'time', 'customerName', 'seat', 'bookings', 'edit'];
  displayTableStatusColumns: string[] = ['Group', 'Table', 'Capacity', 'IsKOT', 'IsWebactive', 'IsAllotted'];
  @ViewChild('TABLE') table!: ElementRef;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  constructor(private apiService: RstDashboardService,
    public dialog: MatDialog,
    // 
    private socketService: SocketServiceService,
    //  private datePipe: DatePipe, 
     private dialogRef: MatDialogRef<ReceiptDialogComponent>) {
    this.updateFormattedDate();
  }


  ngOnInit() {
    // this.apiService.getTablesAndColumns().subscribe(data => {
    //   this.tables = data.tables;
    //   this.displayedColumns = ['Table', ...data.columns];
    //   this.dataSource = this.generateData();
    // });
    this.connectSocket()
    this.load()
  }
  connectSocket() {
    this.socketService.on('tableReservation')
      .subscribe((result: any) => {
        this.load()
      });
  }

  load() {
    this.apiService.getUnApprovedReservedTables()
      .subscribe((result: any) => {
        this.UnApprovedReservedTablesArr = result.tblReservationData
        this.dataSource = this.UnApprovedReservedTablesArr
        this.tblStatus = result.tblStatus
        const count = this.UnApprovedReservedTablesArr.filter((e:any) => {
          if (e.approved != "Confirmed") {
            return e
          }
        })
        if (count.length > 0) {
          this.requestCount = count.length
        }
        // this.startTimer();
        // this.spinner.hide();
      }, (err: any) => {
        // this.spinner.hide();
      });
  }

  currentDate: Date = new Date();

  goBack() {
    this.currentDate.setDate(this.currentDate.getDate() - 1);
    this.updateFormattedDate();
  }

  goForward() {
    this.currentDate.setDate(this.currentDate.getDate() + 1);
    this.updateFormattedDate();
  }
  formattedDate: any = "";
  private updateFormattedDate() {
    // this.formattedDate = this.datePipe.transform(this.currentDate, 'dd-MM-yy');
  }


  dateChanged(selectedDate: any) {
    this.currentDate = selectedDate;
    // this.currentDate.setDate(selectedDate);
  }




  // displayedColumns: string[] = [
  //   'Table',
  //   '10:00', '10:30', '11:00', '11:30',
  //   '12:00', '12:30', '13:00', '13:30',
  //   '14:00', '14:30', '15:00', '15:30',
  //   '16:00', '16:30'
  // ];

  // tables = ["T1", "T2", "T3", "T4", "T5", "T6", "T7", "T8", "T9", "T10", "T11"];
  // dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();

  // @ViewChild(MatSort) sort!: MatSort;
  // @ViewChild(MatPaginator) paginator!: MatPaginator;

  // ngAfterViewInit() {
  //   this.dataSource.sort = this.sort;
  //   this.dataSource.paginator = this.paginator;
  // }



  // generateData() {
  //   return this.tables.map(table => {
  //     const row = { Table: table };
  //     this.displayedColumns.slice(1).forEach(column => {
  //       row[column] = false; // Initialize all checkboxes to false
  //     });
  //     return row;
  //   });
  // }

  // displayedColumns: string[] = [];
  // tables: string[] = [];
  // dataSource: any = [];
  // generateData() {
  //   return this.tables.map(table => {
  //     const row = { Table: table };
  //     this.displayedColumns.slice(1).forEach(column => {
  //       row[column] = false; // Initialize all checkboxes to false
  //     });
  //     return row;
  //   });
  // }

  reservationForm(i:any) {
    // alert(contact)
    this.dialog.open(BookingFormComponent, {
      width: '500px',
      data: i
    })
      .afterClosed().subscribe(result => {
        if (result)
          if (result.status == 'OK') {
            this.load();
          }
      })
  }
  selfReservation() {
    // alert(contact)
    this.dialog.open(BookingFormComponent, {
      width: '500px',
      data: null
    })
      .afterClosed().subscribe(result => {
        if (result)
          if (result.status == 'OK') {
            this.load();
          }
      })
  }
  onCancel() {
    this.dialogRef.close()
  }



  itemControl = new FormControl();
  filteredOptions: Observable<any[]> = new Observable<any>();;

  _filter(value:any) {
    const filterValue = typeof (value) != 'object' ? value.toLowerCase() : value;
    return this.itemObj.filter((item:any) => item.name.toLowerCase().includes(filterValue) || item.name.toLowerCase().includes(filterValue));
  }
  search(event:any) {
    if (event.key != "ArrowDown" && event.key != "ArrowUp") {
      if (this.itemControl.value.length > 1) {

        this.filteredOptions = this.itemControl.valueChanges
          .pipe(
            startWith(''),
            map(value => this._filter(value))
          );

      } else {
        this.itemObj = [];
        this.filteredOptions = this.itemControl.valueChanges
          .pipe(
            startWith(''),
            map(value => this._filter(value))
          );
      }
    }

  }


  displayFn(Item:any) {
    return Item ? Item.name : "";
  }
  // initialiseItem() {
  //   this.itemControl.setValue(null);
  //   // this.itemObj = [];
  //   this.filteredOptions = this.itemControl.valueChanges
  //     .pipe(
  //       startWith(''),
  //       map(value => this._filter(value))
  //     );
  // }

  onFocus(e:any) {

    // this.apiService.getItemList()
    //   .subscribe((result: any) => {
    this.itemObj = this.UnApprovedReservedTablesArr;
    this.filteredOptions = this.itemControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
    //   }, (result: any) => {
    //   });
  }

  onEnterKey(event: KeyboardEvent) {
    event.preventDefault();
    // if (event.target === this.sitm.nativeElement) {
    //   this.filteredOptions.subscribe(options => {
    //     if (options.length === 1) {
    //       this.itemControl.setValue(options[0]);
    //       this.onAddItem();
    //     }
    //   });
    // }
    // this.onAddItem();
  }


  itemSelection(event: MatAutocompleteSelectedEvent) {
    if (event.option.value != 0) {
      // this.focusSetting()
      // var item_id = event.option.value ? event.option.value.item_id : undefined;
      // var indexofItem = this.itemObj.findIndex((i:any) => i.item_id === item_id);
      // this.openCustomizationsearch(this.itemObj[indexofItem], event);
    }

  }

  selectedDate: Date = new Date();

  goToPreviousDay(): void {
    this.selectedDate = new Date(this.selectedDate.getTime() - 86400000); // Subtract one day in milliseconds
    const dateObject = new Date(this.selectedDate);

    // Convert the Date object to ISO string
    const isoString = dateObject.toISOString();
    this.apiService.getUnApprovedReservedTableswithDate(isoString)
      .subscribe((result: any) => {
        this.UnApprovedReservedTablesArr = result
        const count = this.UnApprovedReservedTablesArr.filter((e:any) => {
          if (e.approved != "Confirmed") {
            return e
          }
        })
        if (count.length > 0) {
          this.requestCount = count.length
        }
        // this.startTimer();
        // this.spinner.hide();
      }, (err: any) => {
        // this.spinner.hide();
      });
  }

  goToNextDay(): void {
    this.selectedDate = new Date(this.selectedDate.getTime() + 86400000); // Add one day in milliseconds
    const dateObject = new Date(this.selectedDate);

    // Convert the Date object to ISO string
    const isoString = dateObject.toISOString();
    this.apiService.getUnApprovedReservedTableswithDate(isoString)
      .subscribe((result: any) => {
        this.UnApprovedReservedTablesArr = result
        const count = this.UnApprovedReservedTablesArr.filter((e:any) => {
          if (e.approved != "Confirmed") {
            return e
          }
        })
        if (count.length > 0) {
          this.requestCount = count.length
        }
        // this.startTimer();
        // this.spinner.hide();
      }, (err: any) => {
        // this.spinner.hide();
      });
  }
  onDateChange(): void {

    const dateObject = new Date(this.selectedDate);

    // Convert the Date object to ISO string
    const isoString = dateObject.toISOString();
    this.apiService.getUnApprovedReservedTableswithDate(isoString)
      .subscribe((result: any) => {
        this.UnApprovedReservedTablesArr = result
        const count = this.UnApprovedReservedTablesArr.filter((e:any) => {
          if (e.approved != "Confirmed") {
            return e
          }
        })
        if (count.length > 0) {
          this.requestCount = count.length
        }
        // this.startTimer();
        // this.spinner.hide();
      }, (err: any) => {
        // this.spinner.hide();
      });
  }


  // dataSource = [
  //   { display: 'Item 1', date: new Date(), time: '12:00 PM', customerName: 'John Doe', seat: 'A1', bookings: 3 },
  //   { display: 'Item 2', date: new Date(), time: '3:30 PM', customerName: 'Jane Smith', seat: 'B2', bookings: 2 },
  //   // Add more data as needed
  // ];

  // Define the displayed columns
 

  // Function to handle edit button click
  editRow(i: any): void {
    // Add your edit logic here
    this.dialog.open(BookingFormComponent, {
      width: '500px',
      data: i
    })
      .afterClosed().subscribe(result => {
        if (result)
          if (result.status == 'OK') {
            this.load();
          }
      })
  }
}
