import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataService } from '../data.service';

import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


import { environment } from '../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { DBbackupComponent } from '../db-backup/db-backup.component';

import { Notyf } from 'notyf';

const notyf = new Notyf({
  position: {
    x: 'right',
    y: 'top',
  }
});
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { CompanyManagementComponent } from '../company-management/company-management.component';
import { SharedModule } from '../common.module';



@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.css'],
  standalone: true,
  imports: [
    SharedModule
  ]
})
export class RedirectComponent {
 
  params1:any;
  params2:any;
 
  constructor(private breakpointObserver: BreakpointObserver, private route: ActivatedRoute, 
    private apiService: DataService, public dialog: MatDialog, private router: Router) {
    
  }
  ngOnInit() {
   
    this.redirecturl()
   
   
    
  }
  

 
  
  redirecturl(){

    this.route.queryParams.subscribe(params => {
        this.params1 = params['code'];
        this.params2 =params['state']
    }); 
      const observable= new Observable(observer=>{
          observer.next(this.params2);
      });
      observable.subscribe( value=>window.open(this.params2+"/dashboard?code="+this.params1, '_blank', 'toolbar=0,width=400,height=600,left=500,top=50'));
       
      
     
  }
}
