import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DataService } from "../../../data.service";

import { MatDialogModule } from "@angular/material/dialog";
import { MatSortModule } from "@angular/material/sort";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatIconModule } from "@angular/material/icon";

import { MatButtonModule } from "@angular/material/button";
import { MatTableModule } from "@angular/material/table";
import { SharedModule } from "../../../common.module";
import { MasterService } from "../../../_services/master.service";
import { Notyf } from "notyf";

const notyf = new Notyf({
  position: {
    x: "right",
    y: "top",
  },
});
@Component({
  selector: "app-add-edit-items",
  templateUrl: "./add-edit-items.component.html",
  styleUrls: ["./add-edit-items.component.css"],
  standalone: true,
  imports: [SharedModule],
})
export class AddEditItemsComponent implements OnInit {
  model: any = {};
  item_id: any;
  itemDetails: any = {};
  status: any;

  constructor(
    public dialogRef: MatDialogRef<AddEditItemsComponent>,
    private apiService: MasterService,

    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.status = data.status;
    this.model.priceList_id = parseInt(data.priceList_id);
    this.model.item_id = data.item_id;
    this.model.s_rate = data.s_rate;
    this.model.taxslab = data.taxslab;
  }

  ngOnInit() {
    this.load();
    this.item_id = this.data.item_id; // Access item_id from the data passed
  }

  // loadItemDetails() {
  //   //Call your data service method to fetch item details by item_id
  //   this.apiService.getItemDetails(this.item_id).subscribe((response: any) => {
  //     this.itemDetails = response;
  //     // Optionally, you can handle errors here
  //   });
  // }

  onClose() {
    this.dialogRef.close();
  }

  load() {}

  onSubmit() {
    if (this.status == "add") {
      // Here you can perform actions like sending data to a server
      this.apiService.additemPriceList(this.model).subscribe(
        (response: any) => {
          if (response.success) {
            this.model = {};
            notyf.success(response.message);
          } else {
            notyf.error(response.message);
          }
        },
        (err) => {
          notyf.error(err);
        }
      );
    } else {
      // Here you can perform actions like sending data to a server
      console.log("--thismodeeeeeeeeee", this.model.priceList_id, this.model);
      this.apiService
        .updateItemPriceList(this.model.priceList_id, this.model)
        .subscribe(
          (response: any) => {
            if (response.success) {
              this.model = {};
              notyf.success(response.message);
            } else {
              notyf.error(response.message);
            }
          },
          (err) => {
            notyf.error(err);
          }
        );
    }
    this.dialogRef.close({ status: this.status });
  }
}
