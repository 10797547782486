<div class="table-details">
  <h1 mat-dialog-title>{{ data.title }}</h1>
  <label for="exampleFormControlFile1">Preparation Time</label>
  <div class="input-group mb-3">
    <input type="text" id="exampleFormControlFile1" class="form-control" placeholder="Preparation time in minutes"
      [(ngModel)]="preparationTime" aria-label="Recipient's username" aria-describedby="basic-addon2">
    <div class="input-group-append">
      <span class="input-group-text" id="basic-addon2">mins</span>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="onConfirm(data)">OK</button>
  </div>
</div>