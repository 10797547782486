<div class="card-container">
  <!-- Header Section -->
  <div class="header-container">
    <div class="header1">
      <p>Total Sales</p>
    </div>
    <div class="header2">
      <p class="background">
        <!-- Dropdown for date ranges, populated dynamically via @Input -->
        <select
          (change)="onDateRangeChange($event)"
          class="date-range-dropdown"
        >
          <option *ngFor="let range of dateRanges" [value]="range.value">
            {{ range.label }}
          </option>
        </select>
      </p>
      <!-- <p class="refresh">
        <button class="refresh-btn" (click)="refreshData()">
          <i class="fa fa-refresh"></i>
        </button>
      </p> -->
    </div>
  </div>

  <!-- Total Sales Section -->
  <div class="total-section">
    <p class="total-label">Total:</p>
    <p class="total-amount">
      <i class="fa fa-{{ currency }}"></i> {{ totalSales | number : "1.2-2" }}
    </p>
  </div>

  <!-- Payment Methods Section, populated dynamically via @Input -->
  <div class="payment-methods">
    <div class="payment-method" *ngFor="let payment of paymentMethods">
      <div class="payment-label">{{ payment.label }}</div>
      <div class="stat-item-view"></div>
      <div class="payment-amount">
        <i class="fa fa-{{ currency }}"></i
        >{{ payment.value | number : "1.2-2" }}
      </div>
    </div>
  </div>
</div>
