import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CacheServiceService } from "./cache-service.service";
import { Observable, of } from "rxjs";
import { tap } from "rxjs/operators";

const TIME_TO_LIVE = 86400
@Injectable()
export class CacheInterceptor implements HttpInterceptor {
    constructor(private cacheResolver: CacheServiceService) { }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (req.method !== "GET") {
            return next.handle(req)
        }
        const cachedResponse = this.cacheResolver.get(req.url);
        
        return cachedResponse ? of(cachedResponse) : this.sendRequest(req, next);
    }

    sendRequest(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap((event)=>{
                if(event instanceof HttpResponse){
                    this.cacheResolver.set(req.url , event , TIME_TO_LIVE);
                }
            })
        )
    }
    

}