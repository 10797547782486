import { Component, Inject, HostListener, ElementRef, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-table-change-dialog',
  templateUrl: './table-change-dialog.component.html',
  styleUrls: ['./table-change-dialog.component.css'],
  standalone: true,
  imports: [
    MatDialogModule,
    CommonModule,
    FormsModule,
    MatButtonModule
  ]
})
export class TableChangeDialogComponent {
  tables: any[] = [];
  filteredTables: any[] = [];
  filterText: string = '';
  selectedIndex: number = -1;

  @ViewChild('dropdownList', { static: false }) dropdownList!: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<TableChangeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.tables = data.tables;
    this.filteredTables = this.tables;
  }

  filterTables(): void {
    const filterTextLower = this.filterText.toLowerCase();
    this.filteredTables = this.tables.filter(table =>
      table.name.toLowerCase().includes(filterTextLower)
    );
    this.selectedIndex = this.filteredTables.length === 1 ? 0 : -1;
    this.scrollToSelected();
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'ArrowDown' && this.filteredTables.length > 1) {
      this.selectedIndex = (this.selectedIndex + 1) % this.filteredTables.length;
      this.scrollToSelected();
      event.preventDefault();
    } else if (event.key === 'ArrowUp' && this.filteredTables.length > 1) {
      this.selectedIndex = (this.selectedIndex - 1 + this.filteredTables.length) % this.filteredTables.length;
      this.scrollToSelected();
      event.preventDefault();
    } else if (event.key === 'Enter' && this.selectedIndex >= 0) {
      this.onSelectTable(this.filteredTables[this.selectedIndex]);
      event.preventDefault();
    } else if (event.key === 'Enter' && this.filteredTables.length === 1) {
      this.onSelectTable(this.filteredTables[0]);
      event.preventDefault();
    }
  }

  scrollToSelected(): void {
    if (this.dropdownList && this.selectedIndex >= 0) {
      const dropdownElement = this.dropdownList.nativeElement;
      const selectedItem = dropdownElement.children[this.selectedIndex];
      if (selectedItem) {
        selectedItem.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
      }
    }
  }

  onSelectTable(table: any): void {
    this.dialogRef.close(table);
  }

  onCancel(): void {
    this.dialogRef.close(null);
  }
}
