import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CacheServiceService {

  constructor() { }

  private cache = new Map<string, [Date, HttpResponse<any>]>();

  set(key: string, value: any, timeToLive:number | null = null): void {
    // this.cache.set(key, value);
    if(timeToLive){
      const expiresIn = new Date();
      expiresIn.setSeconds(expiresIn.getSeconds() + timeToLive);
    }else{
      const expiry = new Date(); // or some valid Date value
      expiry.setHours(expiry.getHours() + 15);
      this.cache.set(key, [expiry, value]);
    }
  }


  get(key: string): any {
    // return this.cache.get(key);
    const tuple = this.cache.get(key);

    if(!tuple) return null; 
    
    // Extract tupil 
    const expiresIn = tuple[0];
    const httpSavedResponce = tuple[1];
    const now = new Date();

    if(expiresIn && expiresIn.getTime() < now.getTime()){
      this.cache.delete(key);
      return null;
    }
    return httpSavedResponce;
  }

 

  clear(): void {
    this.cache.clear();
  }
}


