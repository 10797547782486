<div class="main-container">
  <div class="card-container">
    <div class="w-100 not-head text-center">
      <p class="mt-2" style="font-size: medium; font-weight: bold">
        Total Reservation Request
      </p>
      <span class="mx-5" style="font-weight: bold"
        >Total Request: {{ requestCount }}</span
      ><br />
      <span class="mx-5" style="font-weight: bold"
        >Total Unapproved: {{ unApprovedRequestCount }}</span
      ><br />
    </div>

    <div class="card-actions text-center">
      <button
        class="btn approve-btn"
        style="font-weight: bold"
        (click)="tableReservationDashboard()"
      >
        Reservation Dashboard
      </button>
    </div>
  </div>
</div>
