<div class="dialog-title" mat-dialog-title>
  <h3 mat-dialog-title class="color-h3">
    <span>Edit pricelist Variant</span>
  </h3>
</div>
<div class="table-details">
  <mat-dialog-content>
    <div class="row">
      <div class="col-md-12">
        <mat-form-field class="contract-full-width">
          <input
            matInput
            type="number"
            placeholder="Variant ID"
            [(ngModel)]="model.variant_id"
            name="id"
            required
          />
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <mat-form-field class="contract-full-width">
          <input
            matInput
            type="number"
            placeholder="Sale Rate"
            [(ngModel)]="model.s_rate"
            name="sale_rate"
          />
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-raised-button class="btn" type="button" (click)="onClose()">
      Close
    </button>

    <button
      mat-raised-button
      class="btn-primary"
      type="button"
      (click)="updatevar()"
    >
      Update
    </button>
  </mat-dialog-actions>
</div>
