<div class="panelcard">
  <form #f="ngForm" [formGroup]="form">
    <h3 class="color-h3">
      Create Invoice
      <span
        ><mat-icon class="close-icon" style="float: right" (click)="onClose()"
          >close</mat-icon
        ></span
      >
    </h3>
    <div
      class="table-details"
      style="
        height: 450px;
        max-height: 450px;
        white-space: nowrap;
        overflow-x: auto;
        overflow-y: auto;
      "
    >
      <div class="row tablecontainer" style="margin: 1px">
        <table class="table item-tbl">
          <thead>
            <tr>
              <th>#</th>
              <th class="itm-name">Item Name</th>
              <th class="text-center">Qty</th>
              <!-- <th class="text-center">Rate</th> -->
              <th class="right">Amount</th>
              <th></th>
            </tr>
          </thead>
          <tbody class="itm-tbody">
            <tr *ngFor="let item of itemsArr; let i = index">
              <td data-title="Sl" *ngIf="item.addons_parent_id === null">
                {{ i + 1 }}
              </td>
              <td
                data-title="Name"
                class="table-font-arial itm-name"
                *ngIf="item.addons_parent_id === null"
              >
                {{ item.item_name }}
              </td>
              <td
                data-title="Qty"
                class="table-font-arial"
                *ngIf="item.addons_parent_id === null"
              >
                <input
                  type="number"
                  class="form-control form-control-xs text-center"
                  value="{{ convertFloat(item.qty) }}"
                  (keyup)="onChangeItemQty($event, i)"
                />
              </td>
              <td
                data-title="Amount"
                class="table-font-arial right"
                *ngIf="item.addons_parent_id === null"
              >
                {{ item.amount | number : "1.2-2" }}
              </td>
              <td
                data-title="Actions"
                class="action-itm"
                *ngIf="item.addons_parent_id === null"
              >
                <button
                  type="button"
                  class="btn-sm del-btn btn"
                  (click)="onDeleteItem(i)"
                >
                  <mat-icon color="warn">delete_outline</mat-icon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <div class="input-group mb-1 input-group-sm">
          <div class="input-group-prepend">
            <span class="input-group-text"
              >Amount &nbsp;<i class="fa fa-{{ currency }}"></i
            ></span>
          </div>
          <input
            type="number"
            class="form-control"
            formControlName="total_amount"
            readonly
          />
        </div>
        <div class="input-group mb-1 input-group-sm">
          <div class="input-group-prepend">
            <div class="input-group-text">Discount %</div>
          </div>
          <input
            type="number"
            formControlName="dis_per"
            class="form-control"
            (input)="onDiscountPer($event)"
            (focus)="onDiscountFocus(true)"
            (blur)="onDiscountFocus(false)"
            min="0"
          />
          <div class="input-group-prepend">
            <div class="input-group-text">
              <i class="fa fa-{{ currency }}"></i>
            </div>
          </div>
          <input
            type="number"
            formControlName="discount_amt"
            class="form-control"
            (input)="onDiscountAmt($event)"
            readonly
          />
        </div>

        <div
          *ngIf="form.value.total_tax"
          class="input-group mb-1 input-group-sm"
        >
          <div class="input-group-prepend">
            <div class="input-group-text">
              Tax &nbsp;
              <i class="fa fa-{{ currency }}"></i>
            </div>
          </div>
          <input
            type="number"
            formControlName="total_tax"
            class="form-control"
            readonly
          />
        </div>
        <div class="input-group mb-1 input-group-sm">
          <div class="input-group-prepend">
            <div class="input-group-text">Other1</div>
          </div>
          <input formControlName="other1_label" class="form-control" />
          <div class="input-group-prepend">
            <div class="input-group-text">
              <i class="fa fa-{{ currency }}"></i>
            </div>
          </div>
          <input
            type="number"
            formControlName="other1_amt"
            class="form-control"
            (input)="onOtherPrice()"
            min="0"
          />
        </div>
        <div class="input-group mb-1 input-group-sm">
          <div class="input-group-prepend">
            <div class="input-group-text">Other2</div>
          </div>
          <input formControlName="other2_label" class="form-control" />
          <div class="input-group-prepend">
            <div class="input-group-text">
              <i class="fa fa-{{ currency }}"></i>
            </div>
          </div>
          <input
            type="number"
            formControlName="other2_amt"
            class="form-control"
            (input)="onOtherPrice()"
            min="0"
          />
        </div>
        <!-- service charge -->

        <div class="input-group mb-1 input-group-sm" *ngIf="isServiceCharge">
          <div class="input-group-prepend">
            <div class="input-group-text">Service Charge %</div>
          </div>
          <input
            type="number"
            formControlName="service_charge_per"
            class="form-control"
            min="0"
            readonly
          />
          <div class="input-group-prepend">
            <div class="input-group-text">
              <i class="fa fa-{{ currency }}"></i>
            </div>
          </div>
          <input
            type="number"
            formControlName="service_charge"
            class="form-control"
            readonly
          />
        </div>

        <!-- service charge end-->
        <div class="input-group mb-1 input-group-sm">
          <div class="input-group-prepend">
            <div class="input-group-text">
              Total &nbsp;
              <i class="fa fa-{{ currency }}"></i>
            </div>
          </div>
          <input
            type="number"
            formControlName="gross_amount"
            class="form-control"
            readonly
          />
        </div>
        <div class="input-group mb-1 input-group-sm">
          <div class="input-group-prepend">
            <div class="input-group-text">
              RoundOff &nbsp;
              <i class="fa fa-{{ currency }}"></i>
            </div>
          </div>
          <input
            type="number"
            formControlName="roundoff_amt"
            class="form-control"
            readonly
          />
        </div>
        <table class="table table-borderless net-amt-table">
          <td>
            <label class="big form-bold-label">Net Amount:</label>
          </td>
          <td>
            <label class="big form-bold-label">
              <i class="fa fa-{{ currency }}"></i
              >{{ form.value.net_amount }}</label
            >
          </td>
        </table>
      </div>
      <!-- <div class="col-md-10"> -->
      <!-- First row for No cost checkbox and OTP input -->
      <!-- <ng-container *ngIf="itemsArr.length > 0" class="col-md-12">
          <div class="row mt-3">
            <div class="input-group-text col-auto d-flex align-items-center" style="width: auto; height: 30px">
              <label for="checkbox2" style="margin-right: 8px">No cost</label>
              <input type="checkbox" id="checkbox2" style="transform: scale(1.5); margin-left: 8px"
                (change)="onCheckboxChange()" />
            </div>
            <div *ngIf="showValidationInput" class="col-auto mt-1" style="padding-left: 0px; width: 100px"
              [formGroup]="validationForm" (ngSubmit)="onSubmit()">
              <div *ngIf="!formSubmitted" style="width: 100%">
                <input formControlName="validationCode" class="form-control" placeholder="OTP"
                  style="width: 100%; height: 30px" />
              </div>
            </div>
            <div class="col d-flex justify-content-end align-items-center">

            </div>
          </div>
        </ng-container> -->
      <!-- </div> -->
    </div>
    <!-- Container for actions -->
    <mat-dialog-actions class="dialog-actions">
      <button
        class="btn mat-raised-button"
        (click)="SaveAndPrint()"
        *ngIf="itemsArr.length > 0 && waitforinvoice === false"
        [disabled]="showValidationInput && !validationForm.valid"
      >
        Save & Print
      </button>
      <button
        style="margin-left: 10px"
        class="btn mat-raised-button"
        (click)="onSubmit()"
        *ngIf="waitforinvoice === false"
        [disabled]="showValidationInput && !validationForm.valid"
      >
        Save
      </button>
      <button
        style
        class="btn mat-raised-button"
        disabled
        *ngIf="waitforinvoice === true"
      >
        <span class="spinner-border spinner-border-sm" role="status"></span>
        wait...
      </button>
      <!-- <button class="btn mat-raised-button" (click)="onClose()">Close</button> -->
    </mat-dialog-actions>
  </form>
</div>
