import { CommonModule } from "@angular/common";
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  HostListener,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { IconComponent } from "../icons/icons.component";
import { DropdownMenuCardComponent } from "../dropdown-menu-card/dropdown-menu-card.component";
import { FormsModule } from "@angular/forms"; // Import FormsModule for two-way data binding
import { Router } from "@angular/router"; // Import Router

interface MenuItem {
  menu_id: number;
  id: number;
  role_id: number;
  menu_name: string;
  key: string;
  menu_group_id: number;
  route: string;
  icon: string;
  title: string;
  menu_sq: number;
  active: boolean;
  is_system: boolean;
}

interface MenuGroup {
  menu_group_id: number;
  menu_group_name: string;
  menu_group_icon: string;
  menu_group_sq: number;
  menuItem: MenuItem[];
}

@Component({
  selector: "app-top-header",
  standalone: true,
  imports: [
    CommonModule,
    IconComponent,
    DropdownMenuCardComponent,
    FormsModule,
  ], // Add FormsModule here
  templateUrl: "./top-header.component.html",
  styleUrls: ["./top-header.component.css"],
})
export class TopHeaderComponent implements OnInit {
  @Input() welcomeMessage: string = "Welcome";
  @Input() profileName: string = "Pankaj Verma";
  @Input() iconName: string = "";
  @Input() iconColor: string = "black";
  @Input() iconSize: number = 24;
  @Input() backgroundColor: string = "#f8f8fa";
  @Input() userFullName: string = "";
  @Input() userRole: string = "";
  @Input() company_name: string = "";
  @Input() isConnected: boolean = false;
  @Input() notificationCount: number = 1;

  @Input() roleId: number = 0;
  @Input() allowMulticompany: boolean = false;

  @Input() menuGroupObj: MenuGroup[] = [];

  // searchTerm: string = ""; // For capturing the search input
  // filteredData: MenuGroup[] = []; // Filtered data for search suggestions
  // dataNotFound: boolean = false;

  searchTerm: string = "";
  dataNotFound: boolean = false;
  filteredData: any[] = [];
  activeIndex: number = -1;
  isScrolling: boolean = false;

  @Output() itemClickedFromDropdown: EventEmitter<string> =
    new EventEmitter<string>();
  @Output() iconClicked = new EventEmitter<void>();

  menuItems = [
    {
      label: "Company Management",
      icon: "Home",
      actionName: "onCompanyManage",
    },
    { label: "Change Password", icon: "Edit", actionName: "changePass" },
    { label: "Logout", icon: "Logout", actionName: "logout" },
    { label: "Backup DB", icon: "Upload", actionName: "onBackupClick" },
  ];

  constructor(private router: Router) {}

  ngOnInit(): void {}

  @ViewChild("suggestionsContainer", { static: false })
  suggestionsContainer!: ElementRef<HTMLUListElement>;

  onSearchChange(event: Event) {
    const input = event.target as HTMLInputElement;
    this.searchTerm = input.value.trim().toLowerCase();
    this.dataNotFound = false;
    this.activeIndex = -1; // Reset active index when search term changes

    if (this.searchTerm) {
      // Filter menuGroupObj based on matching items
      this.filteredData = this.menuGroupObj
        .map((menuGroup: any) => {
          // Filter menuItems within each group that match the search term
          const matchedItems = menuGroup.menuItem.filter((item: any) =>
            item.menu_name.toLowerCase().includes(this.searchTerm)
          );
          // Return a new object if there are matched items
          return matchedItems.length > 0
            ? { ...menuGroup, menuItem: matchedItems }
            : null;
        })
        .filter((menuGroup: any) => menuGroup !== null); // Remove nulls where no items matched

      // Check if no matches were found
      if (this.filteredData.length === 0) {
        this.dataNotFound = true;
      }
    } else {
      this.filteredData = [];
    }
  }

  onKeyDown(event: KeyboardEvent) {
    if (this.isScrolling) {
      return; // Don't move the active index when scrolling
    }

    const itemCount = this.filteredData.reduce(
      (acc, group) => acc + group.menuItem.length,
      0
    );

    if (event.key === "ArrowDown") {
      // Move the active index down
      if (this.activeIndex < itemCount - 1) {
        this.activeIndex++;
      } else {
        // If it's the last item, move to the first item
        this.activeIndex = 0;
      }
      this.scrollToActiveItem();
    } else if (event.key === "ArrowUp") {
      // Move the active index up
      if (this.activeIndex > 0) {
        this.activeIndex--;
      } else {
        // If it's the first item, move to the last item
        this.activeIndex = itemCount - 1;
      }
      this.scrollToActiveItem();
    } else if (event.key === "Enter") {
      // Select the item when Enter is pressed
      if (this.activeIndex !== -1) {
        const item = this.getSelectedItem();
        if (item) {
          this.onSelectItem(item);
        }
      }
    }
  }

  // Helper function to scroll the active item into view
  scrollToActiveItem() {
    if (this.suggestionsContainer && this.activeIndex !== -1) {
      const activeItem =
        this.suggestionsContainer.nativeElement.querySelector(".active");

      if (activeItem) {
        activeItem.scrollIntoView({
          behavior: "smooth",
          block: "center", // Adjust block value as needed for scroll alignment
        });
      }
    }
  }

  onScroll(event: Event) {
    this.isScrolling = true;
    setTimeout(() => {
      this.isScrolling = false;
    }, 100); // Reset after 100ms
  }

  // Helper method to get the global index across all groups
  getGlobalIndex(groupIndex: number, itemIndex: number): number {
    let index = 0;
    for (let i = 0; i < groupIndex; i++) {
      index += this.filteredData[i].menuItem.length;
    }
    return index + itemIndex;
  }

  // Get the selected item based on active index
  getSelectedItem() {
    const flatItems = this.filteredData.flatMap((group) => group.menuItem);
    return flatItems[this.activeIndex] || null;
  }

  // Handle item selection
  onSelectItem(item: any) {
    this.router.navigate([item.route]);
    this.searchTerm = ""; // Clear the search term after selection
    this.filteredData = []; // Clear the suggestions
    this.activeIndex = -1; // Reset the active index
  }

  @HostListener("document:click", ["$event"])
  onClickOutside(event: Event) {
    const target = event.target as HTMLElement | null;
    if (!target?.closest(".search-suggestions")) {
      this.resetSearch();
    }
  }

  // Reset the search field and suggestions
  resetSearch() {
    this.searchTerm = "";
    this.filteredData = [];
    this.activeIndex = -1;
  }

  onDropdownItemClicked(actionName: string) {
    this.itemClickedFromDropdown.emit(actionName);
  }

  onrightToolbarMenuToggle() {
    this.iconClicked.emit();
  }

  getBackgroundColor(groupName: string): string {
    switch (groupName) {
      case "Dashboard":
        return "#D0EFFF"; // Light Sky Blue
      case "Masters":
        return "#E6FFDE"; // Light Mint Green
      case "Transactions":
        return "#F3E5F5"; // Light Lavender
      case "Reports":
        return "#FFE5B4"; // Light Peach
      case "Setting":
        return "#F0F0F0"; // Light Grey
      default:
        return "transparent"; // Default background
    }
  }

  getFilteredMenuItems(): any[] {
    return this.menuItems.filter((item) => {
      if (item.actionName === "onCompanyManage") {
        return this.roleId === 1 || this.allowMulticompany;
      }
      return true;
    });
  }
}
