import { CommonModule } from "@angular/common";
import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { StoriesModuleModule } from "../stories-module.module";

@Component({
  selector: "app-dashboard-cards",
  standalone: true,
  imports: [FormsModule, CommonModule],
  templateUrl: "./dashboard-card.component.html",
  styleUrls: ["./dashboard-card.component.css"],
})
export class DashboardCardsComponent implements OnInit {
  @Input() status: string = "inprogress";
  @Input() label: string = "Order Status";
  @Input() size: string = "medium";

  @Input() text: string = "Dashboard"; // Default button text
  @Input() backgroundColor: string = "#007bff"; // Default background color (Bootstrap primary)
  @Input() textColor: string = "#ffffff"; // Default text color
  @Input() fontSize: number = 16; // Default font size (in pixels)
  @Input() padding: string = "10px 20px"; // Default padding

  @Input() currency: string = "usd";

  @Input() tableArr: any;
  @Input() tableGroup: any;
  @Input() id: number = 43; // Added ID from JSON
  @Input() groupId: number = 1; // Added group_id from JSON
  @Input() name: string = "T 1"; // Added name from JSON
  @Input() seqNo: number = 1; // Added seq_no from JSON
  @Input() active: boolean = true; // Added active from JSON
  @Input() capacity: number | null = null; // Added capacity from JSON
  @Input() groupName: string = "1 st Floor"; // Added group_name from JSON
  @Input() kotCount: string = "0"; // Added kot_count from JSON
  @Input() kotAmount: number = 0; // Added kot_amount from JSON
  @Input() displayTransNo: string = "INV24-2500768"; // Added display_trans_no from JSON
  @Input() invAmt: number = 181; // Added inv_amt from JSON
  @Input() invCount: string = "1"; // Added inv_count from JSON
  @Input() webactive: boolean = false; // Added webactive from JSON
  @Input() waiter: boolean = false; // Added waiter from JSON
  @Input() water: boolean = false; // Added water from JSON
  @Input() pricelistId: number | null = null; // Added pricelist_id from JSON

  @Output() tableClick = new EventEmitter<any>();
  @Output() openInv = new EventEmitter<any>();
  @Output() openKot = new EventEmitter<any>();
  @Output() receipt = new EventEmitter<any>();

  // Timer value formatted
  // get timer() {
  //   return new Date(0, 0, 0, 0, 0, this.timerValue).toISOString().substr(14, 5); // Format in mm:ss
  // }

  constructor() {}

  ngOnInit() {}

  gotoInvoice(table: any) {
    this.tableClick.emit(table);
  }
  openInvoiceModal(table: any): void {
    this.openInv.emit(table);
  }

  openKOTModal(table: any): void {
    this.openKot.emit(table);
  }

  receiptDialog(table: any): void {
    this.receipt.emit(table);
  }
}
