<div class="dialog-title" mat-dialog-title>
  <h3 class="color-h3">
    Receipt
    <span
      ><mat-icon class="close-icon" style="float: right" (click)="onCancel()"
        >close</mat-icon
      ></span
    >
  </h3>
</div>

<div style="margin: 20px">
  <div>
    <span style="font-size: larger; font-weight: 500">Customer Name :</span>
    {{ data?.customer_name }}
  </div>
  <div class="row">
    <div class="col-6">
      <span style="font-size: larger; font-weight: 500">Inv No :</span>
      {{ data?.display_trans_no }}
    </div>
    <div class="col-6">
      <span style="font-size: larger; font-weight: 500">Date :</span>
      {{ formattedDate }}
    </div>
  </div>
  <form #f="ngForm" [formGroup]="form" (ngSubmit)="onReceipt()">
    <div class="input-group mb-1 input-group-sm">
      <div class="input-group-prepend">
        <span class="input-group-text"
          >Cash Receive &nbsp;<i class="fa fa-{{ currency }}"></i
        ></span>
      </div>
      <input
        type="number"
        placeholder="0"
        class="form-control"
        min="0"
        formControlName="cash_receive"
        (keyup)="onCashReceive()"
      />
    </div>
    <div class="input-group mb-1 input-group-sm">
      <div class="input-group-prepend">
        <span class="input-group-text"
          >Cash &nbsp;<i class="fa fa-{{ currency }}"></i
        ></span>
      </div>
      <input
        id="cash"
        type="number"
        placeholder="0"
        class="form-control"
        min="0"
        formControlName="cash"
        (keyup)="onCashOrCard()"
      />
    </div>
    <div class="input-group mb-1 input-group-sm">
      <div class="input-group-prepend">
        <span class="input-group-text"
          >Card &nbsp;<i class="fa fa-{{ currency }}"></i
        ></span>
      </div>
      <input
        id="card"
        type="number"
        class="form-control"
        placeholder="0"
        min="0"
        formControlName="card"
        (keyup)="onCashOrCard()"
      />
    </div>
    <div class="input-group mb-1 input-group-sm">
      <div class="input-group-prepend">
        <span class="input-group-text"
          >UPI &nbsp;<i class="fa fa-{{ currency }}"></i
        ></span>
      </div>
      <input
        id="upi"
        type="number"
        class="form-control"
        placeholder="0"
        min="0"
        formControlName="upi"
        (keyup)="onCashOrCard()"
      />
    </div>
    <div class="input-group mb-1 input-group-sm">
      <div class="input-group-prepend">
        <span class="input-group-text">Card/Upi Ref.No</span>
      </div>
      <input type="text" class="form-control" formControlName="card_ref_no" />
    </div>
    <div class="input-group mb-1 input-group-sm">
      <div class="input-group-prepend">
        <span class="input-group-text"
          >Total &nbsp;<i class="fa fa-{{ currency }}"></i
        ></span>
      </div>
      <input
        type="number"
        class="form-control"
        placeholder="0"
        formControlName="advance"
        readonly
      />
    </div>
    <div class="input-group mb-1 input-group-sm">
      <div class="input-group-prepend">
        <span class="input-group-text"
          >Balance &nbsp;<i class="fa fa-{{ currency }}"></i
        ></span>
      </div>
      <input
        type="number"
        class="form-control"
        formControlName="net_amount"
        readonly
      />
    </div>
    <div class="input-group mb-1 input-group-sm">
      <div class="input-group-prepend">
        <span class="input-group-text"
          >Refund &nbsp;<i class="fa fa-{{ currency }}"></i
        ></span>
      </div>
      <input
        type="number"
        class="form-control"
        value="{{ refund_amt }}"
        readonly
      />
    </div>
    <div>
      <button
        class="btn-primary"
        #btnGrp
        type="button"
        style="float: right; margin-bottom: 20px; margin-top: 10px"
        disabled
        *ngIf="wait === true"
      >
        <span class="spinner-border spinner-border-sm" role="status"></span>
        wait...
      </button>
      <button
        type="submit"
        #btnGrp
        class="btn-primary"
        style="float: right; margin-bottom: 20px; margin-top: 10px"
        *ngIf="wait === false"
      >
        Receipt
      </button>
      <button
        type="button"
        #btnGrp
        class="btn"
        style="
          float: right;
          margin-bottom: 20px;
          margin-top: 10px;
          margin-right: 5px;
        "
        mat-dialog-close
      >
        Cancel
      </button>
    </div>
  </form>
</div>
