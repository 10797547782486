<div class="main-loader loader" *ngIf="loading">
  <div class="cssload-container">
    <div class="cssload-whirlpool"></div>
  </div>
</div>

<router-outlet [ngClass]="theme"></router-outlet>
<notifier-container [ngClass]="theme"></notifier-container>
<ngx-spinner
  [ngClass]="theme"
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Please wait...</p>
</ngx-spinner>
