<div class="table-details">
  <h1 mat-dialog-title>{{ data.title }}</h1>
  <hr>
  <img style="max-width: 52px; border-radius: 12px;" *ngIf="order_mode.toLowerCase() === 'swiggy' || order_mode.toLowerCase() === 'zomato'"
    src="assets/images/{{order_mode}}.png" alt="" class="pull-right" />
  <h3 style="font-size: smaller;">#KOT : {{trans_no}}</h3>

  <div *ngIf="data.title==='Approve'">
    <label for="exampleFormControlFile1">Preparation Time</label>
    <div class="input-group mb-3" style="margin-top: 10px;">
      <input type="text" id="exampleFormControlFile1" class="form-control" placeholder="Preparation time in minutes"
        [(ngModel)]="preparationTime" aria-label="Recipient's username" aria-describedby="basic-addon2">
      <div class="input-group-append">
        <span class="input-group-text" id="basic-addon2" style="height: 35px;">mins</span>
      </div>
    </div>
  </div>
  <div *ngIf="data.title==='Reject'">
    <label for="exampleFormControlFile1">Please Select Reject Reason</label>
    <select class="form-select form-select-lg mb-3" aria-label=".form-select-lg example" [(ngModel)]="rejectionMessage"
      *ngIf="order_mode.toLowerCase() === 'zomato'" style="margin-top: 10px;">
      <option selected>Open this select menu</option>
      <option *ngFor="let message of rejectionMessages" [value]="message.key">
        {{message.message}}</option>
    </select>
    <select class="form-select form-select-lg mb-3" aria-label=".form-select-lg example" [(ngModel)]="rejectionMessage"
      *ngIf="order_mode.toLowerCase() === 'swiggy'" style="max-width: 210px; margin-top: 10px;">
      <option selected>Open this select menu</option>
      <option *ngFor="let message of swiggyRejectionMessages" [value]="message.message">
        {{message.message}}</option>
    </select>
  </div>
  <div mat-dialog-actions class="d-flex justify-content-end">
    <button style="margin-left: 8px;" mat-raised-button type="button" class="mfullwidth1"
      (click)="onCancel()">Close</button>
    <button mat-raised-button color="primary" type="button" class="mfullwidth1" (click)="onConfirm(data)"
      [disabled]="!isDataSelected()">OK</button>
  </div>
</div>