import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { MasterService } from "../../../../../_services/master.service";
import { Notyf } from 'notyf';
import { SharedModule } from "../../../../../common.module";

const notyf = new Notyf({
  position: {
    x: 'right',
    y: 'top',
  }
});
@Component({
  selector: "app-edit-item-variant",
  templateUrl: "./edit-item-variant.component.html",
  styleUrls: ["./edit-item-variant.component.css"],
  standalone: true,
  imports: [
    SharedModule
  ]
})
export class EditItemVariantComponent implements OnInit {
  model: any = {};
  id: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EditItemVariantComponent>,
    private apiService: MasterService
  ) {
    this.model.variant_id = data.variant_id;
    this.model.s_rate = data.s_rate;
    this.id = data.id;
  }

  ngOnInit() {
    this.load();
  }

  onClose() {
    this.dialogRef.close();
  }

  load() { }

  updatevar() {
    this.apiService.updatepricelistvariant(this.id, this.model).subscribe(
      (response: any) => {
        if (response.success) {
          notyf.success(response.message);
          // Pass the updated model back to the parent component
          this.dialogRef.close(this.model);
        } else {
          notyf.error(response.message);
        }
      },
      (err) => {
        notyf.error(err);
      }
    );
  }
}
