import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

import { Notyf } from 'notyf';

const notyf = new Notyf({
  position: {
    x: 'right',
    y: 'top',
  }
});
import { NgxSpinnerService } from 'ngx-spinner';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';


import { MatDialogModule } from "@angular/material/dialog";
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from "../../common.module";
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { RouterModule } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-add-edit-tenent',
  templateUrl: './add-edit-tenent.component.html',
  styleUrls: ['./add-edit-tenent.component.css'],
  standalone: true,
  imports: [
    SharedModule
  ]
})
export class AddEditTenentComponent implements OnInit {
  @ViewChild('f') f: any;
  StateControl = new FormControl();
  constructor(private dialogRef: MatDialogRef<AddEditTenentComponent>,
    private apiService: DataService,
    
    private spinner: NgxSpinnerService,

    @Inject(MAT_DIALOG_DATA) public data: any) { }
  filteredOptions: Observable<any[]> = new Observable<any[]>();

  model: any = {};
  statelist: any = [
    { value: 1, name: "Jammu & Kashmir" },
    { value: 2, name: "Himachal Pradesh" },
    { value: 3, name: "Punjab" },
    { value: 4, name: "Chandigarh" },
    { value: 5, name: "Uttarakhand" },
    { value: 6, name: "Haryana" },
    { value: 7, name: "Delhi" },
    { value: 8, name: "Rajasthan" },
    { value: 9, name: "Uttar Pradesh" },
    { value: 10, name: "Bihar" },
    { value: 11, name: "Sikkim" },
    { value: 12, name: "Arunachal Pradesh" },
    { value: 13, name: "Nagaland" },
    { value: 14, name: "Manipur" },
    { value: 15, name: "Mizoram" },
    { value: 16, name: "Tripura" },
    { value: 17, name: "Meghalaya" },
    { value: 18, name: "Assam" },
    { value: 19, name: "West Bengal" },
    { value: 20, name: "Jharkhand" },
    { value: 21, name: "Orissa" },
    { value: 22, name: "Chhattisgarh" },
    { value: 23, name: "Madhya Pradesh" },
    { value: 24, name: "Gujarat" },
    { value: 25, name: "Daman & Diu" },
    { value: 26, name: "Dadra & Nagar Haveli" },
    { value: 27, name: "Maharashtra" },
    { value: 28, name: "Andhra Pradesh" },
    { value: 29, name: "Karnataka" },
    { value: 30, name: "Goa" },
    { value: 31, name: "Lakshadweep" },
    { value: 32, name: "Kerala" },
    { value: 33, name: "Tamil Nadu" },
    { value: 34, name: "Puducherry" },
    { value: 35, name: "Andaman & Nicobar Islands" },
    { value: 36, name: "Telengana" },
    { value: 37, name: "Andrapradesh(New)" }
  ];



  ngOnInit() {
    this.filteredOptions = this.StateControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filter(name) : this.statelist.slice())
      );

    // this.f.submitted = true;
    this.load();
  }

  displayState(state?: any): string | undefined {
    return state ? state.name : undefined;
  }

  private _filter(name: string): any {
    const filterValue = name.toLowerCase();

    return this.statelist.filter((option: any) => option.name.toLowerCase().indexOf(filterValue) === 0);
  }



  StateSelection(event: MatAutocompleteSelectedEvent) {
    if (event.option.value) {
      this.model.address_state = event.option.value ? event.option.value.value : undefined;

    }
  }


  load() {
    this.statelist.sort(function (a: any, b: any) {
      return a.name.localeCompare(b.name);
    })

    if (this.data) {
      this.apiService.getTenent(this.data.tenent_id)
        .subscribe((result: any) => {
          this.model = result[0];
          var indexofState = this.statelist.findIndex((i: any) => i.value === result[0].address_state);
          this.StateControl.setValue(this.statelist[indexofState]);

        }, (result: any) => {
          notyf.error( 'unable to load data');
        });
    }
  }


  onSubmit() {
    if (this.data) {
      this.spinner.show();
      this.apiService.editTenent(this.model.tenent_id, this.model)
        .subscribe((result: any) => {
          this.dialogRef.close('Edited');
          this.spinner.hide();
          notyf.success( 'Tenent updated successfully');
        }, (result: any) => {
          this.spinner.hide();
          notyf.error( 'unable to update data');
        });
    } else {
      this.spinner.show();
      this.apiService.addTenent(this.model)
        .subscribe((result: any) => {
          this.dialogRef.close('Added');
          this.spinner.hide();
          notyf.success( 'Tenent Setup created successfully');
        }, (result: any) => {
          this.spinner.hide();
          notyf.error( 'unable to add new Tenent');
        });
    }

  }

  close() {
    this.dialogRef.close();
  }
}
