// import { CommonModule } from "@angular/common";
// import { Component, Input, Output, EventEmitter } from "@angular/core";
// import { ItemCardComponent } from "../item-card/item-card.component";
// import { SharedModule } from "../../../app/common.module";

// @Component({
//   selector: "app-sub-category-card",
//   standalone: true,
//   imports: [CommonModule, SharedModule],
//   templateUrl: `./sub-category-card.component.html`,
//   styleUrls: ["./sub-category-card.component.css"],
// })
// export class SubCategoryCardComponent {
//   // Centralized array for images and titles
//   @Input() subCategories: any[] = [];

//   selectedIndex: number | null = null;
//   selectedBorderColor: string = "#ff0022";
//   selectedBackgroundColor: string = "#f8f8fa";

//   // New inputs for title customization
//   @Input() titleSize: string = "14px"; // Default size
//   @Input() titleWeight: string = "500"; // Default weight
//   @Input() titleColor: string = "#04030f"; // Default color
//   @Input() showPrice: boolean = false;

//   @Output() cardSelected = new EventEmitter<any>();

//   ngOnInit() {
//     console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$", this.subCategories);
//   }

//   get titleStyle() {
//     return {
//       fontSize: this.titleSize,
//       fontWeight: this.titleWeight,
//       color: this.titleColor,
//     };
//   }

//   // Method to select a card
//   showItems(index: number) {
//     this.selectedIndex = index;
//     const selectedSubCategory = this.subCategories[index]; // Get the selected sub-category
//     this.cardSelected.emit(selectedSubCategory);
//   }
// }

import { CommonModule } from "@angular/common";
import { Component, Input, Output, EventEmitter } from "@angular/core";
import { SharedModule } from "../../../app/common.module";

@Component({
  selector: "app-sub-category-card",
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: `./sub-category-card.component.html`,
  styleUrls: ["./sub-category-card.component.css"],
})
export class SubCategoryCardComponent {
  @Input() subCategories: any[] = [];
  @Input() items_data: any[] = [];
  @Input() titleSize: string = "14px";
  @Input() titleWeight: string = "500";
  @Input() titleColor: string = "#04030f";
  @Input() showPrice: boolean = false;
  @Input() imgurl!: string;
  @Input() nullImageURL: string = "30.jpeg";
  @Input() selectedCategoryName!: string;

  @Input() selected: boolean = false;
  @Input() selectedBorderColor: string = "#ff0022";
  @Input() selectedBackgroundColor: string = "#f8f8fa";

  @Output() cardSelected = new EventEmitter<any>();
  @Output() addItem = new EventEmitter<any>();
  @Input() currency: string = "usd";

  selectedIndex: number | null = null;
  selectedSubCategoryIndex: number | null = null;
  selectedItemIndex: number | null = null;

  // Method to handle image error by setting a fallback image
  handleError(event: Event) {
    const target = event.target as HTMLImageElement;
    target.src = "/assets/images/NoImage.png"; // Fallback image path
  }

  // Method to handle when an item is clicked (add card)
  addItemCardClicked(item: any, index: number) {
    this.selectedItemIndex = index;

    this.addItem.emit(item);
  }

  // Style for the title customization
  get titleStyle() {
    return {
      fontSize: this.titleSize,
      fontWeight: this.titleWeight,
      color: this.titleColor,
    };
  }

  // Method to handle sub-category selection
  showItems(index: number) {
    this.selectedIndex = index;
    const selectedSubCategory = this.subCategories[index];
    this.cardSelected.emit(selectedSubCategory);
  }
}
