import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material/dialog";
import { Notyf } from 'notyf';

const notyf = new Notyf({
  position: {
    x: 'right',
    y: 'top',
  }
});

import { DataService } from '../../data.service';
// import { RolesComponent } from '../../../../backup/roles/roles.component';

import { MatDialogModule } from "@angular/material/dialog";
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
// import { ConfirmationDialogComponent } from '../../../../backup/rst-dashboard/booking-form/confirmation-dialog/confirmation-dialog.component';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { MatDatepickerModule } from '@angular/material/datepicker';

@Component({
  selector: 'app-change-user-pass',
  templateUrl: './change-user-pass.component.html',
  styleUrls: ['./change-user-pass.component.css'],
  standalone: true,
  imports: [
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatDatepickerModule,
    RouterModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    MatCardModule,
    MatTabsModule
  ]
})
export class ChangeUserPassComponent implements OnInit {
  @ViewChild('f') f:any;
  constructor(private dialogRef: MatDialogRef<ChangeUserPassComponent>,
    private apiService: DataService,
    // 
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
  user_id: any;
  model: any = { old_password: "", password: "", confirm_password: "" };
  roles: any;
  ngOnInit() {
    this.load()
  }
  load() {
    this.user_id = localStorage.getItem('user_id');
  }

  onSubmit() {

    if (this.model.password != this.model.confirm_password) {
      // notyf.error( 'Confirm password not Matched');
    } else {
      if (this.data == "User") {
        // this.apiService.changeUserPass(this.user_id, this.model)
        //   .subscribe((result: any) => {
        //     if (result.success) {
        //       this.dialogRef.close('Edited');
        //       notyf.success( 'Password updated successfully');
        //     } else {
        //       notyf.error( result.message);
        //     }

        //   }, (result: any) => {
        //     notyf.error( result.message);
        //   });
      }
      if (this.data == "Tenent") {
        // this.apiService.changeTenentPass(this.user_id, this.model)
        //   .subscribe((result: any) => {
        //     if (result.success) {
        //       this.dialogRef.close('Edited');
        //       notyf.success( 'Password updated successfully');
        //     } else {
        //       notyf.error( result.message);
        //     }

        //   }, (result: any) => {
        //     notyf.error( result.message);
        //   });
      }


    }

  }


  close() {
    this.dialogRef.close();
  }

}
