import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CookieService } from "ngx-cookie-service";
import { Observable } from "rxjs";
import * as models from "../data.modal";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class OwnerDashboardService {
  basePath: any = "https://rst.intellibooks.io";
  cookieValue: any = "";
  constructor(private http: HttpClient, private cookieService: CookieService) {
    this.basePath = environment.basePath;
  }

  getHeader(): HttpHeaders {
    let headers = new HttpHeaders();
    this.cookieValue = this.cookieService.get("SESSIONID");
    headers = headers.set("Accept", "application/json");
    headers = headers.set("Content-Type", "application/json");
    // headers = headers.set("Authorization", "bearer " + this.cookieValue);
    if (this.cookieService.get("refresh_token") && this.cookieValue) {
      headers = headers.set("Authorization", "Bearer " + this.cookieValue);
    }
    return headers;
  }
  getOwnerDashboardData(data: any): Observable<any> {
    let headers = this.getHeader();
    let date = data;

    return this.http.get<any>(`${this.basePath}/api/ownerdashboard/${date}`, {
      headers,
    });
  }

  getOrderStatistic(data: any): Observable<any> {
    let headers = this.getHeader();
    let date = data;
    return this.http.get<any>(
      `${this.basePath}/api/ownerdashboard/order-statistic/${date}`,
      { headers }
    );
  }
  getTotalSaleDashboard(data: any): Observable<any> {
    let headers = this.getHeader();
    let date = data;
    return this.http.get<any>(
      `${this.basePath}/api/ownerdashboard/total-sale/${date}`,
      { headers }
    );
  }
}
