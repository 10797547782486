import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { MatDialogModule } from "@angular/material/dialog";
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { MatDatepickerModule } from '@angular/material/datepicker';

// import { ConfirmationDialogComponent } from '../../../../backup/rst-dashboard/booking-form/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-auto-accept-dialoge',
  templateUrl: './auto-accept-dialoge.component.html',
  styleUrls: ['./auto-accept-dialoge.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatDatepickerModule,
    RouterModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    MatCardModule,
    MatTabsModule

  ]
})
export class AutoAcceptDialogeComponent implements OnInit {
  preparationTime: any

  constructor(
    // public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.preparationTime = data.preparationTime

  }

  ngOnInit() {
  }


  onConfirm(data: any): void {
    const approveData = {
      preparationTime: this.preparationTime
    }
    const message = approveData
    // this.dialogRef.close(message);
  }
}
