import { CommonModule } from "@angular/common";
import {
  Component,
  Input,
  EventEmitter,
  Output,
  HostListener,
  ChangeDetectorRef,
} from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { IconComponent } from "../icons/icons.component";

// Define the MenuItem interface
export interface MenuGroup {
  name: string;
  icon: string;
  open?: boolean;
  menu_group_icon: string;
  menu_group_name: string;
  menuItem?: MenuItem[]; // Optional submenus
}

// Define the MenuItem interface
export interface MenuItem {
  menu_id: number;
  menu_group_id: number;
  menu_name: string;
  route: string;
  icon: string;
  menu_sq: number;
}

@Component({
  selector: "app-sidebar",
  standalone: true,
  imports: [CommonModule, IconComponent],
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent {
  @Input() logo: string = "Logo";
  @Input() logoFontSize: number = 24;
  @Input() textColor: string = "#000";

  // New inputs for dynamic theming
  @Input() iconPanelBackgroundColor: string = "#f0f0f0";
  @Input() iconPanelTextColor: string = "#000";
  @Input() sidebarBackgroundColor: string = "#fff";
  @Input() sidebarTextColor: string = "#000";
  @Input() menuFontSize: number = 18;
  @Input() menuFontStyle: string = "normal";
  @Input() submenuFontSize: number = 15;
  @Input() menuItemTextColor: string = "#000";
  @Input() submenuTextColor: string = "#000";
  @Input() searchTextColor: string = "#000";
  @Input() searchBorderColor: string = "#ddd";
  @Input() selectedIconBackgroundColor: string = "#e0e0e0";
  @Input() menuGroups!: any[];
  @Input() iconName: string = ""; // Default to an empty string
  @Input() iconColor: string = "black"; // Default color
  @Input() iconSize: number = 24; // Default size
  isSidebarCollapsed = true;
  @Output() sidebarToggle = new EventEmitter<boolean>();
  @Input() helpDeskUrl!: string;

  activeIcon: string | null = null;
  activeStaticItem: string | null = null;
  selectedMenu: string = ""; // Initialize as an empty string or a default value
  // menuGroups: any[] = [];
  menuItems: any[] = [];

  @HostListener("window:resize", ["$event"])
  onResize(event: any) {
    if (event.target.innerWidth < 768) {
      this.isSidebarCollapsed = true;
    }
  }

  ngOnInit() {
    const data = {
      menu: [
        {
          menu_group_id: 2,
          menu_group_name: "Transactions",
          menu_group_icon:
            "M240-80q-50 0-85-35t-35-85v-120h120v-560l60 60 60-60 60 60 60-60 60 60 60-60 60 60 60-60v680q0 50-35 85t-85 35H240Zm480-80q17 0 28.5-11.5T760-200v-560H320v440h360v120q0 17 11.5 28.5T720-160ZM360-600v-80h240v80H360Zm0 120v-80h240v80H360Zm320-120q-17 0-28.5-11.5T640-640q0-17 11.5-28.5T680-680q17 0 28.5 11.5T720-640q0 17-11.5 28.5T680-600Zm0 120q-17 0-28.5-11.5T640-520q0-17 11.5-28.5T680-560q17 0 28.5 11.5T720-520q0 17-11.5 28.5T680-480ZM240-160h360v-80H200v40q0 17 11.5 28.5T240-160Zm-40 0v-80 80Z",
          menu_group_sq: 1,
          open: true,
        },
        {
          menu_group_id: 3,
          menu_group_name: "Reports",
          menu_group_icon:
            "M480-280q17 0 28.5-11.5T520-320q0-17-11.5-28.5T480-360q-17 0-28.5 11.5T440-320q0 17 11.5 28.5T480-280Zm-40-160h80v-240h-80v240ZM330-120 120-330v-300l210-210h300l210 210v300L630-120H330Zm34-80h232l164-164v-232L596-760H364L200-596v232l164 164Zm116-280Z",
          menu_group_sq: 2,
          open: true,
        },
        {
          menu_group_id: 1,
          menu_group_name: "Masters",
          open: true,
          menu_group_icon:
            "m354-287 126-76 126 77-33-144 111-96-146-13-58-136-58 135-146 13 111 97-33 143ZM233-120l65-281L80-590l288-25 112-265 112 265 288 25-65 281-93 80 31 139L480-120l93-80Z",
          menuItem: [
            {
              menu_id: 1,
              menu_group_id: 1,
              menu_name: "Item Master",
              route: "/item-master",
              icon: "",
              menu_sq: 1,
            },
            {
              menu_id: 2,
              menu_group_id: 1,
              menu_name: "Customer Master",
              route: "/customer-master",
              icon: "",
              menu_sq: 2,
            },
          ],
          menu_group_sq: 1,
        },
      ],
    };
    this.menuGroups = data.menu
      .filter((item) => item.open)
      .map((item) => ({
        menu_group_name: item.menu_group_name,
        menu_group_icon: item.menu_group_icon,
        open: item.open,
        menuItem: item.menuItem || [],
      }));
  }

  constructor(
    private sanitizer: DomSanitizer,
    private router: Router,
    private cd: ChangeDetectorRef
  ) {}

  setActiveIcon(group: MenuGroup) {
    this.activeIcon = group.menu_group_icon;
  }

  // Check if the icon is currently active
  isActiveIcon(group: MenuGroup): boolean {
    return this.activeIcon === group.menu_group_icon;
  }

  toggleDropdown(group: any): void {
    // Close all other menu groups except the clicked one
    this.menuGroups.forEach((menuGroup) => {
      if (menuGroup !== group) {
        menuGroup.open = false;
      }
    });

    // Toggle the clicked group
    group.open = !group.open;
  }

  isStaticActive(menuName: string): boolean {
    return this.activeStaticItem === menuName;
  }

  toggleSidebar(event?: Event, alwaysOpen: boolean = false): void {
    // Only stop event propagation if an event is passed
    if (event) {
      event.stopPropagation();
    }

    // Collapse or expand the sidebar based on alwaysOpen
    if (alwaysOpen) {
      this.isSidebarCollapsed = false; // Keep sidebar open if alwaysOpen is true
    } else {
      this.isSidebarCollapsed = !this.isSidebarCollapsed;
    }

    // Emit the sidebar toggle state
    this.sidebarToggle.emit(this.isSidebarCollapsed);
  }

  getIcon(iconName: string): SafeHtml {
    const svgIcons: { [key: string]: string } = {
      chevron: `<svg xmlns="http://www.w3.org/2000/svg" height="25px" viewBox="0 -960 960 960" width="25px" fill="black"><path d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z" /></svg>`,
    };

    return this.sanitizer.bypassSecurityTrustHtml(
      svgIcons[iconName] || `<span>Icon not found</span>`
    );
  }

  navigateTo(route: string): void {
    // Collapse sidebar on small devices (mobile view)
    if (window.innerWidth < 768) {
      this.isSidebarCollapsed = true;
      this.sidebarToggle.emit(this.isSidebarCollapsed);
    }

    // Navigate to the selected route
    this.selectedMenu = route;
    this.router.navigate([route]);
  }
}
