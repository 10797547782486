import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from "@angular/forms";
// 
import { Notyf } from 'notyf';

const notyf = new Notyf({
  position: {
    x: 'right',
    y: 'top',
  }
});

import { DatePipe } from '@angular/common';


import { MatDialogModule } from "@angular/material/dialog";
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { DataService } from '../../data.service';
import { SharedModule } from '../../common.module';
import { RstDashboardService } from '../../_services/rst-dashboard.service';

@Component({
  selector: 'app-tabletransfer-dialog',
  templateUrl: './tabletransfer-dialog.component.html',
  styleUrls: ['./tabletransfer-dialog.component.css'],
  standalone: true,
  imports: [
    SharedModule
  ]
})
export class TabletransferDialogComponent implements OnInit {
  selectedTable: any;
  tableDetails: any
  table_id: any
  data: any = {};
  owntable: any
  trans_type: any = {};
  model: any = { trans_data: [], trans_type: [], time: [], date: [] };
  constructor(private dialogRef: MatDialogRef<TabletransferDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public details: any = [], 
    // 
     private apiService: RstDashboardService,
      // private datePipe: DatePipe
    ) {
  }
  ngOnInit() {
    this.load()
  }
  load() {
    // -----------------------getTableListWithKOT-----
    this.apiService.getTableListWithKOT()
      .subscribe((result: any) => {
        this.tableDetails = result.filter((table: any) => table.id !== this.details && table.kot_count == 0);
        // this.tableDetails = result.filter((table: any) => table.kot_count === 0);
        this.owntable = result.filter((table: any) => table.id == this.details);
      }, (result: any) => {
        // notyf.error( 'unable to load data');
      });
  }
  // ---------------------transfer table -------------------
  processTransfer() {
    if (!this.data.selectedTable) {
      return;
    }
    const data = {
      table_id_1: this.details,
      table_id_2: this.data.selectedTable
    };
    this.apiService.transferTable(data).subscribe(
      (result: any) => {
        if (result.success == true) {
          // notyf.success( 'Table Transfer successfully');
          this.data.selectedTable = null;
          this.dialogRef.close('true');
          this.printClick(data);
        } else {
          // notyf.error( 'Table Transfer failed');
          this.dialogRef.close();
        }
      },
      (error: any) => {
        console.log('table transfer error occurred:', error);
      }
    );
  }
  printClick(data: any) {
    this.model.trans_type = "transfer table";
    this.model.trans_data = data
    const currentTime = new Date();
    const currentDateString = currentTime.toLocaleDateString();
    const currentTimeString = currentTime.toLocaleTimeString();
    this.model.time = currentTimeString;
    this.model.date = currentDateString;
    this.apiService.downloadPdf(this.model)
      .subscribe((result: any) => {
        let blob = new Blob([result], {
          type: 'application/pdf'
        });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'transfer_table' + new Date().toLocaleDateString() + '.pdf';
        link.click();
        window.URL.revokeObjectURL(link.href);
      }, (result: any) => {
      });
  }
}




