import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Notyf } from 'notyf';

const notyf = new Notyf({
  position: {
    x: 'right',
    y: 'top',
  }
});

import { DataService } from '../data.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { AddEditTenentComponent } from './add-edit-tenent/add-edit-tenent.component';

import { MatDialogModule } from "@angular/material/dialog";
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
// import { SharedModule } from "../../common.module";
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { SharedModule } from '../common.module';

@Component({
  selector: 'app-tenent-management',
  templateUrl: './tenent-management.component.html',
  styleUrls: ['./tenent-management.component.css'],
  standalone: true,
  imports: [
    SharedModule
  ]
})
export class TenentManagementComponent implements OnInit {
  tenentObj: MatTableDataSource<any> = new MatTableDataSource<any>();
  constructor(private apiService: DataService, public dialog: MatDialog, ) {

  }
  displayedColumns = ['tenent_code', 'tenent_name', 'db_name', 'active', 'is_trial', 'allow_multicompany', 'actions'];
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  ngOnInit() {
    this.load();
  }

  load() {
    this.apiService.getTenentList()
      .subscribe((result: any) => {
        this.tenentObj = new MatTableDataSource(result);
        this.tenentObj.sort = this.sort;
        this.tenentObj.paginator = this.paginator;
      }, (result: any) => {
        notyf.error( 'unable to load data');
      });
  }

  deleteClick(row:any) {
    this.dialog.open(ConfirmDialogComponent, {
      data: "Are you sure to Delete Selected Tenent Details.?"
    })
      .afterClosed().subscribe(result => {
        if (result == true) {
          this.apiService.deleteTenent(row.tenent_id)
            .subscribe((result: any) => {
              if (!result.success)
                notyf.error( result.message);
              else
               notyf.success( 'tenent deleted successfully');
              this.apiService.getTenentList()
                .subscribe((result: any) => {
                  this.tenentObj = new MatTableDataSource(result);
                  this.tenentObj.sort = this.sort;
                  this.tenentObj.paginator = this.paginator;
                }, (result: any) => {
                });
            }, (result: any) => {
              notyf.error( 'unable to delete data');
            });
        }
      });
  }
  addClick() {
    this.dialog.open(AddEditTenentComponent, {
      width: '650px',
    })
      .afterClosed().subscribe(result => {
        if (result == 'Added') {
          this.apiService.getTenentList()
            .subscribe((result: any) => {
              this.tenentObj = new MatTableDataSource(result);
              this.tenentObj.sort = this.sort;
              this.tenentObj.paginator = this.paginator;
            }, (result: any) => {
            });
        }
      });

  }
  editClick(row:any) {
    this.dialog.open(AddEditTenentComponent, {
      width: '650px',
      data: row
    })
      .afterClosed().subscribe(result => {
        if (result == 'Edited') {
          this.apiService.getTenentList()
            .subscribe((result: any) => {
              this.tenentObj = new MatTableDataSource(result);
              this.tenentObj.sort = this.sort;
              this.tenentObj.paginator = this.paginator;
            }, (result: any) => {
            });
        }
      });
  }

  updateIsTrial(event:any, data:any) {
    data.is_trial = event
    this.apiService.editTenent(data.tenent_id, data)
      .subscribe((result: any) => {
        notyf.success( 'Tenent Updated Successfully');
      }, (result: any) => {
        notyf.error( 'Unable To Update Data');
      });
  }
  updateAllowMultiCompany(event:any, data:any) {
    data.allow_multicompany = event
    this.apiService.editTenent(data.tenent_id, data)
      .subscribe((result: any) => {
        notyf.success( 'Tenent Updated Successfully');
      }, (result: any) => {
        notyf.error( 'Unable To Update Data');
      });

  }

}

