import { CommonModule } from "@angular/common";
import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-item-card",
  standalone: true,
  imports: [CommonModule],
  templateUrl: `./item-card.component.html`,
  styleUrls: ["./item-card.component.css"],
})
export class ItemCardComponent {
  @Input() categoryData: any;
  @Input() imgurl!: string;
  @Input() categoryName!: string;
  @Input() selected: boolean = false;
  @Input() selectedBorderColor: string = "#ff0022";
  @Input() selectedBackgroundColor: string = "#f8f8fa";
  @Input() titleSize: string = "14px";
  @Input() titleWeight: string = "bold"; // Default font weight
  @Input() titleColor: string = "#04030f";
  @Input() price: number = 0;
  @Input() priceSize: string = "12px";
  @Input() priceWeight: string = "400";
  @Input() priceColor: string = "#000";
  @Input() nullImageURL: string =
    "https://image.intellibooks.io/Images/uploads/category/rst_14_15/null";
  @Input() showPrice: boolean = true;

  @Output() cardSelected = new EventEmitter<boolean>();

  showFullName: boolean = false; // State to control tooltip visibility

  get titleStyle() {
    return {
      fontSize: this.titleSize,
      fontWeight: this.titleWeight, // Apply the dynamic font weight
      color: this.titleColor,
    };
  }

  get priceStyle() {
    return {
      fontSize: this.priceSize,
      fontWeight: this.priceWeight,
      color: this.priceColor,
    };
  }

  showitems() {
    this.cardSelected.emit(this.categoryData);
  }
}
