import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { environment} from '../../environments/environment';
import { SharedModule } from '../common.module';
import { TransactionsService } from '../_services/transactions.service';


@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    SharedModule
  ],
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  mapArr: any; 
  showRest:boolean = false;
  constructor(private apiService: TransactionsService) { }

  ngOnInit() {
    this.load();
  }
  load() { 
      this.apiService.getAccountMapList()
        .subscribe((result: any) => {
          this.mapArr = result;
          var indexofCurrency=this.mapArr.findIndex((i:any)=>i.key === "Currency");
            if(this.mapArr[indexofCurrency].text_value){
              environment.currency=this.mapArr[indexofCurrency].text_value;
              localStorage.setItem('currency', this.mapArr[indexofCurrency].text_value);
            }
      
          var ResturantKey = this.mapArr.filter((key:any)=> key.key == 'Enable_Restaurant_POS');
          

          if(ResturantKey[0].checkbox_value == true){
            this.showRest = true
          }else{
            this.showRest = false
          }
        }, (result: any) => {
        });


    var width = window.innerWidth;
    if (width <= 768) {
    } else if (width > 768 && width <= 992) {
    } else {
    }
  }
 
}
