<h2 mat-dialog-title class="color-h3">Company Management</h2>
<div class="table-details">
  <mat-dialog-content>
    <div>
      <button mat-icon-button class="mat-primary" (click)="addClick()">
        <mat-icon>add_circle</mat-icon>
      </button>
      <table mat-table [dataSource]="companyArr">
        <ng-container matColumnDef="company_name">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let row" data-label="Name">{{row.company_name}}
            <span *ngIf="row.active" class="badge badge-success"> Active </span>
            <span *ngIf="!row.active" class="badge badge-danger"> Deactive </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row" data-label="Actions">
            <button mat-icon-button (click)="editClick(row)"><mat-icon>edit</mat-icon></button>
            <button mat-icon-button color="warn" (click)="deleteClick(row)"><mat-icon>delete_outline</mat-icon></button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button type="button" class="btn mat-raised-button mat-primary" (click)="close()">Close</button>
  </mat-dialog-actions>
</div>