<div class="dropdown-container">
  <div class="dropdown">
    <div style="display: flex; align-items: center">
      <span *ngIf="isLabel" class="company-name" style="font-size: 12px">{{
        companyName
      }}</span>
      <!-- <button  class="dropdown-toggles"> -->
      <div
        class="icon-container"
        [ngStyle]="{
            backgroundColor: isCircular ? '#fff' : 'transparent',
            borderRadius: isCircular ? '50%' : 'none',
          }"
        (click)="toggleDropdown()"
      >
        <app-icon
          [iconName]="'Person'"
          [iconColor]="'#000'"
          [iconSize]="35"
        ></app-icon>
      </div>
      <!-- </button> -->
    </div>

    <div class="dropdown-menu-container">
      <div class="dropdown-menu" *ngIf="isOpen">
        <!-- Company Name -->
        <div class="company-name">
          {{ companyName }}
        </div>

        <!-- User Role Info -->
        <div class="user-info">
          <strong>{{ title }}</strong> ({{ role }})
        </div>

        <!-- Divider Line -->
        <div class="dropdown-divider"></div>

        <!-- Dropdown Items -->
        <ul class="dropdown-items">
          <li
            *ngFor="let item of menuItems"
            (click)="onItemClick(item.actionName)"
          >
            <app-icon
              [iconName]="item.icon"
              [iconColor]="iconColor"
              [iconSize]="24"
            ></app-icon>
            {{ item.label }}
          </li>
        </ul>
      </div>
    </div>
    <div
      class="notification-circle"
      [ngStyle]="{ 'background-color': isConnected ? 'green' : 'red' }"
    ></div>
  </div>
</div>
