<div class="container">
  <div
    style="
      border-bottom: none;
      height: 60px; /* Increased height for better spacing */
      display: flex;
      align-items: center;
      padding: 0 10px; /* Add padding for better spacing */
    "
  >
    <div
      class="btn"
      style="margin-right: 10px; display: flex; align-items: center"
      (click)="navigateTo('/rst-dashboard')"
    >
      <mat-icon style="font-size: 25px">keyboard_backspace</mat-icon>
    </div>

    <span
      style="
        font-weight: 700;
        display: inline-block;
        margin-top: 4px;
        flex-grow: 1;
      "
      class="label"
    >
      Table: {{ table_name }} ({{ tableCapacity }})
    </span>

    <span style="display: flex; align-items: center">
      <section
        class="form-section mr-1"
        style="height: 30px"
        *ngIf="!tableObj?.length && webappActiveTable"
      >
        <button class="btn" (click)="clearTable()">
          <mat-icon style="font-size: 23px; margin-right: 5px">clear</mat-icon>
          <span class="label">Clear Session</span>
        </button>
      </section>
      <section class="form-section mr-1" style="height: 30px">
        <button
          *ngIf="water || waiter"
          class="btn"
          (click)="clearwaterwaiter()"
        >
          <mat-icon style="font-size: 23px; margin-right: 5px"
            >local_drink</mat-icon
          >
          <span class="label">Waiter/water</span>
        </button>
      </section>
      <section class="form-section mr-1" style="height: 30px">
        <button class="btn" (click)="onAllowLogin()">
          <mat-icon style="font-size: 25px; margin-right: 5px">{{
            allow_login ? "check_circle" : "toggle_off"
          }}</mat-icon>
          <span class="label"
            >{{ allow_login ? "Allowed" : "Allow" }} Multi Login</span
          >
        </button>
      </section>
      <section class="form-section mr-1" style="height: 30px">
        <button class="btn" (click)="redirectToKeyboard()">
          <mat-icon style="font-size: 23px; margin-right: 5px"
            >keyboard</mat-icon
          >
          <span class="label">Go to keyboard Mode</span>
        </button>
      </section>

      <!-- Transfer Table Button with Condition -->
      <section class="form-section mr-1" style="height: 30px">
        <button
          *ngIf="tableObj?.kot?.length !== 0"
          class="btn"
          (click)="openTransferDialog()"
        >
          <mat-icon style="font-size: 23px; margin-right: 5px"
            >transfer_within_a_station</mat-icon
          >
          <span class="label">Transfer Table</span>
        </button>
      </section>
    </span>
  </div>
</div>
