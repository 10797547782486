<!-- <div class="container-fluid h-100">
        <div class="row h-100">
            <div class="col-md-12 h-100">
                <div class="card w-100 h-100">
                    <div class="card-body"> -->
<div class="table-details" style="margin-top: 72px">
  <div class="row d-flex" style="width: 100%; margin: 0">
    <div
      class="col-sm-12 col-md-12 col-lg-12 col-xl-7 card-container"
      style="
        padding: 0px;
        margin: 0px;
        background-color: #f0f0f0;
        border: 1px solid #ddd;
      "
    >
      <div class="header-container">
        <div class="header1">
          <p>Dashboard</p>
        </div>
        <div class="header2">
          <p class="background">
            <select
              (change)="onDateRangeChange($event)"
              class="date-range-dropdown"
            >
              <option *ngFor="let range of dateRanges" [value]="range.value">
                {{ range.label }}
              </option>
            </select>
          </p>
        </div>
      </div>
      <div class="card-display">
        <div *ngFor="let card of cards">
          <app-card-components
            [showCurrency]="card.showCurrency"
            [currencyType]="card.currencyType"
            [descriptionFontSize]="card.descriptionFontSize"
            [descriptionFontWeight]="'normal'"
            [iconName]="card.iconName"
            [iconColor]="card.iconColor"
            [iconSize]="24"
            [amount]="card.total_amount"
            [description]="card.description"
            [showIcon]="card.showIcon"
            [currency]="currency"
          ></app-card-components>
        </div>
        <div *ngIf="cards.length == 0">
          <h1>No Record</h1>
        </div>
      </div>
    </div>
    <div
      class="col-sm-12 col-md-12 col-lg-12 col-xl-4 stats-card-container"
      style="width: 100%"
    >
      <div class="">
        <app-stats-card
          (dateRangeChange)="handleDateRangeChangeOrderDashboard($event)"
          [stats]="{
            successOrder: modelData.success_invoice,
            cancelledOrder: modelData.cancelled_invoice,
            complimentaryOrder: modelData.success_kot,
            tableTurnAroundTime: modelData.cancelled_kot
          }"
        ></app-stats-card>
      </div>
      <div style="margin-top: 18px">
        <div>
          <app-total-sales
            (dateRangeChangeSale)="handleDateRangeChangeSaleDashboard($event)"
            [date]="''"
            [totalSales]="saleModelData.net_amount"
            [paymentMethods]="[
              { label: 'Cash', value: saleModelData.cash },
              { label: 'Card', value: saleModelData.card },
              { label: 'UPI', value: saleModelData.upi },
              { label: 'Paid', value: saleModelData.paid },
              { label: 'Due', value: saleModelData.due },
              { label: 'Discount', value: saleModelData.discount_amt }
            ]"
            [dateRanges]="[
              { label: 'Today', value: 'Today' },
              { label: 'Yesterday', value: 'Yesterday' },
              { label: 'Weekly', value: 'Weekly' },
              { label: 'Monthly', value: 'Monthly' }
            ]"
            [currency]="currency"
          ></app-total-sales>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- 
Online HTML, CSS and JavaScript editor to run code online.
-->

<!-- <!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8" />
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <title>Bootstrap Full Height and Width Card</title>
</head>

<body>
 <div class="table-detail">
    <div class='dashboard-container'>
      <div class="header-container">
        <div class="header1">
          <p>Dashboard</p>
        </div>
        <div class="header2">
            <p class="background">
                <select
                  (change)="onDateRangeChange($event)"
                  class="date-range-dropdown"
                >
                  <option
                    *ngFor="let range of dateRanges"
                    [value]="range.value"
                  >
                    {{ range.label }}
                  </option>
                </select>
              </p>
        </div>
      </div>
      
      <div class="card-display">
        <div *ngFor="let card of cards">
            <app-card-components
              [showCurrency]="card.showCurrency"
              [currencyType]="card.currencyType"
              [descriptionFontSize]="card.descriptionFontSize"
              [descriptionFontWeight]="'normal'"
              [iconName]="card.iconName"
              [iconColor]="card.iconColor"
              [iconSize]="24"
              [amount]="card.total_amount"
              [description]="card.description"
              [showIcon]="card.showIcon"
            ></app-card-components>
          </div>
          <div *ngIf="cards.length == 0">
            <h1>No Record</h1>
          </div>
      </div>
    </div>

    <div class="stats-container">
      <div>
        <app-stats-card
              (dateRangeChange)="handleDateRangeChangeOrderDashboard($event)"
              [stats]="{
                successOrder: modelData.success_invoice,
                cancelledOrder: modelData.cancelled_invoice,
                complimentaryOrder: modelData.success_kot,
                tableTurnAroundTime: modelData.cancelled_kot
              }"
            ></app-stats-card>
      </div>
      <div>
        <app-total-sales
                (dateRangeChangeSale)="
                  handleDateRangeChangeSaleDashboard($event)
                "
                [date]="''"
                [totalSales]="15000"
                [paymentMethods]="[
                  { label: 'Cash', value: saleModelData.total_cash },
                  { label: 'Card', value: saleModelData.total_card },
                  { label: 'Online Paid', value: saleModelData.total_upi }
                ]"
                [dateRanges]="[
                  { label: 'Today', value: 'Today' },
                  { label: 'Yesterday', value: 'Yesterday' },
                  { label: 'Weekly', value: 'Weekly' },
                  { label: 'Monthly', value: 'Monthly' }
                ]"
              ></app-total-sales>
      </div>
    </div>
  </div>
</body>
</html> -->
