import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpResponse,
} from "@angular/common/http";
import { Router } from "@angular/router";
import { Notyf } from "notyf";
import { CookieService } from "ngx-cookie-service";
import { from, Observable, Subject, throwError } from "rxjs";
import { catchError, tap, take, switchMap, retryWhen } from "rxjs/operators";
import { DataService } from "./data.service";
import { environment } from "../environments/environment";
import { DeviceDetectorService } from "ngx-device-detector";
const notyf = new Notyf({
  position: {
    x: "right",
    y: "top",
  },
});

@Injectable({
  providedIn: "root", // Assuming your NotifierService is provided in root
})
export class AuthInterceptor implements HttpInterceptor {
  private tokenSubscriptionTrigger = new Subject<void>();
  private tokenSubscriptionExecuted = false;
  private basePath: any = "https://rst.intellibooks.io";
  deviceType: any = null;
  constructor(
    private router: Router,
    private cookieService: CookieService,
    private dataService: DataService,
    private deviceService: DeviceDetectorService // private notifierService: NotifierService // Inject NotifierService
  ) {
    this.basePath = environment.basePath;
  }
  // Get the connect.sid cookie
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Extract device type
    this.deviceType = this.deviceService.getDeviceInfo().deviceType;

    // Extract subdomain
    const subdomain = this.getSubdomain();
    // const subdomain = "rst";

    // Clone the request to add withCredentials if SESSIONID cookie is missing
    if (!this.cookieService.get("SESSIONID")) {
      request = request.clone({ withCredentials: true });
    }

    // Clone the request to add Platform and Subdomain headers
    let clonedRequest = request.clone({
      headers: request.headers,
      setHeaders: {
        Platform: this.deviceType,
        Subdomain: subdomain, // Adding the Subdomain header
      },
    });

    // Retry logic and error handling
    return next.handle(clonedRequest).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          return from(this.refreshToken()).pipe(
            switchMap((res: any) => {
              const { token } = res;
              // Clone the original request and set new headers
              const clonedRequest = request.clone({
                setHeaders: {
                  Authorization: `Bearer ${token}`,
                  Platform: this.deviceType,
                  Subdomain: subdomain, // Retaining the Subdomain header in the retry request
                },
              });
              // Retry the request with new headers
              return next.handle(clonedRequest);
            }),
            catchError((refreshError) => {
              this.handleAuthError(); // Handle unauthorized error
              return throwError(refreshError);
            })
          );
        } else if (err.status === 403) {
          notyf.error(
            "Forbidden: You do not have permission to access this resource."
          );
        } else if (err.status === 0 || err.status >= 500) {
          notyf.error("Server Error: Please try again later.");
        } else {
          if (err.status == 405) {
            this.router.navigate(["login"]);
            this.cookieService.deleteAll();
            notyf.error(err.error.message || "An error occurred.");
          }
        }

        return throwError(err); // Re-throw the error for further handling
      })
    );
  }

  clearLocalStorageItems() {
    const keysToRemove = [
      "is_trial",
      "allow_multicompany",
      "userFullName",
      "fy_id",
      "company_id",
      "last_Backup",
      "help_Desk_Url",
      "Website",
      "POSEnable",
      "CompanyInfo",
      "menuData",
      "menuItemData",
      "isTransactionGSTslab",
      "isEBillingEnable",
      "isRstInvoice",
      "websiteinvoce",
      "kotArr",
    ];

    keysToRemove.forEach((key) => {
      localStorage.removeItem(key);
    });
  }

  // Call the function to clear the items

  refreshToken(): Promise<any> {
    return this.dataService
      .getTokens()
      .toPromise()
      .then((res) => {
        const { status, token, refreshToken } = res;

        if (status) {
          if (token && refreshToken) {
            this.cookieService.set("SESSIONID", token, 10); // Set new token in cookies
            this.cookieService.set("refresh_token", refreshToken, 10); // Set new token in cookies
          }
        } else {
          notyf.error("Unauthorized"); // Use error message or generic message
          this.router.navigate(["login"]);
          throw new Error("Token refresh failed");
        }
        return res;
      });
  }

  handleAuthError() {
    // localStorage.clear();
    localStorage.removeItem("company_name");
    localStorage.removeItem("userName");
    localStorage.removeItem("emailId");
    localStorage.removeItem("userRole");
    localStorage.removeItem("role_id");
    localStorage.removeItem("user_id");
    localStorage.removeItem("ver");
    localStorage.removeItem("currency");
    localStorage.removeItem("licence");
    localStorage.removeItem("tenent_id");
    localStorage.removeItem("isrst");
    this.cookieService.delete("refresh_token");
    this.cookieService.delete("SESSIONID");
    this.cookieService.delete("userSession");
    this.clearLocalStorageItems();
    // Show error message and redirect to login
    notyf.error("Unauthorized"); // Use error message or generic message
    this.router.navigate(["login"]);
  }
  // intercept(
  //   request: HttpRequest<any>,
  //   next: HttpHandler
  // ): Observable<HttpEvent<any>> {

  //   this.deviceType = this.deviceService.getDeviceInfo().deviceType;

  //     if (!this.cookieService.get("SESSIONID")) {
  //       request = request.clone({ withCredentials: true });
  //     }

  //   let clonedRequest  = request.clone(
  //     {
  //     headers:request.headers,
  //     setHeaders:{
  //       Platform: this.deviceType
  //     },
  //   }
  // )
  // request = clonedRequest
  //   return next.handle(clonedRequest).pipe(

  //     catchError((err: HttpErrorResponse) => {

  //   if (err.status === 401) {

  //     return from(this.refreshToken()).pipe(
  //       switchMap((res: any) => {

  //         const { token } = res;

  //         // Clone the original request and set new headers
  //         const clonedRequest = request.clone({
  //           setHeaders: {
  //             Authorization: `Bearer ${token}`,
  //             Platform: this.deviceType

  //           },

  //         });

  //         // Retry the request with new headers
  //         return next.handle(clonedRequest);
  //       }),
  //       catchError(refreshError => {

  //         this.handleAuthError(); // Handle unauthorized error
  //         return throwError(refreshError);
  //       })
  //     );
  //     // }
  //   } else if (err.status === 403) {
  //     notyf.error(
  //       "Forbidden: You do not have permission to access this resource."
  //     );
  //     // Handle forbidden errors (optional)
  //   } else if (err.status === 0 || err.status >= 500) {
  //     notyf.error("Server Error: Please try again later.");
  //     // Handle server errors (optional)
  //   } else {
  //     if (err.status == 405) {
  //       this.router.navigate(["login"]);
  //       // localStorage.clear();
  //       this.cookieService.deleteAll();
  //       notyf.error(err.error.message || "An error occurred."); // Use NotifierService (optional)
  //     }
  //   }

  //   return throwError(err); // Re-throw the error for further handling

  //     })
  //   );
  // }

  private getSubdomain(): string {
    const hostname = window.location.hostname;
    const parts = hostname.split(".");
    if (parts.length > 2) {
      return parts[0]; // Assuming the first part is the subdomain
    }
    return ""; // Return an empty string if no subdomain is found
  }
}

// private handleError(err: HttpErrorResponse,request:any): Observable<never> {
//   console.error("Interceptor Error:", err); // Log the error
//   // if (
//   //   request.url !== `${this.basePath}/api/getTokens` &&
//   //   request.url !== `${this.basePath}/api/login`
//   // ) {
//   if (err.status === 401) {
//     // localStorage.clear();
//     // this.cookieService.deleteAll();
//     // notyf.error(err.error.message || "Unauthorized"); // Use error message or generic message
//     // this.router.navigate(["admin"]);
//     // this.executeTokenSubscriptionOnce()

//     // }
//   } else if (err.status === 403) {
//     notyf.error(
//       "Forbidden: You do not have permission to access this resource."
//     );
//     // Handle forbidden errors (optional)
//   } else if (err.status === 0 || err.status >= 500) {
//     notyf.error("Server Error: Please try again later.");
//     // Handle server errors (optional)
//   } else {
//     if (err.status == 405) {
//       this.router.navigate(["login"]);
//       // localStorage.clear();
//       localStorage.removeItem('company_name');
//       localStorage.removeItem('userName');
//       localStorage.removeItem('emailId');
//       localStorage.removeItem('userRole');
//       localStorage.removeItem('role_id');
//       localStorage.removeItem('user_id');
//       localStorage.removeItem('ver');
//       localStorage.removeItem('currency');
//       localStorage.removeItem('licence');
//       localStorage.removeItem('tenent_id');
//       localStorage.removeItem('isrst');
//       this.clearLocalStorageItems();
//       this.cookieService.deleteAll();
//     }
//     notyf.error(err.error.message || "An error occurred."); // Use NotifierService (optional)
//   }

//   return throwError(err); // Re-throw the error for further handling
// }

// intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
//   if (!this.cookieService.get('SESSIONID')) {
//     request = request.clone({
//       withCredentials: true
//     });
//   }

//   return next.handle(request).pipe(
//     catchError((error: any) => {
//       if (error instanceof HttpErrorResponse && error.status === 401) {

//         notyf.error(error.error.message);
//         this.router.navigate(['/admin']);
//       }

//       return throwError(error);
//     })
//   );
// }
