import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { MatDialogModule } from "@angular/material/dialog";
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { DataService } from "../../data.service";

@Component({
  selector: "app-call-rider",
  templateUrl: "./call-rider.component.html",
  styleUrls: ["./call-rider.component.css"],
  standalone: true,
  imports: [
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule
  ]
})
export class CallRiderComponent implements OnInit {
  constructor(
    // 
    private apiService: DataService,
    @Inject(MAT_DIALOG_DATA) public transaction_id: any,
    private dialogRef: MatDialogRef<CallRiderComponent>
  ) {
  }
  deliveryAgentDetails: any;
  ngOnInit() {
    // this.apiService.getWeraDeliveryAgentDetails(this.transaction_id).subscribe(
    //   (result: any) => {
    //     this.deliveryAgentDetails = result.data.details;
    //   },
    //   (result: any) => {}
    // );
  }

  onClose() {
    this.dialogRef.close(this.transaction_id);
  }
}
