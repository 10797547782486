import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from '../data.service';
import { CookieService } from 'ngx-cookie-service';
import { CacheServiceService } from '../cache-service.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {
  SESSIONID: any;
  constructor(private router: Router,
    private apiService: DataService,
    private cookieService: CookieService, private cacheService: CacheServiceService) { }

  ngOnInit() {
    this.load();
  }
  load() {
    this.SESSIONID = this.cookieService.get('SESSIONID');
    this.apiService.logout()
      .subscribe((result: any) => {
        this.cacheService.clear()
        this.cookieService.deleteAll();
        // localStorage.clear();
        sessionStorage.clear();
        this.cookieService.delete('SESSIONID');
        localStorage.removeItem('company_name');
        localStorage.removeItem('userName');
        localStorage.removeItem('emailId');
        localStorage.removeItem('userRole');
        localStorage.removeItem('role_id');
        localStorage.removeItem('user_id');
        localStorage.removeItem('ver');
        localStorage.removeItem('currency');
        localStorage.removeItem('licence');
        localStorage.removeItem('tenent_id');
        localStorage.removeItem('isrst');
        function clearLocalStorageItems() {
          const keysToRemove = [
            'is_trial',
            'allow_multicompany',
            'userFullName',
            'fy_id',
            'company_id',
            'last_Backup',
            'help_Desk_Url',
            'Website',
            'POSEnable',
            'CompanyInfo',
            'menuData',
            'menuItemData',
            'isTransactionGSTslab',
            'isEBillingEnable',
            'isRstInvoice',
            'websiteinvoce',
            'kotArr'
          ];
        
          keysToRemove.forEach(key => {
            localStorage.removeItem(key);
          });
        }
        
        // Call the function to clear the items
        clearLocalStorageItems();
        this.router.navigate(['login']);
      }, (result: any) => {

      });
  }
}
