<!-- <div class="dashboard-container">
  <div class="status-container">
    <h3 class="color-h3">Dashboard</h3>
    <div class="status-box" [ngStyle]="{ 'background-color': statusColor }" [ngClass]="getStatusSizeClass()">
      <span class="status-label">{{ statusLabel }}</span>
      <span *ngIf="statusText" class="status-text">- {{ statusText }}</span>
    </div>    
    <div class="table-status-box">
      <div
        style="
          display: flex;
          align-items: center;
          column-gap: 20px;
          margin-right: 10px;
        "
      >
      </div>
    </div>
  </div> -->

<div class="table-details">
  <div
    class=""
    *ngFor="let group of tableGroup"
    style="display: flex; flex-wrap: wrap; margin-right: 2px; margin-left: 0px"
  >
    <!-- class="row" -->
    <div class="col-md-12" style="margin-top: 10px; margin-left: -15px">
      <span class="grp-name"> {{ group }}</span>
    </div>
    <ng-container *ngFor="let table of tableArr">
      <div
        class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-2 col-xxl-2 table-container"
        *ngIf="table.group_name == group"
        (click)="gotoInvoice(table)"
      >
        <div
          class="col-12 table-box"
          [ngClass]="[
            table.kot_count != 0
              ? 'alert-pink'
              : !table.display_trans_no
              ? 'white-space '
              : 'alert-success'
          ]"
          [ngStyle]="{
            'background-color':
              table.kot_count != 0
                ? backgroundColor
                : !table.display_trans_no
                ? backgroundColor
                : backgroundColor
          }"
        >
          <div class="table-image">
            <div
              style="float: right; margin-right: 10px"
              *ngIf="table.waiter == true && table.waiter != 'undefind'"
            >
              <img
                src="assets/images/waiter2.png"
                alt="Water"
                class="blinking-icon"
              />
              <div
                style="float: right; color: rgb(69, 8, 209)"
                *ngIf="table.webactive == true && table.webactive != 'undefind'"
              >
                <img
                  src="assets/images/customerapp.png"
                  class="blinking-icon"
                />
              </div>
            </div>
            <div
              style="float: right; margin-right: 10px"
              *ngIf="table.water == true && table.water != 'undefind'"
            >
              <img
                src="assets/images/water.png"
                alt="Water Icon"
                class="blinking-icon"
              />
            </div>
          </div>
          <h6
            [ngStyle]="{'padding-bottom': table.kot_count != 0 ? '0px' : '', }"
            style="font-size: 16px"
            [ngClass]="[
              table.kot_count == 0 && !table.display_trans_no
                ? 'table-title'
                : ''
            ]"
          >
            {{ table.name }}
          </h6>
          <div *ngIf="table.kot_count > 0 || table.display_trans_no">
            <span>
              <ng-container *ngIf="table.kot_count > 0">
                KOT: {{ table.kot_count }}
              </ng-container>

              <ng-container
                *ngIf="table.kot_count > 0 && table.display_trans_no"
              >
                |
              </ng-container>

              <ng-container
                *ngIf="table.display_trans_no && table.inv_count > 0"
              >
                Invoice: {{ table.inv_count }}
              </ng-container>

              <ng-container
                *ngIf="table.display_trans_no || table.kot_count > 0"
              >
                | Total: <i class="fa fa-{{ currency }}"></i
                >{{
                  table.inv_amt + (table.kot_count > 0 ? table.kot_amount : 0)
                    | number : "1.2-2"
                }}
              </ng-container>
            </span>
          </div>
          <div class="table-icons" *ngIf="table.invoice !== null">
            <!-- Print Icon Button -->
            <button
              class="icon-button"
              *ngIf="table.kot_count > 0"
              (click)="openInvoiceModal(table); $event.stopPropagation()"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="cadetblue"
              >
                <path
                  d="M640-640v-120H320v120h-80v-200h480v200h-80Zm-480 80h640-640Zm560 100q17 0 28.5-11.5T760-500q0-17-11.5-28.5T720-540q-17 0-28.5 11.5T680-500q0 17 11.5 28.5T720-460Zm-80 260v-160H320v160h320Zm80 80H240v-160H80v-240q0-51 35-85.5t85-34.5h560q51 0 85.5 34.5T880-520v240H720v160Zm80-240v-160q0-17-11.5-28.5T760-560H200q-17 0-28.5 11.5T160-520v160h80v-80h480v80h80Z"
                />
              </svg>
            </button>

            <!-- View Icon Button -->
            <button
              class="icon-button view"
              *ngIf="table.kot_count > 0"
              (click)="openKOTModal(table); $event.stopPropagation()"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="cadetblue"
              >
                <path
                  d="M480-320q75 0
                127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5
                52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45
                0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5
                31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-146
                0-266-81.5T40-500q54-137 174-218.5T480-800q146 0 266
                81.5T920-500q-54 137-174 218.5T480-200Zm0-300Zm0 220q113 0
                207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5
                59.5T128-500q50 101 144.5 160.5T480-280Z"
                />
              </svg>
            </button>

            <button
              class="icon-button"
              *ngIf="
                table.kot_count == 0 &&
                table.display_trans_no &&
                table.inv_count >= 1
              "
              (click)="receiptDialog(table); $event.stopPropagation()"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="cadetblue"
              >
                <path
                  d="M760-200H320q-33 0-56.5-23.5T240-280v-560q0-33 23.5-56.5T320-920h280l240 240v400q0 33-23.5 56.5T760-200ZM560-640v-200H320v560h440v-360H560ZM160-40q-33 0-56.5-23.5T80-120v-560h80v560h440v80H160Zm160-800v200-200 560-560Z"
                />
              </svg>
            </button>
          </div>

          <!-- <div *ngIf="table.display_trans_no">
              <span>{{table.display_trans_no}}</span><br>
              <span><i class="fa fa-{{currency}}"></i>{{table.kot_amount | number : '1.2-2'}}</span>
            </div> -->
        </div>
      </div>
    </ng-container>
  </div>
</div>
<!-- </div> -->
