import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { BehaviorSubject, Observable } from "rxjs";
import * as models from "../data.modal";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class RstDashboardService {
  basePath: any = "https://rst.intellibooks.io";
  cookieValue: any = "";
  constructor(private http: HttpClient, private cookieService: CookieService) {
    this.basePath = environment.basePath;
  }

  getHeader(): HttpHeaders {
    let headers = new HttpHeaders();
    this.cookieValue = this.cookieService.get("SESSIONID");
    headers = headers.set("Accept", "application/json");
    headers = headers.set("Content-Type", "application/json");
    // headers = headers.set("Authorization", "bearer " + this.cookieValue);
    if (this.cookieService.get("refresh_token") && this.cookieValue) {
      headers = headers.set("Authorization", "Bearer " + this.cookieValue);
    }
    return headers;
  }

  getTableListWithKOT(): Observable<any> {
    let headers = this.getHeader();
    return this.http.get<any>(`${this.basePath}/api/TableWithKOT`, { headers });
  }

  getmode(): Observable<any> {
    let headers = this.getHeader();
    return this.http.get<any>(`${this.basePath}/api/menu-dashboard-mode`, {
      headers,
    });
  }

  getSONotApproved(): Observable<any> {
    let headers = this.getHeader();
    return this.http.get<any>(`${this.basePath}/api/SOnotApprovedWithItems`, {
      headers,
    });
  }

  getSONotFinished(): Observable<any> {
    let headers = this.getHeader();
    return this.http.get<any>(`${this.basePath}/api/SOnotFinishedWithItems`, {
      headers,
    });
  }

  approveKot(id: any): Observable<any> {
    let headers = this.getHeader();
    return this.http.get<any>(
      `${this.basePath}/api/ApproveKot/${encodeURIComponent(id)}`,
      { headers }
    );
  }

  // deleteSalesOrder(id: string | 'null', reason: any): Observable<any> {
  //   let headers = this.getHeader();
  //   return this.http.delete<any>(`${this.basePath}/api/SalesOrder/${encodeURIComponent(id)}`,
  //     {
  //       headers,
  //       body: JSON.stringify(reason)
  //     });
  // }

  deleteSalesOrder(id: string | "null", reason: any): Observable<any> {
    let headers = this.getHeader();
    return this.http.delete<any>(
      `${this.basePath}/api/SalesOrder/${encodeURIComponent(id)}`,
      {
        headers,
        body: reason,
      }
    );
  }

  deleteReservedTablesDetails(code: string | "null"): Observable<any> {
    let headers = this.getHeader();
    return this.http.delete<any>(
      `${this.basePath}/api/deleteReservedTablesDetails/${encodeURIComponent(
        code
      )}`,
      {
        headers,
      }
    );
  }

  // generate_token(code: any): Observable<any> {
  //   let headers = this.getHeader();
  //   return this.http.post<any>(`${this.basePath}/api/generate_token`,
  //     {
  //       headers,
  //       body: JSON.stringify(code)
  //     });
  // }

  approveWeraKot(id: string | "null", data: any | "null"): Observable<any> {
    let headers = this.getHeader();
    return this.http.post<any>(
      `${this.basePath}/api/approveWeraOrder/${encodeURIComponent(id)}`,
      data,
      {
        headers,
      }
    );
  }

  rejectWeraKot(id: string | "null", data: any | "null"): Observable<any> {
    let headers = this.getHeader();
    return this.http.post<any>(
      `${this.basePath}/api/rejectWeraOrder/${encodeURIComponent(id)}`,
      data,
      {
        headers,
      }
    );
  }

  markProcessingKot(data: any): Observable<any> {
    let headers = this.getHeader();
    return this.http.post<any>(`${this.basePath}/api/ProcessingKot`, data, {
      headers,
    });
  }

  markFinishedKot(id: any): Observable<any> {
    let headers = this.getHeader();
    return this.http.get<any>(
      `${this.basePath}/api/FinishedKot/${encodeURIComponent(id)}`,
      {
        headers,
      }
    );
  }

  markOrderPickedUp(transaction_id: any): Observable<any> {
    let headers = this.getHeader();
    const data = { transactionId: transaction_id };
    return this.http.post<any>(`${this.basePath}/api/weraOrderPickedUp`, data, {
      headers,
    });
  }

  weraFoodReady(transaction_id: any): Observable<any> {
    let headers = this.getHeader();
    const data = { transactionId: transaction_id };
    return this.http.post<any>(
      `${this.basePath}/api/foodReadyWeraOrder`,
      data,
      {
        headers,
      }
    );
  }

  addInvoice(data: models.TransactionObj | "null"): Observable<any> {
    let headers = this.getHeader();
    return this.http.post<any>(`${this.basePath}/api/Invoice`, data, {
      headers,
    });
  }

  downloadPdf(data: models.TransactionObj | "null"): Observable<Blob> {
    let headers = new HttpHeaders();
    headers = headers.set("Accept", "application/pdf");
    headers = headers.set("Content-Type", "application/json");
    this.cookieValue = this.cookieService.get("SESSIONID");
    // headers = headers.set("Authorization", "bearer " + this.cookieValue);
    headers = headers.set("Authorization", "Bearer " + this.cookieValue);

    return this.http.post<Blob>(`${this.basePath}/api/PdfDownload`, data, {
      headers: headers,
      responseType: "blob" as "json", // Cast 'json' to 'blob' to match the type
    });
  }

  downloadPdfByTransId(id: any): Observable<Blob> {
    let headers = new HttpHeaders();
    headers = headers.set("Accept", "application/pdf");
    headers = headers.set("Content-Type", "application/json");
    this.cookieValue = this.cookieService.get("SESSIONID");
    // headers = headers.set("Authorization", "bearer " + this.cookieValue);
    headers = headers.set("Authorization", "Bearer " + this.cookieValue);

    return this.http.post<any>(
      `${this.basePath}/api/printApprovedTransaction/${encodeURIComponent(id)}`,
      {
        headers,
        responseType: "blob" as "json",
      }
    );
  }

  updateReservationTable(
    id: string | "null",
    data: any | "null"
  ): Observable<any> {
    let headers = new HttpHeaders();

    return this.http.put<any>(
      `${this.basePath}/api/updateReservationTable/${encodeURIComponent(id)}`,
      data,
      {
        headers,
      }
    );
  }

  getTablesWithGroup(): Observable<any> {
    let headers = this.getHeader();
    return this.http.get<any>(`${this.basePath}/api/getTablesWithGroup`, {
      headers,
    });
  }

  submitReservationTable(data: any): Observable<any> {
    let headers = this.getHeader();
    return this.http.post<any>(
      `${this.basePath}/api/submitReservationTable`,
      data,
      {
        headers,
      }
    );
  }

  getUnApprovedReservedTables(): Observable<any> {
    let headers = this.getHeader();
    return this.http.get<any>(
      `${this.basePath}/api/getUnApprovedReservedTables`,
      {
        headers,
      }
    );
  }

  getUnApprovedReservedTableswithDate(string: any): Observable<any> {
    let headers = this.getHeader();
    return this.http.get<any>(
      `${
        this.basePath
      }/api/getUnApprovedReservedTableswithDate/${encodeURIComponent(string)}`,
      {
        headers,
      }
    );
  }

  addReceipt(data: models.TransactionObj | "null"): Observable<any> {
    let headers = this.getHeader();
    return this.http.post<any>(`${this.basePath}/api/Receipt`, data, {
      headers,
    });
  }

  getTableByTransId(id: string | "null"): Observable<any> {
    let headers = this.getHeader();
    return this.http.get<any>(
      `${this.basePath}/api/TableByTransId/${encodeURIComponent(id)}`,
      {
        headers,
      }
    );
  }

  getWebActiveTable(table_id: any): Observable<any> {
    let headers = this.getHeader();
    return this.http.get<any>(
      `${this.basePath}/api/getActiveTable/${encodeURIComponent(table_id)}`,
      {
        headers,
      }
    );
  }

  getTableInfo(table_id: any, data: any): Observable<any> {
    let headers = this.getHeader();
    return this.http.post<any>(
      `${this.basePath}/api/getTableInfo/${encodeURIComponent(table_id)}`,
      data,
      {
        headers,
      }
    );
  }

  ncValidation(): Observable<any> {
    let headers = this.getHeader();
    return this.http.get<any>(`${this.basePath}/api/sendmail`, {
      headers,
    });
  }

  getCustomFilterItemList(
    filter: string | "null",
    filterItem: any,
    table_id: any | "null"
  ): Observable<any> {
    let headers = this.getHeader();
    return this.http.get<any>(
      `${this.basePath}/api/CustomFilterItemList/${encodeURIComponent(
        filter
      )}/${encodeURIComponent(filterItem)}/${encodeURIComponent(table_id)}`,
      {
        headers,
      }
    );
  }

  getCustomItemList(filter: any): Observable<any> {
    let headers = this.getHeader();
    return this.http.get<any>(
      `${this.basePath}/api/CustomItemList/${encodeURIComponent(filter)}`,
      {
        headers,
      }
    );
  }

  addSalesOrder(data: models.TransactionObj | "null"): Observable<any> {
    let headers = this.getHeader();
    return this.http.post<any>(`${this.basePath}/api/SalesOrder`, data, {
      headers,
    });
  }

  updateSessionAllowLogin(isAllow: any, table_id: any): Observable<any> {
    let headers = this.getHeader();
    return this.http.get<any>(
      `${this.basePath}/api/SessionAllowLogin/${encodeURIComponent(
        table_id
      )}/${encodeURIComponent(isAllow)}`,
      {
        headers,
      }
    );
  }

  updateActiveTable_inactive(active: any, table_id: any): Observable<any> {
    let headers = this.getHeader();
    return this.http.get<any>(
      `${this.basePath}/api/clearTable/${encodeURIComponent(
        table_id
      )}/${encodeURIComponent(active)}`,
      {
        headers,
      }
    );
  }

  updatewaterwaiter(table_id: any): Observable<any> {
    let headers = this.getHeader();
    return this.http.post<any>(
      `${this.basePath}/api/doneWaterOrWaiter/${encodeURIComponent(table_id)}`,
      {
        headers,
      }
    );
  }

  verifyemailotp(data: any): Observable<any> {
    let headers = this.getHeader();
    return this.http.post<any>(`${this.basePath}/api/verifyotp`, data, {
      headers,
    });
  }

  getEBillAcountMapData(): Observable<any> {
    let headers = this.getHeader();
    return this.http.get<any>(`${this.basePath}/api/getEBillAcountMapData`, {
      headers,
    });
  }

  getSalesOrder(id: string | "null"): Observable<any> {
    let headers = this.getHeader();
    return this.http.get<any>(
      `${this.basePath}/api/SalesOrder/${encodeURIComponent(id)}`,
      {
        headers,
      }
    );
  }

  getItemList(): Observable<any> {
    let headers = this.getHeader();
    return this.http.get<any>(`${this.basePath}/api/Items`, {
      headers,
    });
  }

  editSalesOrder(id: string | "null", data: any): Observable<any> {
    let headers = this.getHeader();
    return this.http.put<any>(
      `${this.basePath}/api/SalesOrder/${encodeURIComponent(id)}`,
      data,
      {
        headers,
      }
    );
  }

  transferTable(table: any): Observable<any> {
    let headers = this.getHeader();
    return this.http.get<any>(
      `${this.basePath}/api/TransferTable/${encodeURIComponent(
        table.table_id_1
      )}/${encodeURIComponent(table.table_id_2)}`,
      {
        headers,
      }
    );
  }

  fetchContacts(page: number, limit: number): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(
      `${this.basePath}/api/crm/contacts?page=${page}&limit=${limit}`,
      {
        headers,
      }
    );
  }

  fetchContactByPhone(phone_no: string): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(
      `${this.basePath}/api/crm/contacts/phone/${phone_no}`,
      {
        headers,
      }
    );
  }

  getOrderHistoryByPhoneNumber(phone_no: string): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(
      `${this.basePath}/api/crm/contacts/order-history/${phone_no}`,
      {
        headers,
      }
    );
  }

  // deleteContact(customerID: number): Observable<any> {
  //   let headers = this.getHeader();
  //   return this.http
  //     .delete<any>(`${this.basePath}/api/crm/contacts`),
  //       {
  //         observe: "response",
  //         headers,
  //         body: JSON.stringify({ CustomerID: customerID }),
  //       }
  //     )

  // }

  addContact(contact: any): Observable<any> {
    let headers = this.getHeader();
    return this.http.post<any>(`${this.basePath}/api/crm/contacts`, contact, {
      headers,
    });
  }

  editContact(contact: any): Observable<any> {
    let headers = this.getHeader();
    return this.http.put<any>(`${this.basePath}/api/crm/contacts`, contact, {
      headers,
    });
  }

  importContact(data: any) {
    let headers = this.getHeader();

    return this.http.post<any>(
      `${this.basePath}/api/crm/contacts/import`,
      data,
      {
        headers,
      }
    );
  }

  importContacts(formData: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set("Accept", "multipart/form-data");
    this.cookieValue = this.cookieService.get("SESSIONID");
    // headers = headers.set("Authorization", "bearer " + this.cookieValue);
    headers = headers.set("Authorization", "Bearer " + this.cookieValue);
    return this.http.post<any>(
      `${this.basePath}/api/crm/contacts/import`,
      formData,
      {
        headers,
      }
    );
  }

  searchNumber(contactNo: any): Observable<any> {
    let headers = this.getHeader();
    return this.http.post<any>(
      `${this.basePath}/api/crm/searchnumber`,
      { contactNo: contactNo },
      {
        headers,
      }
    );
  }
  private dashboardStatus = new BehaviorSubject<any>(null);
  sharedData = this.dashboardStatus.asObservable();

  setDashboardStatus(data: any) {
    this.dashboardStatus.next(data);
  }

  getDashboardStatus() {
    return this.sharedData;
  }

  //
  menuSubCategoryList(category_id: any): Observable<any> {
    let headers = this.getHeader();
    return this.http.get<any>(
      `${this.basePath}/api/menu-items/categories/${encodeURIComponent(
        category_id
      )}/subcategories`,
      {
        headers,
      }
    );
  }

  menuItemListByCategory(category_id: any, table_id: any): Observable<any> {
    let headers = this.getHeader();
    return this.http.get<any>(
      `${this.basePath}/api/menu-items/categories/${encodeURIComponent(
        category_id
      )}/items?tableId=${encodeURIComponent(table_id)}
`,
      {
        headers,
      }
    );
  }

  menuItemListBySubCategory(
    sub_category_id: any,
    table_id: any
  ): Observable<any> {
    let headers = this.getHeader();
    return this.http.get<any>(
      `${this.basePath}/api/menu-items/subcategories/${encodeURIComponent(
        sub_category_id
      )}/items?tableId=${encodeURIComponent(table_id)}
`,
      {
        headers,
      }
    );
  }

  getdashboardInfo(table_id: any): Observable<any> {
    let headers = this.getHeader();
    return this.http.get<any>(
      `${this.basePath}/api/getDashboardkot/${encodeURIComponent(table_id)}`,

      {
        headers,
      }
    );
  }

  getreceiptData(display_trans_no: any): Observable<any> {
    let headers = this.getHeader();
    return this.http.get<any>(
      `${this.basePath}/api/getunpaidData/${encodeURIComponent(
        display_trans_no
      )}`,
      {
        headers,
      }
    );
  }
}
