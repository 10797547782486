import { CommonModule } from "@angular/common";
import { Component, Input, Output, EventEmitter } from "@angular/core";
import { ItemCardComponent } from "../item-card/item-card.component";

@Component({
  selector: "app-category-cart",
  standalone: true,
  imports: [CommonModule, ItemCardComponent],
  templateUrl: `./category-cart.component.html`,
  styleUrls: ["./category-cart.component.css"],
})
export class CategoryCartComponent {
  // Centralized array for images and titles
  @Input() categories: any[] = [];

  selectedIndex: number | null = null;
  selectedBorderColor: string = "#ff0022";
  selectedBackgroundColor: string = "#f8f8fa";

  // New inputs for title customization
  // New inputs for title customization
  @Input() titleSize: string = "14px"; // Default size
  @Input() titleWeight: string = "500"; // Default weight
  @Input() titleColor: string = "#04030f"; // Default color
  @Input() showPrice: boolean = false;
  @Input() tableObj: any;

  @Output() cardSelected = new EventEmitter<any>();

  get titleStyle() {
    return {
      fontSize: this.titleSize,
      fontWeight: this.titleWeight,
      color: this.titleColor,
    };
  }

  // Method to select a card
  showitems(index: number) {
    this.selectedIndex = index;
    const selectedCategory = this.categories[index]; // Get the selected category
    this.cardSelected.emit(selectedCategory);
  }
}
