
    <!-- {{deliveryAgentDetails | json}} -->

<div class="card w-100">
  <div class="card-body">
    <h5 class="card-title">Rider Details</h5>
    <p class="card-text"><strong>Name -</strong> {{ deliveryAgentDetails.rider_name }}</p>
    <p class="card-text"><strong>Phone Number -</strong> {{ deliveryAgentDetails.rider_phone_number }}</p>
    <p class="card-text"><strong>Status -</strong> {{ deliveryAgentDetails.rider_status }}</p>
    <p class="card-text"><strong>Time to arrive -</strong> {{ deliveryAgentDetails.time_to_arrive }}</p>
    <!-- <p class="card-text" *ngIf="deliveryAgentDetails.alternate_number"><strong>Alternate Number -</strong> {{ deliveryAgentDetails.alternate_number }}</p> -->
  </div>
</div>
