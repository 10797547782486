import { CommonModule } from "@angular/common";
import { Component, Input, Output, EventEmitter } from "@angular/core";
import { SharedModule } from "../../../app/common.module";
import { Router } from "@angular/router";

@Component({
  selector: "app-category-card-button",
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: "./category-card-button.component.html",
  styleUrls: ["./category-card-button.component.css"],
})
export class CategoryCardButtonComponent {
  @Input() showBackButton: boolean = true; // Use boolean instead of string for better practice
  @Input() table_name: string = "";
  @Input() tableCapacity: number = 0;
  @Input() webappActiveTable: boolean = false;
  @Input() allow_login: boolean = false;
  @Input() water: boolean = false;
  @Input() waiter: boolean = false;
  @Input() tableObj: any;

  @Output() TransferDialog = new EventEmitter<any>();
  @Output() redirectToKey = new EventEmitter<any>();
  @Output() clearTab = new EventEmitter<any>();
  @Output() clearwater = new EventEmitter<any>();
  @Output() allowLoginChange = new EventEmitter<boolean>();

  constructor(private router: Router) {}

  clearTable() {
    this.clearTab.emit();
  }

  clearwaterwaiter() {
    this.clearwater.emit();
  }

  onAllowLogin() {
    this.allow_login = !this.allow_login;
    this.allowLoginChange.emit(this.allow_login);
  }

  redirectToKeyboard() {
    this.redirectToKey.emit();
  }

  openTransferDialog() {
    this.TransferDialog.emit();
  }

  navigateTo(route: string): void {
    this.router.navigate([route]);
  }
}
