<h2 mat-dialog-title>Select a Table</h2>
<mat-dialog-content>
  <input type="text" [(ngModel)]="filterText" (input)="filterTables()" placeholder="Search for a table..." />

  <div #dropdownList class="dropdown-list">
    <div 
      *ngFor="let table of filteredTables; let i = index" 
      [ngClass]="{'selected-option': i === selectedIndex}"
      (click)="onSelectTable(table)"
      class="dropdown-item"
    >
      {{ table.name }}
    </div>
  </div>

  <div *ngIf="filteredTables.length === 0" class="no-results">
    No matching tables found.
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="onCancel()">Cancel</button>
</mat-dialog-actions>
