<div class="main-container">
  <div *ngFor="let data of websiteInvoiceData" class="card-container">
    <div class="action-buttons" *ngIf="!data.kot_approved">
      <div style="display: flex; align-items: center; gap: 10px">
        <!-- Approve Button -->
        <button
          class="btn"
          [disabled]="data.kot_rejected"
          (click)="siteOrderApprove($event, data)"
          *ngIf="!data.kot_approved"
        >
          {{ data.kot_approved ? "Approved" : "Approve" }}
        </button>

        <!-- Reject Button -->
        <button
          class="btn"
          [disabled]="data.kot_approved"
          (click)="siteOrderReject($event, data)"
          *ngIf="!data.kot_approved"
        >
          {{ data.kot_rejected ? "Rejected" : "Reject" }}
        </button>
      </div>
    </div>

    <div class="customer-details">
      <p><span>Customer Name:</span> {{ data.customer_name }}</p>
      <p><span>Contact:</span> {{ data.contact_no }}</p>
      <p><span>Address:</span> {{ data.shipping_address1 }}</p>
    </div>

    <hr />

    <div class="invoice-details">
      <p><strong>#Invoice:</strong> {{ data.display_trans_no }}</p>
    </div>

    <div class="transaction-details">
      <p><strong>#KOT:</strong> {{ data.trans_no }}</p>
      <p><strong>Date:</strong> {{ data.trans_date | date : "short" }}</p>
      <p>
        <strong>Trans Amount: </strong> <i class="fa fa-{{ currency }}"></i
        >{{ data.net_amount | number : "1.0-0" }}
      </p>
    </div>

    <hr />

    <div class="rider-invoice">
      <span>Rider Invoice</span>
      <i
        class="fa fa-print text-primary"
        style="font-size: 25px; cursor: pointer"
        (click)="riderInvoice(data.transaction_id, data.display_trans_no)"
      ></i>
    </div>

    <div class="payment-status">
      <p [style.color]="data.advance === 0 ? 'crimson' : 'blueviolet'">
        Paid by {{ data.advance === 0 ? "COD" : "Online" }}
        <mat-checkbox
          type="checkbox"
          [checked]="data.show_details"
          (change)="NotificationSeen(data, $event)"
          style="cursor: pointer; margin-left: 5px"
        />
        {{ data.show_details ? "Seen" : "Unseen" }}
      </p>
    </div>
  </div>
</div>
