import { CommonModule } from "@angular/common";
import { Component, Input, Output, EventEmitter } from "@angular/core";

interface ItemDetail {
  item_id: number;
  item_name: string;
  qty: string;
  amount: number;
}

interface Kot {
  transaction_id: number;
  net_amount: number;
  trans_no: number;
  trans_date: string;
  approved: boolean;
  notes: string | null;
  order_mode: string;
  food_cancel: boolean;
  auto_accept: boolean;
  order_id: number | null;
  item_id: number;
  item_name: string;
  amount: number;
  qty: string;
  item_count: string;
  table_id: number;
  name: string;
  items_details: ItemDetail[];
}

@Component({
  selector: "app-web-app-card",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./web-app-card.component.html",
  styleUrls: ["./web-app-card.component.css"],
})
export class WebAppCardComponent {
  @Input() kotArr: Kot[] = [];
  @Input() currency: string = "usd";

  @Output() approve = new EventEmitter<{ event: any; kot: any }>();
  @Output() reject = new EventEmitter<{ event: any; kot: any }>();
  @Output() accept = new EventEmitter<{ event: any; kot: any }>();
  @Output() cancel = new EventEmitter<{ event: any; kot: any }>();

  onApproveClick(event: Event, kot: Kot) {
    this.approve.emit({ event, kot });
  }

  onRejectClick(event: Event, kot: Kot) {
    this.reject.emit({ event, kot });
  }

  orderAutoAccepted(event: Event, kot: Kot) {
    this.accept.emit({ event, kot });
  }

  cancelledOrder(event: Event, kot: Kot) {
    this.cancel.emit({ event, kot });
  }
}
