<div
  class="icon-container"
  [ngStyle]="{
    backgroundColor: isCircular ? iconBackground : 'transparent',
    borderRadius: isCircular ? '50%' : 'none',
    padding: isCircular ? '2px' : '0'
  }"
>
  <div
    class="icon-box"
    [ngStyle]="{
      width: iconSize + 'px',
      height: iconSize + 'px'
    }"
  >
    <svg
      *ngIf="iconPath"
      [attr.height]="iconSize + 'px'"
      [attr.width]="iconSize + 'px'"
      [attr.fill]="iconColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
      [title]="iconName"
    >
      <path [attr.d]="iconPath"></path>
    </svg>
    <span *ngIf="!iconPath">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        viewBox="0 -960 960 960"
        width="24px"
        fill="#00000"
      >
        <path
          d="M240-200h120v-240h240v240h120v-360L480-740 240-560v360Zm-80 80v-480l320-240 320 240v480H520v-240h-80v240H160Zm320-350Z"
        />
      </svg>
    </span>
  </div>
  <div class="icon-label" *ngIf="showLabel">{{ iconName }}</div>
</div>
