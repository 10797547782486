import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../data.service';

@Component({
  selector: 'app-get-bill',
  standalone: true,
  imports: [],
  templateUrl: './get-bill.component.html',
  styleUrl: './get-bill.component.css'
})
export class GetBillComponent {

  constructor(
    private apiService: DataService,
    private _Activatedroute: ActivatedRoute,

  ) {

    // _Activatedroute.queryParams.subscribe((params) => {
    //   console.log("GetBillComponentparams--", params)
    // });

  }

  ngOnInit() {
    // Subscribe to the queryParams observable to get the parameters

    // Get the query parameter
    this._Activatedroute.queryParams.subscribe(params => {
      const queryParamValue = params['s'];
      // console.log('Query Param (s):', queryParamValue);  // Output: '16'
      if (queryParamValue) {

        // Get the fragment (hash) from the URL
        this._Activatedroute.fragment.subscribe(fragment => {
          const fragmentValue = fragment;
          // console.log('Fragment:', fragmentValue);  // Output: '1458ad'

          const code = `${queryParamValue}#${fragmentValue}`
          this.apiService.getbill(code).subscribe((data) => {
            let blob = new Blob([data], {
              type: "application/pdf",
            });
            var link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "Invoice.pdf";
            link.click();
            window.URL.revokeObjectURL(link.href);
            this.clearUrl();
          });
        });
      }
    });
  }


  clearUrl() {
    console.log("window.location.origin + window.location.pathname--", window.location.origin + window.location.pathname)
    // This will remove the query parameters and the hash from the current URL
    window.location.href = window.location.origin + window.location.pathname;
  }
}