import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataService } from '../data.service';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { MatDialogModule } from "@angular/material/dialog";
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { SharedModule } from '../common.module';
import { ChangeUserPassComponent } from '../app-nav/change-user-pass/change-user-pass.component';

@Component({
  selector: 'app-tenent-nav',
  templateUrl: './tenent-nav.component.html',
  styleUrls: ['./tenent-nav.component.css'],
  standalone: true,
  imports: [
    SharedModule
  ]
})
export class TenentNavComponent {
  isMenuOpen = true;
  contentMargin = 250;
  userName: any;
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  constructor(private breakpointObserver: BreakpointObserver, 
    private apiService: DataService, public dialog: MatDialog) {
    
   }
  ngOnInit() {
  }
    
  onToolbarMenuToggle() {
    this.isMenuOpen = !this.isMenuOpen;

    if (!this.isMenuOpen) {
      this.contentMargin = 90;
    } else {
      this.contentMargin = 250;
    }
  }
  logout() {
  }
  changePass(){
    this.dialog.open(ChangeUserPassComponent,{
      height:'auto',
      width:'auto',
      data: 'Tenent'
    })
  
  }
}
