import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { BehaviorSubject, Observable } from "rxjs";
import * as models from "../data.modal";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class MasterService {
  basePath: any = "https://rst.intellibooks.io";
  cookieValue: any = "";
  constructor(private http: HttpClient, private cookieService: CookieService) {
    this.basePath = environment.basePath;
  }

  getHeader(): HttpHeaders {
    let headers = new HttpHeaders();
    this.cookieValue = this.cookieService.get("SESSIONID");
    headers = headers.set("Accept", "application/json");
    headers = headers.set("Content-Type", "application/json");
    // headers = headers.set("Authorization", "bearer " + this.cookieValue);
    if (this.cookieService.get("refresh_token") && this.cookieValue) {
      headers = headers.set("Authorization", "Bearer " + this.cookieValue);
    }
    return headers;
  }

  getTenentCompanyInfo(companyName: string | "null"): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(
      `${this.basePath}/api/getTenentCompanyInfo/${encodeURIComponent(
        companyName
      )}`,
      { headers }
    );
  }

  getTermsConditionsList(): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/CompanyTermsConditions`, {
      headers,
    });
  }

  deleteTermCondition(id: string | "null"): Observable<any> {
    let headers = this.getHeader();

    return this.http.delete<any>(
      `${this.basePath}/api/CompanyTermsConditions/${encodeURIComponent(id)}`,
      { headers }
    );
  }

  editQuotationTerms(id: string | "null", data: any | "null"): Observable<any> {
    let headers = this.getHeader();

    return this.http.put<any>(
      `${this.basePath}/api/companyQuotationTerms/${encodeURIComponent(id)}`,
      data,
      { headers }
    );
  }

  getEnableResturantPos(): Observable<any> {
    let headers = this.getHeader();
    return this.http.get<any>(`${this.basePath}/api/ChechEnableResturantPos`, {
      headers,
    });
  }

  getCategory(id: string | "null"): Observable<any> {
    let headers = this.getHeader();
    return this.http.get<any>(
      `${this.basePath}/api/Category/${encodeURIComponent(id)}`,
      { headers }
    );
  }

  editCategory(
    id: string | "null",
    data: models.CategoryObj | "null"
  ): Observable<any> {
    let headers = this.getHeader();
    return this.http.put<any>(
      `${this.basePath}/api/Category/${encodeURIComponent(id)}`,
      data,
      { headers }
    );
  }

  addCategory(data: models.CategoryObj | "null"): Observable<any> {
    let headers = this.getHeader();

    return this.http.post<any>(`${this.basePath}/api/Category`, data, {
      headers,
    });
  }

  addCategoryImage(data: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set("Accept", "multipart/form-data");
    this.cookieValue = this.cookieService.get("SESSIONID");
    // headers = headers.set("Authorization", "bearer " + this.cookieValue);
    headers = headers.set("Authorization", "Bearer " + this.cookieValue);

    return this.http.post<any>(
      `${this.basePath}/api/UploadCategoryImage`,
      data,
      { headers }
    );
  }

  getCategoryList(): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/Category`, { headers });
  }

  deleteCategory(id: string | "null"): Observable<any> {
    let headers = this.getHeader();

    return this.http.delete<any>(
      `${this.basePath}/api/Category/${encodeURIComponent(id)}`,
      { headers }
    );
  }

  //Kitchen API Service
  getKitchenList(active:any): Observable<any> {
    let headers = this.getHeader();
    return this.http.get<any>(`${this.basePath}/api/kitchen/list/${encodeURIComponent(active.active)}`, { headers });
  }

  addkitchen(data: models.kitchenObj | "null"): Observable<any> {
    let headers = this.getHeader();
    return this.http.post<any>(`${this.basePath}/api/kitchen`, data, {
      headers,
    });
  }

  getkitchen(id: string | "null"): Observable<any> {
    let headers = this.getHeader();
    return this.http.get<any>(
      `${this.basePath}/api/kitchen/${encodeURIComponent(id)}`,
      { headers }
    );
  }

  deletekitchen(id: string | "null"): Observable<any> {
    let headers = this.getHeader();
    return this.http.delete<any>(
      `${this.basePath}/api/kitchen/${encodeURIComponent(id)}`,
      { headers }
    );
  }

  editKitchen(
    id: string | "null",
    data: models.kitchenObj | "null"
  ): Observable<any> {
    let headers = this.getHeader();
    return this.http.put<any>(
      `${this.basePath}/api/kitchen/${encodeURIComponent(id)}`,
      data,
      { headers }
    );
  }

  //Sub-Category
  getSubCategoryList(id: any | "null"): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(
      `${this.basePath}/api/SubCategory/${encodeURIComponent(id)}`,
      { headers }
    );
  }

  deleteSubCategory(id: string | "null"): Observable<any> {
    let headers = this.getHeader();

    return this.http.delete<any>(
      `${this.basePath}/api/SubCategory/${encodeURIComponent(id)}`,
      { headers }
    );
  }

  getSubCategory(id: string | "null"): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(
      `${this.basePath}/api/SubCategoryBy/${encodeURIComponent(id)}`,
      { headers }
    );
  }

  editSubCategory(
    id: string | "null",
    data: models.RoleObj | "null"
  ): Observable<any> {
    let headers = this.getHeader();

    return this.http.put<any>(
      `${this.basePath}/api/SubCategory/${encodeURIComponent(id)}`,
      data,
      { headers }
    );
  }

  addSubCategory(data: models.RoleObj | "null"): Observable<any> {
    let headers = this.getHeader();

    return this.http.post<any>(`${this.basePath}/api/SubCategory`, data, {
      headers,
    });
  }

  addSubCategoryImage(data: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set("Accept", "multipart/form-data");
    this.cookieValue = this.cookieService.get("SESSIONID");
    // headers = headers.set("Authorization", "bearer " + this.cookieValue);
    headers = headers.set("Authorization", "Bearer " + this.cookieValue);

    return this.http.post<any>(
      `${this.basePath}/api/UploadSubCategoryImage`,
      data,
      { headers }
    );
  }

  getSubCategoryImage(id: string | "null"): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(
      `${this.basePath}/api/SubCategoryImage/${encodeURIComponent(id)}`,
      { headers }
    );
  }

  getSubCategorylist(): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/subCategorylist`, {
      headers,
    });
  }

  getSubCategorylistofList(): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/SubCategorylistofList`, {
      headers,
    });
  }

  // sales
  getItemSalesReport(
    from_date: any | "null",
    to_date: any | "null"
  ): Observable<any> {
    let headers = this.getHeader();
    from_date.setHours(0, 0, 0, 1);
    to_date.setHours(23, 59, 59, 59);

    return this.http.get<any>(
      `${this.basePath}/api/ItemSalesReport/${encodeURIComponent(
        from_date
      )}/${encodeURIComponent(to_date)}`,
      { headers }
    );
  }

  // //Get Voucher Type List
  getVoucherTypeList(): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/VoucherType`, { headers });
  }

  getTermCondition(id: string | "null"): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(
      `${this.basePath}/api/CompanyTermsConditions/${encodeURIComponent(id)}`,
      { headers }
    );
  }

  editTermCondition(id: string | "null", data: any | "null"): Observable<any> {
    let headers = this.getHeader();

    return this.http.post<any>(
      `${this.basePath}/api/CompanyTermsConditions/${encodeURIComponent(id)}`,
      data,
      { headers }
    );
  }

  addTermCondition(data: any | "null"): Observable<any> {
    let headers = this.getHeader();

    return this.http.post<any>(
      `${this.basePath}/api/CompanyTermsConditions`,
      data,
      { headers }
    );
  }

  //Company email settings
  editCompanyEmailSettingsInfo(
    id: string | "null",
    emailInfo: string | "null"
  ): Observable<any> {
    let headers = this.getHeader();

    return this.http.put<any>(
      `${this.basePath}/api/CompanyEmailSettings/${encodeURIComponent(id)}`,
      emailInfo,
      { headers }
    );
  }

  sgetGstState(
    filter: string | "null",
    inactive: string | "null"
  ): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(
      `${this.basePath}/api/sgstState/${encodeURIComponent(
        filter
      )}/${encodeURIComponent(inactive)}`,
      { headers }
    );
  }

  //get gst state code
  getGstState(): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/tntgstState`, { headers });
  }

  editCompanyInfo(
    id: string | "null",
    data: models.TenentObj | "null"
  ): Observable<any> {
    let headers = this.getHeader();

    return this.http.put<any>(
      `${this.basePath}/api/CompanyInfo/${encodeURIComponent(id)}`,
      data,
      { headers }
    );
  }

  editCompanyMapSettingsInfo(
    id: string | "null",
    mapInfo: string | "null"
  ): Observable<any> {
    let headers = this.getHeader();

    return this.http.put<any>(
      `${this.basePath}/api/CompanyMapSettingsInfo/${encodeURIComponent(id)}`,
      mapInfo,
      { headers }
    );
  }

  getTwilioBalance(smsCredentials: any): Observable<any> {
    const twilioAccountSid = smsCredentials.twilio_sid;
    const authToken = smsCredentials.twilio_token;
    const url = `https://api.twilio.com/2010-04-01/Accounts/${twilioAccountSid}/Balance.json`;

    const headers = new HttpHeaders({
      Authorization: `Basic ${btoa(`${twilioAccountSid}:${authToken}`)}`,
    });

    return this.http.get(url, { headers });
  }

  // =====================smsLowBalanceEmail ===============
  smsLowBalanceEmail(data: any): Observable<any> {
    let headers = this.getHeader();

    return this.http.put<any>(`${this.basePath}/api/smsLowBalanceEmail`, data, {
      headers,
    });
  }

  //Company sms settings-----------------------------------
  editCompanySmsSettingsInfo(
    id: string | "null",
    smsInfo: string | "null"
  ): Observable<any> {
    let headers = this.getHeader();

    return this.http.put<any>(
      `${this.basePath}/api/CompanySMSSettingsInfo/${encodeURIComponent(id)}`,
      smsInfo,
      { headers }
    );
  }

  resetSmsSetting(): Observable<any> {
    let headers = this.getHeader();

    return this.http.delete<any>(`${this.basePath}/api/company/sms-setting`, {
      headers,
    });
  }

  //Company Information API service
  getCompanyInfo(): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/CompanyInfo`, { headers });
  }

  //Multicompany API Service
  getCompanyList(tenent_id: string | "null"): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(
      `${this.basePath}/api/MultiCompanyList/${encodeURIComponent(tenent_id)}`,
      { headers }
    );
  }

  addCompany(data: any, tenent_id: string | "null"): Observable<any> {
    let headers = this.getHeader();

    return this.http.post<any>(
      `${this.basePath}/api/MultiCompanyList/${encodeURIComponent(tenent_id)}`,
      data,
      { headers }
    );
  }

  getCompany(id: string | "null"): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(
      `${this.basePath}/api/MultiCompany/${encodeURIComponent(id)}`,
      { headers }
    );
  }

  deleteCompany(id: string | "null"): Observable<any> {
    let headers = this.getHeader();

    return this.http.delete<any>(
      `${this.basePath}/api/MultiCompany/${encodeURIComponent(id)}`,
      { headers }
    );
  }

  editCompany(id: string | "null", data: any): Observable<any> {
    let headers = this.getHeader();

    return this.http.put<any>(
      `${this.basePath}/api/MultiCompany/${encodeURIComponent(id)}`,
      data,
      { headers }
    );
  }

  addContract(data: models.ContractObj | "null"): Observable<any> {
    let headers = this.getHeader();

    return this.http.post<any>(`${this.basePath}/api/Contracts`, data, {
      headers,
    });
  }

  //Contracts API service
  getContractList(): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/Contracts`, { headers });
  }

  getContract(id: string | "null"): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(
      `${this.basePath}/api/Contracts/${encodeURIComponent(id)}`,
      { headers }
    );
  }

  deleteContract(id: string | "null"): Observable<any> {
    let headers = this.getHeader();

    return this.http.delete<any>(
      `${this.basePath}/api/Contracts/${encodeURIComponent(id)}`,
      { headers }
    );
  }

  editContract(
    id: string | "null",
    data: models.ContractObj | "null"
  ): Observable<any> {
    let headers = this.getHeader();

    return this.http.put<any>(
      `${this.basePath}/api/Contracts/${encodeURIComponent(id)}`,
      data,
      { headers }
    );
  }

  //Items API service
  getItemList(): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/Items`, { headers });
  }

  //Custom Items API service
  getCustomItemList(filter: any): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(
      `${this.basePath}/api//CustomItemList/${encodeURIComponent(filter)}`,
      { headers }
    );
  }

  addItem(data: any) {
    let headers = this.getHeader();

    return this.http.post<any>(`${this.basePath}/api/Items`, data, { headers });
  }
  addItemImage(data: any) {
    let headers = new HttpHeaders();
    headers = headers.set("Accept", "multipart/form-data");
    this.cookieValue = this.cookieService.get("SESSIONID");
    // headers = headers.set("Authorization", "bearer " + this.cookieValue);
    headers = headers.set("Authorization", "Bearer " + this.cookieValue);

    return this.http.post<any>(`${this.basePath}/api/ItemImage`, data, {
      headers,
    });
  }

  importItems(data: any) {
    let headers = this.getHeader();

    return this.http.post<any>(`${this.basePath}/api/ImportItems`, data, {
      headers,
    });
  }

  importImages(data: any) {
    let headers = new HttpHeaders();
    headers = headers.set("Accept", "multipart/form-data");
    this.cookieValue = this.cookieService.get("SESSIONID");
    // headers = headers.set("Authorization", "bearer " + this.cookieValue);
    headers = headers.set("Authorization", "Bearer " + this.cookieValue);

    return this.http.post<any>(`${this.basePath}/api/ImportImages`, data, {
      headers,
    });
  }

  CustomerItemsReport(
    from_date: any,
    to_date: any,
    ledger_id: any,
    item_id: any
  ) {
    let headers = this.getHeader();
    from_date.setHours(0, 0, 0, 0);
    to_date.setHours(23, 59, 59, 59);

    return this.http.get<any>(
      `${this.basePath}/api/CustomerItemsReport/${encodeURIComponent(
        from_date
      )}/${encodeURIComponent(to_date)}/${encodeURIComponent(
        ledger_id
      )}/${encodeURIComponent(item_id)}`,
      { headers }
    );
  }

  getFilterItemList(filter: string | "null"): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(
      `${this.basePath}/api/FilterItems/${encodeURIComponent(filter)}`,
      { headers }
    );
  }

  downloadPdf(data: models.TransactionObj | "null"): Observable<Blob> {
    let headers = new HttpHeaders();

    headers = headers.set("Accept", "application/pdf");
    headers = headers.set("Content-Type", "application/json");
    this.cookieValue = this.cookieService.get("SESSIONID");
    // headers = headers.set("Authorization", "bearer " + this.cookieValue);
    headers = headers.set("Authorization", "Bearer " + this.cookieValue);

    return this.http.post<any>(`${this.basePath}/api/PdfDownload`, data, {
      headers,
      responseType: "blob" as "json",
    });
  }

  // //Get Item Stock
  getItemStockDetails(
    from_date: any | "null",
    to_date: any | "null",
    item: string | "null"
  ) {
    let headers = this.getHeader();
    from_date.setHours(0, 0, 0, 0);
    to_date.setHours(23, 59, 59, 59);

    return this.http.get<any>(
      `${this.basePath}/api/ItemStockDetails/${encodeURIComponent(
        from_date
      )}/${encodeURIComponent(to_date)}/${encodeURIComponent(item)}`,
      { headers }
    );
  }
  getConsumableList(): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/Consumeable`, { headers });
  }

  getLocationSeviceGet(): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/LocationServiceFlag`, {
      headers,
    });
  }

  // //Unit API Service
  getUnitList(): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/Unit`, { headers });
  }

  addUnit(data: models.UnitObj | "null"): Observable<any> {
    let headers = this.getHeader();

    return this.http.post<any>(`${this.basePath}/api/Unit`, data, { headers });
  }

  getUnit(id: string | "null"): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(
      `${this.basePath}/api/Unit/${encodeURIComponent(id)}`,
      { headers }
    );
  }

  deleteUnit(id: string | "null"): Observable<any> {
    let headers = this.getHeader();

    return this.http.delete<any>(
      `${this.basePath}/api/Unit/${encodeURIComponent(id)}`,
      { headers }
    );
  }

  editUnit(
    id: string | "null",
    data: models.UnitObj | "null"
  ): Observable<any> {
    let headers = this.getHeader();

    return this.http.put<any>(
      `${this.basePath}/api/Unit/${encodeURIComponent(id)}`,
      data,
      { headers }
    );
  }

  //LOCATION API service
  getLocationList(): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/Location`, { headers });
  }

  addLocation(data: any | "null"): Observable<any> {
    let headers = this.getHeader();

    return this.http.post<any>(`${this.basePath}/api/Location`, data, {
      headers,
    });
  }
  deleteLocation(id: string | "null"): Observable<any> {
    let headers = this.getHeader();

    return this.http.delete<any>(
      `${this.basePath}/api/Location/${encodeURIComponent(id)}`,
      { headers }
    );
  }

  getLocation(id: string | "null"): Observable<any> {
    let headers = this.getHeader();
    return this.http.get<any>(
      `${this.basePath}/api/Location/${encodeURIComponent(id)}`,
      { headers }
    );
  }
  getLocationByItem(id: string | "null"): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(
      `${this.basePath}/api/LocationByItem/${encodeURIComponent(id)}`,
      { headers }
    );
  }
  updateLocation(id: string | "null", data: any | "null"): Observable<any> {
    let headers = this.getHeader();

    return this.http.put<any>(
      `${this.basePath}/api/Location/${encodeURIComponent(id)}`,
      data,
      { headers }
    );
  }

  getLocationByObList(item_id: string | "null"): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(
      `${this.basePath}/api/LocationByOb/${encodeURIComponent(item_id)}`,
      { headers }
    );
  }

  getItem(id: string | "null"): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(
      `${this.basePath}/api/Items/${encodeURIComponent(id)}`,
      { headers }
    );
  }

  getAccountMapList(): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/AccountMap`, { headers });
  }

  getWebsiteInvoice(orderMode: string | "null"): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(
      `${this.basePath}/api/getWebsiteInvoice/${encodeURIComponent(orderMode)}`,
      { headers }
    );
  }

  getWeraEnableStatus(): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/getWeraEnableStatus`, {
      headers,
    });
  }

  editItem(
    id: string | "null",
    data: models.ItemObj | "null"
  ): Observable<any> {
    let headers = this.getHeader();

    return this.http.put<any>(
      `${this.basePath}/api/Items/${encodeURIComponent(id)}`,
      data,
      { headers }
    );
  }

  getCustomFilterItemList(
    filter: string | "null",
    filterItem: any, table_id: any | "null"
  ): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(
      `${this.basePath}/api/CustomFilterItemList/${encodeURIComponent(
        filter
      )}/${encodeURIComponent(filterItem)}`,
      { headers }
    );
  }

  // Featured Item API
  getFeturedItem(): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/FeaturedItems`, {
      headers,
    });
  }

  updateFeaturedItem(data: any): Observable<any> {
    let headers = this.getHeader();
    return this.http.post<any>(`${this.basePath}/api/FeaturedItems`, data, {
      headers,
    });
  }

  addTableGroupImage(data: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set("Accept", "multipart/form-data");
    this.cookieValue = this.cookieService.get("SESSIONID");
    // headers = headers.set("Authorization", "bearer " + this.cookieValue);
    headers = headers.set("Authorization", "Bearer " + this.cookieValue);

    return this.http.post<any>(
      `${this.basePath}/api/UploadTableGroupImage`,
      data,
      {
        headers,
      }
    );
  }

  getItemImage(id: string | "null"): Observable<Blob> {
    let headers = this.getHeader();

    return this.http.get<any>(
      `${this.basePath}/api/ProductImage/${encodeURIComponent(id)}`,
      {
        headers,
        responseType: "blob" as "json",
      }
    );
  }
  getCategoryImage(id: string | "null"): Observable<Blob> {
    let headers = this.getHeader();

    return this.http.get<any>(
      `${this.basePath}/api/CategoryImage/${encodeURIComponent(id)}`,
      {
        headers,
        responseType: "blob" as "json",
      }
    );
  }

  getTableGroupImage(id: string | "null"): Observable<Blob> {
    let headers = this.getHeader();

    return this.http.get<any>(
      `${this.basePath}/api/TableGroupImage/${encodeURIComponent(id)}`,
      {
        headers,
        responseType: "blob" as "json",
      }
    );
  }
  getItemByBarcode(barcode: string | "null"): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(
      `${this.basePath}/api/ItemsByBarcode/${encodeURIComponent(barcode)}`,
      { headers }
    );
  }

  getItemListWithInactive(
    filter: any | "undefined",
    inactive: any | "false"
  ): Observable<any> {
    let headers = this.getHeader();
    if (!filter) filter = undefined;

    return this.http.get<any>(
      `${this.basePath}/api/ItemListWithInactive/${encodeURIComponent(
        filter
      )}/${encodeURIComponent(inactive)}`,
      { headers }
    );
  }
  deleteItem(id: string | "null"): Observable<any> {
    let headers = this.getHeader();

    return this.http.delete<any>(
      `${this.basePath}/api/Items/${encodeURIComponent(id)}`,
      { headers }
    );
  }

  addWebsiteImage(data: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set("Accept", "multipart/form-data");
    this.cookieValue = this.cookieService.get("SESSIONID");
    // headers = headers.set("Authorization", "bearer " + this.cookieValue);
    headers = headers.set("Authorization", "Bearer " + this.cookieValue);

    return this.http.post<any>(`${this.basePath}/api/addWebsiteImage`, data, {
      headers,
    });
  }

  getWebsiteImage(): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/getWebsiteImage`, {
      headers,
    });
  }

  // =====================consumable ===============
  importconsumeItems(data: any) {
    let headers = this.getHeader();

    return this.http.post<any>(
      `${this.basePath}/api/ImportConsumeItems`,
      data,
      { headers }
    );
  }

  // =====================customisables import  ===============
  importcustomisables(data: any): Observable<any> {
    let headers = this.getHeader();

    return this.http.post<any>(
      `${this.basePath}/api/importcustomisables`,
      data,
      { headers }
    );
  }

  addPriceList(data: any): Observable<any> {
    let headers = this.getHeader();

    return this.http.post<any>(`${this.basePath}/api/pricelist`, data, {
      headers,
    });
  }

  //Get PriceList
  getAllNewPricList(): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/pricelist`, { headers });
  }

  getPricelistData(id: any): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(
      `${this.basePath}/api/pricelist/${encodeURIComponent(id)}`,
      { headers }
    );
  }

  updateNewEditPriceList(id: string, data: any): Observable<any> {
    let headers = this.getHeader();

    return this.http.put<any>(
      `${this.basePath}/api/pricelist/${encodeURIComponent(id)}`,
      data,
      { headers }
    );
  }

  deleteNewPriceList(id: string): Observable<any> {
    let headers = this.getHeader();

    return this.http.delete<any>(
      `${this.basePath}/api/pricelist/${encodeURIComponent(id)}`,
      { headers }
    );
  }

  additemPriceList(data: any): Observable<any> {
    let headers = this.getHeader();

    return this.http.post<any>(`${this.basePath}/api/pricelist/item`, data, {
      headers,
    });
  }

  updateItemPriceList(id: any, data: any): Observable<any> {
    let headers = this.getHeader();

    return this.http.put<any>(
      `${this.basePath}/api/pricelist/item/${encodeURIComponent(id)}`,
      data,
      { headers }
    );
  }

  getItembyPriceListId(id: any): Observable<any> {
    let headers = this.getHeader();
    return this.http.get<any>(
      `${this.basePath}/api/pricelist/item/${encodeURIComponent(id)}`,
      { headers }
    );
  }

  DeleteItemPriceListById(id: string, item_id: any): Observable<any> {
    let headers = this.getHeader();

    return this.http.delete<any>(
      `${this.basePath}/api/pricelist/item/${encodeURIComponent(
        id
      )}/${encodeURIComponent(item_id)}`,
      { headers }
    );
  }

  getItemDetails(): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/restaurant-menuitem`, {
      headers,
    });
  }

  priceListCSVImport(id: any, data: any): Observable<any> {
    let headers = this.getHeader();

    return this.http.post<any>(
      `${this.basePath}/api/priceListCSVImport/${encodeURIComponent(id)}`,
      data,
      { headers }
    );
  }

  //Table Group API service
  getTableGroupList(): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/TableGroup`, { headers });
  }

  addTableGroup(data: models.CategoryObj | "null"): Observable<any> {
    let headers = this.getHeader();

    return this.http.post<any>(`${this.basePath}/api/TableGroup`, data, {
      headers,
    });
  }

  getTableGroup(id: string | "null"): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(
      `${this.basePath}/api/TableGroup/${encodeURIComponent(id)}`,
      { headers }
    );
  }

  getTableByTransId(id: string | "null"): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(
      `${this.basePath}/api/TableByTransId/${encodeURIComponent(id)}`,
      { headers }
    );
  }

  deleteTableGroup(id: string | "null"): Observable<any> {
    let headers = this.getHeader();

    return this.http.delete<any>(
      `${this.basePath}/api/TableGroup/${encodeURIComponent(id)}`,
      { headers }
    );
  }

  editTableGroup(
    id: string | "null",
    data: models.CategoryObj | "null"
  ): Observable<any> {
    let headers = this.getHeader();

    return this.http.put<any>(
      `${this.basePath}/api/TableGroup/${encodeURIComponent(id)}`,
      data,
      { headers }
    );
  }

  // // consumable

  getConsumableItemList(row: any): Observable<any> {
    let headers = this.getHeader();

    return this.http.post<any>(`${this.basePath}/api/ConsumeableItem`, row, {
      headers,
    });
  }

  addUnitConvert(data: FormData | "null"): Observable<any> {
    let headers = this.getHeader();

    return this.http.post<any>(`${this.basePath}/api/Converter`, data, {
      headers,
    });
  }
  getUnitConvert(): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/Converter`, {
      headers,
    });
  }

  EditUnitConvert(
    id: string | "null",
    data: models.UnitObj | "null"
  ): Observable<any> {
    let headers = this.getHeader();

    return this.http.put<any>(
      `${this.basePath}/api/AddConverter/${encodeURIComponent(id)}`,
      data,
      {
        headers,
      }
    );
  }

  deleteConverter(id: string | "null"): Observable<any> {
    let headers = this.getHeader();

    return this.http.delete<any>(
      `${this.basePath}/api/Converter/${encodeURIComponent(id)}`,
      {
        headers,
      }
    );
  }

  getUnitConvertdata(id: any): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(
      `${this.basePath}/api/AddConverter/${encodeURIComponent(id)}`,
      {
        headers,
      }
    );
  }

  addConsumableItem(data: any) {
    let headers = this.getHeader();

    return this.http.post<any>(`${this.basePath}/api/Items`, data, {
      headers,
    });
  }

  // Journal API service
  getJournalList(): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/Journal`, {
      headers,
    });
  }

  addJournal(data: models.TransactionObj | "null"): Observable<any> {
    let headers = this.getHeader();

    return this.http.post<any>(`${this.basePath}/api/Journal`, data, {
      headers,
    });
  }

  getJournal(id: string | "null"): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(
      `${this.basePath}/api/Journal/${encodeURIComponent(id)}`,
      {
        headers,
      }
    );
  }

  deleteJournal(id: string | "null"): Observable<any> {
    let headers = this.getHeader();

    return this.http.delete<any>(
      `${this.basePath}/api/Journal/${encodeURIComponent(id)}`,
      {
        headers,
      }
    );
  }

  editJournal(
    id: string | "null",
    data: models.TransactionObj | "null"
  ): Observable<any> {
    let headers = this.getHeader();

    return this.http.put<any>(
      `${this.basePath}/api/Journal/${encodeURIComponent(id)}`,
      data,
      {
        headers,
      }
    );
  }
  //Cash Bank API service
  getCashBankList(): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/CashBank`, {
      headers,
    });
  }

  addCashBank(data: models.TransactionObj | "null"): Observable<any> {
    let headers = this.getHeader();

    return this.http.post<any>(`${this.basePath}/api/CashBank`, data, {
      headers,
    });
  }

  getCashBank(id: string | "null"): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(
      `${this.basePath}/api/CashBank/${encodeURIComponent(id)}`,
      {
        headers,
      }
    );
  }

  deleteCashBank(id: string | "null"): Observable<any> {
    let headers = this.getHeader();

    return this.http.delete<any>(
      `${this.basePath}/api/CashBank/${encodeURIComponent(id)}`,
      {
        headers,
      }
    );
  }

  editCashBank(
    id: string | "null",
    data: models.TransactionObj | "null"
  ): Observable<any> {
    let headers = this.getHeader();

    return this.http.put<any>(
      `${this.basePath}/api/CashBank/${encodeURIComponent(id)}`,
      {
        observe: "response",
        headers,
        body: JSON.stringify(data),
      }
    );
  }

  deleteTable(id: string | "null"): Observable<any> {
    let headers = this.getHeader();

    return this.http.delete<any>(
      `${this.basePath}/api/Table/${encodeURIComponent(id)}`,
      {
        headers,
      }
    );
  }

  editTable(id: string | "null", data: any): Observable<any> {
    let headers = this.getHeader();

    return this.http.put<any>(
      `${this.basePath}/api/Table/${encodeURIComponent(id)}`,
      data,
      {
        headers,
      }
    );
  }
  getTableList(): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/Table`, { headers });
  }
  addDeliveryOrder(data: models.TransactionObj | "null"): Observable<any> {
    let headers = this.getHeader();

    return this.http.post<any>(`${this.basePath}/api/DeliveryOrder`, {
      observe: "response",
      headers,
      body: JSON.stringify(data),
    });
  }

  getTableListWithKOT(): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/TableWithKOT`, {
      headers,
    });
  }

  addTable(data: any): Observable<any> {
    let headers = this.getHeader();

    return this.http.post<any>(`${this.basePath}/api/Table`, data, {
      headers,
    });
  }

  getTable(id: string | "null"): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(
      `${this.basePath}/api/Table/${encodeURIComponent(id)}`,
      {
        headers,
      }
    );
  }
  getTableWithKOT(id: string | "null"): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(
      `${this.basePath}/api/TableWithKOT/${encodeURIComponent(id)}`,
      {
        headers,
      }
    );
  }
  private resultDataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  private resultData$: Observable<any> = this.resultDataSubject.asObservable();

  getResultData(): Observable<any> {
    setTimeout(() => { }, 0);
    return this.resultData$;
  }

  getAllVariant(pricelist: any) {
    let headers = this.getHeader();

    return this.http.get<any>(
      `${this.basePath}/api/pricelist/variant/${encodeURIComponent(pricelist)}`,
      {
        observe: "response",
        headers,
      }
    );
  }

  updatepricelistvariant(id: any, data: any) {
    let headers = this.getHeader();

    return this.http.put<any>(
      `${this.basePath}/api/pricelist/variant/${encodeURIComponent(id)}`, data,
      {
        headers,
      }
    );
  }

  deletevariantId(id: string) {
    let headers = this.getHeader();
    return this.http.delete<any>(
      `${this.basePath}/api/pricelist/variant/${encodeURIComponent(id)}`,
      {
        observe: "response",
        headers,
      }
    );
  }

  getItemVariant(id: any) {
    let headers = this.getHeader();
    return this.http.get<any>(
      `${this.basePath}/api/pricelist/item/variant/${encodeURIComponent(id)}`,
      {
        headers,
      }
    );
  }

  getVariantData() {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/items-variant-data`, {
      headers,
    });
  }

  getPriceListVariantData(id: any) {
    let headers = this.getHeader();

    return this.http.get<any>(
      `${this.basePath}/api/pricelist/variant-data/${encodeURIComponent(id)}`,
      {
        observe: "response",
        headers,
      }
    );
  }

  deleteEmailSetting() {
    let headers = this.getHeader();

    return this.http.delete<any>(`${this.basePath}/api/company/email-setting`, {
      observe: "response",
      headers,
    });
  }

  importItemsVariant(id: any, data: any) {
    let headers = this.getHeader();

    return this.http.post<any>(
      `${this.basePath}/api/pricelist/item-variant-csv/${encodeURIComponent(
        id
      )}`,
      data,
      {
        headers,
      }
    );
  }

  getTableGroupListForPriceList(): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/TableGroup`, { headers });
  }

  //printer redirect 
  transferPrinter(printer: any): Observable<any> {
    let headers = this.getHeader();
    return this.http.put<any>(`${this.basePath}/api/printer/transferPrinter`, printer, {
      headers,
    });
  }
  enablePrinter(id: any): Observable<any> {
    let headers = this.getHeader();
    return this.http.get<any>(`${this.basePath}/api/kitchen/enablePrinter/${encodeURIComponent(
      id
    )}`, {
      headers,
    });
  }
}
