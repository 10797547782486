<div class="dialog-title" mat-dialog-title>
  <h3 class="color-h3">
    <span class="text-center">Cancel KOT</span>
    <span
      ><mat-icon
        class="close-icon"
        style="color: black; padding: 5px; float: right"
        (click)="onClose()"
        >close</mat-icon
      ></span
    >
  </h3>
</div>

<div class="table-details">
  <mat-dialog-content>
    <p class="text-center">Please Select Or Write Cancelation Reason </p>

    <mat-form-field class="dialog-content contract-full-width">
      <input
        type="text"
        placeholder="Pick one or write"
        aria-label="Number"
        matInput
        [formControl]="myControl"
        [matAutocomplete]="auto"
      />
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option
          *ngFor="let option of filteredOptions | async"
          [value]="option"
          >{{ option }}</mat-option
        >
      </mat-autocomplete>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-raised-button class="btn" type="button" (click)="onClose()">
      Close
    </button>

    <button
      mat-raised-button
      class="btn-danger"
      type="button"
      (click)="cancelKOT()"
    >
      Cancel
    </button>
  </mat-dialog-actions>
</div>
