<div class="container">
  <div class="row">
    <div class="col-12">
      <h3 class="color-h3">
        Select Menu
        <span *ngIf="selectedCategoryName"> /{{ selectedCategoryName }}</span>
      </h3>
    </div>
  </div>

  <div class="row">
    <!-- Sub Categories Section -->
    <div
      *ngIf="subCategories && subCategories.length > 0"
      class="col-md-5 col-12 subcategory-container"
    >
      <h3 class="color-h3">Sub Categories</h3>
      <div class="container-sub-category">
        <div class="items-sub-category subcatcss">
          <div
            class="food-card"
            *ngFor="let subCategory of subCategories; let i = index"
            [ngStyle]="{
              borderColor:
                selectedSubCategoryIndex === i ? selectedBorderColor : '#ccc',
              backgroundColor:
                selectedSubCategoryIndex === i
                  ? selectedBackgroundColor
                  : '#fff'
            }"
            (click)="showItems(i)"
          >
            <div class="image-container">
              <img
                [src]="
                  subCategory.imgurl === nullImageURL
                    ? '/assets/images/NoImage.png'
                    : subCategory.imgurl
                "
                (error)="handleError($event)"
                alt="{{ subCategory.name }}"
                loading="lazy"
              />
            </div>
            <div class="details-container">
              <div class="title">{{ subCategory.name }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- Divider Line on the Right Side of Subcategories -->
      <div
        class="separator-line"
        *ngIf="subCategories && subCategories.length > 0"
      ></div>
    </div>

    <!-- Items Section -->
    <div
      [ngClass]="{
        'col-md-7 col-12': subCategories && subCategories.length > 0,
        'col-12': !subCategories || subCategories.length === 0
      }"
      *ngIf="items_data && items_data.length > 0"
      class="items-container"
      [ngStyle]="{
        'padding-left': '25px'
      }"
    >
      <div class="row justify-content-center food-cart-container">
        <div
          *ngFor="let item of items_data; let i = index"
          (click)="addItemCardClicked(item, i)"
          class="food-card"
          [ngStyle]="{
            borderColor: selectedItemIndex === i ? selectedBorderColor : '#ccc',
            backgroundColor:
              selectedItemIndex === i ? selectedBackgroundColor : '#fff',
            height: showPrice ? '160px' : '140px'
          }"
        >
          <div class="image-container">
            <img
              [src]="
                item.imgurl === nullImageURL
                  ? '/assets/images/NoImage.png'
                  : item.imgurl
              "
              (error)="handleError($event)"
              alt="{{ item.item_name }}"
              loading="lazy"
            />
          </div>
          <div class="details-container">
            <div class="title" [title]="item.item_name">
              {{ item.item_name }}
            </div>
            <div *ngIf="item.s_rate" class="price">
              <i class="fa fa-{{ currency }}"></i
              >{{ item.s_rate | number : "1.2-2" }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
