<div class="order-item">
  <!-- Header Section with Labels for Item Name, Qty, and Amount -->
  <div class="header-panel">
    <div class="header-item item-number-header">#</div>
    <div class="header-item item-name-header">{{ itemNameHeader }}</div>
    <div class="header-item item-qty-header">{{ qtyHeader }}</div>
    <div class="header-item item-amount-header">{{ amountHeader }}</div>
  </div>

  <!-- Loop through each item -->
  <div class="scrollable-container">
    <div *ngFor="let item of items; let i = index" class="loop">
      <div class="content-panel">
        <div class="content-item item-number">{{ i + 1 }}</div>

        <!-- Item Image and Name -->
        <div class="content-item item-name">
          {{ item.item_name }}
        </div>

        <!-- Quantity Controls -->
        <div class="content-item item-quantity">
          <button (click)="decrease_qty(item, i)" class="quantity-btn">
            <app-icon
              [iconName]="decrementIconName"
              [iconColor]="iconColor"
              [iconSize]="iconSize"
            ></app-icon>
          </button>

          <input
            type="text"
            style="text-align: center;"
            [value]="item.qty"
            (keyup)="onChangeItemQty($event, i)"
            class="input-quantity input-field"
            value="{{ convertFloat(item.qty) }}"
          />

          <button (click)="increase_qty(item, i)" class="quantity-btn">
            <app-icon
              [iconName]="incrementIconName"
              [iconColor]="iconColor"
              [iconSize]="iconSize"
            ></app-icon>
          </button>
        </div>

        <!-- Display Amount -->
        <div class="content-item item-amount">
          {{ item.amount | number : "1.2-2" }}
        </div>

        <!-- Delete Button -->
        <button class="delete-btn" (click)="onDeleteItem1(i)">
          <app-icon
            [iconName]="deleteIconName"
            [iconColor]="iconColor"
            [iconSize]="iconSize"
            [isCircular]="true"
          ></app-icon>
        </button>
      </div>
      <ng-container
        *ngIf="
          (item.checkedVariant && item.checkedVariant.length > 0) ||
          (item.optionsData && item.optionsData.length > 0)
        "
      >
        <a
          (click)="updateCustomisation(item)"
          style="
            text-decoration: none;
            color: #ff8091;
            padding-left: 26px;
            font-weight: bold;
          "
          >Customisable</a
        >
      </ng-container>
      <!-- Cooking Instructions -->
      <div class="cooking-instructions" *ngIf="showCookingInstructions">
        <input
          type="text"
          class="input-field"
          placeholder="Write your cooking instruction here"
          #n
          (input)="instrucctiondata(item, n)"
        />
      </div>

      <hr class="content-separator" />
    </div>
  </div>
</div>
