import { Component, Inject, OnInit } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from "@angular/material/dialog";
import { CommonModule } from "@angular/common"; // Import CommonModule
import { RstDashboardService } from "../../_services/rst-dashboard.service";
import { MatButtonModule } from "@angular/material/button";
import { SharedModule } from "../../common.module";

@Component({
  selector: "app-kot-view",
  standalone: true,
  imports: [SharedModule, CommonModule, MatDialogModule, MatButtonModule],
  templateUrl: "./kot-view.component.html",
  styleUrls: ["./kot-view.component.css"], // Corrected to styleUrls
})
export class KotViewComponent implements OnInit {
  additionalData: any = null; // New variable to store additional data
  tableId: any; // Variable to store the table ID
  grandTotal: number | null = null; // Variable to store grand total
  kotItems: any[] = []; // Variable to store KOT items

  constructor(
    private apiService: RstDashboardService,
    public dialogRef: MatDialogRef<KotViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    // Extract table ID from data and store it in tableId
    this.tableId = this.data.table.id;

    // Call the API with the extracted table ID
    this.apiService.getdashboardInfo(this.tableId).subscribe({
      next: (response) => {
        
        // Ensure the response contains the necessary data
        if (response && response.success) {
          // Extract grandTotal and kotItems from the response
          this.grandTotal = response.data.grandTotal || 0; // Default to 0 if undefined

          response.data && response.data .kotItems && response.data.kotItems.forEach((item: any) => {
            var pos = this.kotItems.findIndex(
              (e: any) =>
                e.item_id === item.item_id && e.s_rate == item.s_rate && e.amount == item.amount
            );
            if (pos == -1) {
              this.kotItems.push(item);
            } else {
              this.kotItems[pos].qty =
                parseFloat(this.kotItems[pos].qty) + parseFloat(item.qty);
              this.kotItems[pos].amount =
                parseFloat(this.kotItems[pos].qty) *
                parseFloat(this.kotItems[pos].s_rate);
            }
          });
          // this.kotItems = response.data.kotItems || []; // Default to empty array if undefined
        } else {
          console.error("Error: Unsuccessful response or missing data");
        }
      },
      error: (err) => {
        console.error("Error fetching dashboard info:", err);
      },
    });
  }

  onClose() {
    this.dialogRef.close();
  }
}
