import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private snackBar: MatSnackBar) {}

  private showNotification(message: string, action: string, config: MatSnackBarConfig ) {
    this.snackBar.open(message, action, config);
  }

  showSuccess(message: string, action: string = 'Close') {
    this.showNotification(message, action, {
      duration: 3000,
      panelClass: ['success-snackbar'],
    });
  }

  showAlert(message: string, action: string = 'Close') {
    this.showNotification(message, action, {
      duration: 3000,
      panelClass: ['alert-snackbar'],
    });
  }

  showDanger(message: string, action: string = 'Close') {
    this.showNotification(message, action, {
      duration: 3000,
      panelClass: ['danger-snackbar'],
    });
  }
}


// uses

// this.notificationService.showSuccess('This is a success message');
// this.notificationService.showAlert('This is an alert message');
// this.notificationService.showDanger('This is a danger message');
