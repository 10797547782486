import {
  ViewChild,
  Inject,
  Output,
  EventEmitter,
  ElementRef,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";

import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";
import { FormControl } from "@angular/forms";
import { take } from "rxjs/operators";
import { of } from "rxjs";
import { MatTabsModule } from "@angular/material/tabs";
import { FormGroup, FormArray, FormBuilder } from "@angular/forms";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DataService } from "../../../data.service";
import { environment } from "../../../../environments/environment";

import { map, startWith, switchMap } from "rxjs/operators";
import { Router, ActivatedRoute } from "@angular/router";
import { element } from "protractor";
import { AddEditItemsComponent } from "../add-edit-items/add-edit-items.component";
// import { analyzeAndValidateNgModules } from "@angular/compiler";

import { MatDialogModule } from "@angular/material/dialog";
import { MatSortModule } from "@angular/material/sort";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatIconModule } from "@angular/material/icon";

import { MatButtonModule } from "@angular/material/button";
import { MatTableModule } from "@angular/material/table";
import { SocketServiceService } from "../../../socket-service.service";
import { ImortPriceListItemComponent } from "./imort-price-list-item/imort-price-list-item.component";
import { SharedModule } from "../../../common.module";
import { MasterService } from "../../../_services/master.service";
import { Notyf } from "notyf";
import { EditItemVariantComponent } from "../add-edit-items/items-variant-list/edit-item-variant/edit-item-variant.component";

const notyf = new Notyf({
  position: {
    x: "right",
    y: "top",
  },
});
@Component({
  selector: "app-add-edit-price-list",
  templateUrl: "./add-edit-price-list.component.html",
  styleUrls: ["./add-edit-price-list.component.css"],
  standalone: true,
  imports: [SharedModule],
})
/**
 * The `AddEditPriceListComponent` is a component that handles the functionality for adding and editing a price list.
 * It includes features such as:
 * - Displaying a list of price list items with options to edit and delete them
 * - Importing price list items from an external source
 * - Adding new price list items
 * - Updating the details of an existing price list
 * - Deleting an entire price list
 *
 * The component uses various Angular services and modules to manage the data and UI interactions.
 */
export class AddEditPriceListComponent implements OnInit {
  item_id: any[] = [];
  itemdataArr: any[] = [];
  filteredItems: any[] = [];
  applyFilter: boolean = false;

  model: any = {
    table_groupId: [],
    name: null,
    Dine_in: false,
    Web_App: false,
    Web_site: false,
    active: false,
  }; // Object to hold form data
  Dine_in: boolean = false; // Dine-in checkbox value
  Web_App: boolean = false; // Web App checkbox value
  Web_site: boolean = false; // Web Site checkbox value
  tableGroupList: any[] = []; // Table group list data
  itemObj: MatTableDataSource<any> = new MatTableDataSource<any>();
  searchText: any;
  activeType: any = false;
  width: any = window.innerWidth;
  file: any;
  resultData: any;
  prceListId: any;
  tablegroupObj: any = [];
  filterdataId: any = [];
  checkEnableResturantFlag: boolean = false;
  displayedColumns: any[] = [
    "id",
    "item_id",
    "category_name",
    "item_name",
    "default_rate",
    "s_rate",
    "gst",
    "actions",
  ];

  tableGroupForm: FormGroup;
  connection: any;
  selectedTab: any = 0;
  filteredDataArr: any[] = [];
  filteredVarAr: any[] = [];
  variant_id: any;
  itemIdVariant: any[] = [];
  itemVarArr: any[] = [];
  itemVarAr: any[] = [];
  displayedColumnsV: any[] = [
    "id",
    "item_id",
    "itemanme",
    "variant_name",
    "default_rate",
    "s_rate",
    "actions",
  ];

  constructor(
    public dialog: MatDialog,
    private apiService: MasterService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,

    private socketService: SocketServiceService
  ) {
    this.tableGroupForm = this.fb.group({
      tableGroupNames: new FormControl([]),
    });
    this.route.params.subscribe((params: any) => {
      this.prceListId = params.id;

      // Access route parameters here
      // const id = params['id'];
    });
  }

  ngOnInit() {
    this.connectSocket();
    this.load();
  }
  connectSocket() {
    this.connection = this.socketService
      .on("priceList")
      .subscribe((result: any) => {
        this.load();
      });
    this.socketService.on("reconnect").subscribe((result: any) => {
      this.load();
    });
  }
  ngOnDestroy() {
    this.connection.unsubscribe();
  }
  importPricelistClick() {
    this.dialog.open(
      ImortPriceListItemComponent,
      {
        width: "800px",
        data: { pricelist_id: this.prceListId },
      }
      // .afterClosed()
      // .subscribe((result) => {
      //   if (result == "Imported") {
      //     this.load();
      //   }
      // })
    );
  }

  // addClick() {
  //   this.dialog.open(AddEditItemsComponent, {
  //     width: "500px",
  //     data: { priceList_id: this.prceListId },
  //   });
  // }

  addClick() {
    this.dialog
      .open(AddEditItemsComponent, {
        width: "500px",
        data: {
          status: "add",
          priceList_id: this.prceListId,
        }, // Pass data to the dialog
      })
      .afterClosed()
      .subscribe((result) => {
        if (result.status == "add" || result.status == "update") {
          this.load();
        }
      });
  }

  onSubmit() {
    if (!this.model.name) {
      notyf.error("Please Enter PriceLise Name");
      return;
    }

    this.model.Dine_in = this.model.Dine_in || false;

    this.model.Web_App = this.model.Web_App || false;

    this.model.Web_site = this.model.Web_site || false;

    const selectedTableGrp = this.tableGroupForm.get("tableGroupNames")?.value;
    this.model.table_groups = selectedTableGrp;

    if (this.prceListId) {
      this.apiService
        .updateNewEditPriceList(this.prceListId, this.model)

        .subscribe(
          (response: any) => {
            if (response.success) {
              notyf.success("Pricelist Updated Successfully");
              this.load();
            } else {
              notyf.error(response.message);
            }
          },
          (error) => {
            notyf.error(error);
          }
        );
    } else {
      this.apiService.addPriceList(this.model).subscribe((response: any) => {
        if (response.success) {
          notyf.success("Pricelist Added Successfully");
          this.prceListId = response.priceList_id;
          this.load();
        } else {
          notyf.error(response.message);
        }
      });
    }
    // this.router.navigate(["Master/add-item-editPrice", row.id]);
  }

  load() {
    this.model = {};
    this.apiService.getTableGroupListForPriceList().subscribe((response: any) => {
      // Check if response is an array

      this.tableGroupList = response;
      if (this.prceListId) {
        this.apiService
          .getPricelistData(this.prceListId)
          .subscribe((response: any) => {
            // console.log("responseiddd", response);
            if (response) {
              this.tablegroupObj = response.table_groups;

              // this.tableGroupForm.patchValue({
              //   tableGroupNames: this.tableGroupList.map((option:any) => option)
              // });
              this.tableGroupList.forEach((item) => {
                this.tablegroupObj.forEach((e: any) => {
                  if (item.id == e.table_group_id) {
                    const control = this.tableGroupForm.get(
                      "tableGroupNames"
                    ) as FormControl;

                    const selectedValues = control.value.concat(item);
                    control.setValue(selectedValues);
                  }
                });
              });

              this.model.name = response.name;
              this.model.Dine_in = response.dine_in;
              this.model.Web_App = response.webapp;
              this.model.Web_site = response.website;
              this.model.active = response.active;
            }
          });

        // this.getAllPriceListItems();
        this.apiService
          .getItembyPriceListId(this.prceListId)
          .subscribe((response: any[]) => {
            this.itemdataArr = response;
            console.log("this.itemdataArrgggg", this.itemdataArr);
            this.searchItem();
          });
        this.apiService
          .getAllVariant(this.prceListId)
          .subscribe((response: any) => {
            this.itemVarAr = response.body;
            console.log("this.itemVarAr", this.itemVarAr);
            this.updateFilteredItems();
          });
      }
    });
    // console.log("this.prceListIdkkkkjjj", this.prceListId);

    // this.filteredItems = this.itemdataArr;
  }
  getAllPriceListItems() {
    this.apiService
      .getItembyPriceListId(this.prceListId)
      .subscribe((response: any[]) => {
        this.itemdataArr = response;
        // console.log("this.itemdataArrgggg", this.itemdataArr);
        this.searchItem();
      });
  }

  updateFilteredItems() {
    this.filteredDataArr = this.itemdataArr.slice();
    this.filteredVarAr = this.itemVarAr.slice();
    this.searchItem();
  }

  updatePrice() {
    // Assuming you have an API service method for updating price list data
    this.apiService
      .updateNewEditPriceList(this.prceListId, this.model)

      .subscribe(
        (response: any) => {
          if (response.message) {
            notyf.success(response.message);
          } else {
            notyf.error(response.message);
          }
          this.load();
        },
        (error) => {
          notyf.error(error);
          // Handle error, show error notification or log it
          // console.error("Error updating price list:", error);
        }
      );
  }

  DeleteNewPriceList() {
    // Assuming you have an API service method for deleting price list data
    this.apiService.deleteNewPriceList(this.prceListId).subscribe(
      (response: any) => {
        if (response.success) {
          notyf.success(response.message);

          this.router.navigate(["Master/item-price-list"]);
        } else {
          notyf.error(response.message);
        }
        // Handle response, maybe show a notification
        // Optionally, you can reload the data after successful deletion
      },
      (error) => {
        notyf.error(error);
        // Handle error, show error notification or log it
        // console.error("Error deleting price list:", error);
      }
    );
  }

  DeleteItemPriceListId(data: any) {
    const itemId = data.item_id;
    let id = { item_id: itemId };
    // Assuming you have an API service method for deleting price list data
    this.apiService.DeleteItemPriceListById(this.prceListId, itemId).subscribe(
      (response: any) => {
        if (response.success) {
          notyf.success(response.message);
        } else {
          notyf.error(response.message);
        }
      },
      (error) => {
        notyf.error(error);
        // Handle error, show error notification or log it
        console.error("Error deleting price list:", error);
      }
    );
    this.load();
  }

  search() {
    this.searchItem();
  }

  enterClick(key: any) {
    if (key.key == "Enter") this.search();
  }

  editClick(row: any) {
    console.log("row", row);
    // Extract item_id from the row object

    const item_id = row.item_id;
    const s_rate = row.s_rate;
    const taxslab = row.taxslab;

    // Pass data to the dialog including item_id
    this.dialog
      .open(AddEditItemsComponent, {
        width: "500px",
        data: {
          status: "update",
          priceList_id: this.prceListId,
          item_id: item_id,
          s_rate: s_rate,
          taxslab: taxslab,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result.status == "add" || result.status == "update") {
          this.getAllPriceListItems();
        }
      });
  }

  cancel() {
    this.router.navigate(["Master/item-price-list"]);
  }

  searchItem() {
    if (this.searchText == undefined || this.searchText == "") {
      // If the search input is empty, reset filteredItems to display all items
      this.filteredDataArr = this.itemdataArr;
      // this.applyFilter = false; // Set flag to false
      return;
    }

    // Filter items based on item name containing the search input
    this.filteredDataArr = this.itemdataArr.filter((item) =>
      item.item_name
        .toLowerCase()
        .includes(this.searchText.trim().toLowerCase())
    );
  }

  onTabChange(e: any) {
    this.selectedTab = e;
  }

  // Function to edit a variant
  editvariant(row: any) {
    // Extract necessary data from the row
    const variant_id = row.variant_id;
    const s_rate = row.s_rate;
    const id = row.id;

    // Open a dialog using EditItemVariantComponent
    this.dialog
      .open(EditItemVariantComponent, {
        width: "500px",
        // Pass data to the dialog component
        data: {
          variant_id: variant_id,
          s_rate: s_rate,
          id: id,
        },
      })
      .afterClosed(); // Handle dialog closure (currently not doing anything with the result)
  }

  Deletevariant(row: any) {
    // Extract the id from the row object
    const id = row.id;
    // Call the deletevariantId method of the apiService, passing the id
    // and subscribe to the observable returned by the method
    this.apiService.deletevariantId(id).subscribe(
      // This callback function is called when the API call is successful
      (response: any) => {
        // Check if the response.success property is truthy
        if (response.body.success) {
          // If successful, display a success notification with the response message
          notyf.success('Price List Item Variant Deleted Successfully');
        } else {
          // If not successful, display an error notification with the response message
          notyf.error(response.message);
        }
      },
      // This callback function is called when the API call encounters an error
      (error: any) => {
        // Display an error notification with the error message
        notyf.error(error);
        // Log the error to the console with a descriptive message
        console.error("Error deleting price list:", error);
      }
    );

    // Call the load method, likely to refresh or reload the list of variants
    this.load();
  }
}
