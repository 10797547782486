import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { IconComponent } from "../../stories-module/icons/icons.component";
@Component({
  selector: "app-card-components",
  standalone: true,
  imports: [CommonModule, IconComponent],
  templateUrl: "./card-components.component.html",
  styleUrls: ["./card-components.component.css"],
})
export class CardComponentsComponent {
  @Input() showCurrency: boolean = true; // Toggle currency symbol
  @Input() currencyType: "USD" | "INR" = "USD"; // Currency type selection
  @Input() descriptionFontSize: number = 14; // Font size for description
  @Input() descriptionFontWeight: "normal" | "bold" = "normal"; // Font weight for description
  @Input() iconName: string = "Download";
  @Input() iconColor: string = "#5f6368";
  @Input() iconSize: number = 24;
  @Input() amount: number = 1000;
  @Input() description: string = "";
  @Input() cardWidth: string = "300px";
  @Input() showIcon: boolean = true;
  @Input() currency: string = "usd";

  // Array of date ranges for the dropdown
  dateRanges: { label: string; value: string }[] = [
    { label: "Today", value: "Today" },
    { label: "Yesterday", value: "Yesterday" },
    { label: "Weekly", value: "Weekly" },
    { label: "Monthly", value: "Monthly" },
    // { label: 'This Month', value: 'thisMonth' },
    // { label: 'Last Month', value: 'lastMonth' },
    // { label: 'Custom Range', value: 'custom' }
  ];
  // Array of card data
  // cards = [
  //   {
  //     iconName: "Download",
  //     iconColor: "#5f6368",
  //     iconSize: 24,
  //     amount: 780,
  //     description: "Card 1 Description",
  //     showCurrency: true,
  //     currencyType: "USD",
  //     descriptionFontSize: 14,
  //     descriptionFontWeight: "normal",
  //     showIcon: true,
  //   },
  //    {
  //     iconName: "Download",
  //     iconColor: "#5f6368",
  //     iconSize: 24,
  //     amount: 234,
  //     description: "Card 1 Description",
  //     showCurrency: true,
  //     currencyType: "USD",
  //     descriptionFontSize: 14,
  //     descriptionFontWeight: "normal",
  //     showIcon: true,
  //   },
  //   {
  //     iconName: "Upload",
  //     iconColor: "#5f6368",
  //     iconSize: 24,
  //     amount: 331,
  //     description: "Card 2 Description",
  //     showCurrency: true,
  //     currencyType: "INR",
  //     descriptionFontSize: 14,
  //     descriptionFontWeight: "normal",
  //     showIcon: true,
  //   },
  //   {
  //     iconName: "Calender",
  //     iconColor: "#5f6368",
  //     iconSize: 24,
  //     amount: 665,
  //     description: "Card 2 Description",
  //     showCurrency: true,
  //     currencyType: "INR",
  //     descriptionFontSize: 14,
  //     descriptionFontWeight: "normal",
  //     showIcon: true,
  //   },
  //   {
  //     iconName: "Description",
  //     iconColor: "#5f6368",
  //     iconSize: 24,
  //     amount: 800,
  //     description: "Card 2 Description",
  //     showCurrency: true,
  //     currencyType: "INR",
  //     descriptionFontSize: 14,
  //     descriptionFontWeight: "normal",
  //     showIcon: true,
  //   },
  // ];

  // Function to return the currency symbol based on selected currency type
  getCurrencySymbol(currencyType: string) {
    return currencyType === "USD" ? "$" : "₹";
  }

  // Truncate description if it exceeds the specified word limit
  getTruncatedText(description: string, wordLimit: number): string {
    if (!description) return "";

    const words = description.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return description;
  }

  refreshData() {}
}
